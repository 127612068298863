import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-425a1217"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "onInput", "onKeydown", "onFocus", "onBlur"]
const _hoisted_2 = {
  key: 0,
  class: "text-center pdt-10"
}
const _hoisted_3 = { class: "text-secondary-red pdt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(['verification-code-inputs', _ctx.extraClasses])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verificationCodeDigits, (digit, index) => {
        return (_openBlock(), _createElementBlock("input", {
          key: index,
          type: "text",
          maxlength: "1",
          class: "verification-code-input",
          value: digit,
          onInput: ($event: any) => (_ctx.onDigitInput($event, index)),
          onKeydown: _withKeys(($event: any) => (_ctx.onBackspace(index, $event)), ["backspace"]),
          onFocus: ($event: any) => (_ctx.onInputFocus(index)),
          onBlur: ($event: any) => (_ctx.onInputBlur(index)),
          onPaste: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPaste($event))),
          ref_for: true,
          ref: _ctx.setDigitInputRef(index)
        }, null, 40, _hoisted_1))
      }), 128))
    ], 2),
    (_ctx.error && _ctx.error?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("small", _hoisted_3, _toDisplayString(Array.isArray(_ctx.error) ? _ctx.error.join('. ') : _ctx.error), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}