<template>
  <div :class="['text-input', { 'error': localError || error?.length }, customClass]">
    <label class="body-text" :for="label">{{ label }}  <span v-if="isRequired" class="text-danger"> * </span></label>
    <div class="input-date-wrapper">
      <input ref="inputRef" :type="currentType" :id="label" :placeholder="placeholder"
        :class="['links', 'date-input', { 'has-icon': icon }]" :maxlength="maxlength" :max="maxDate" :min="minDate"
        :value="inputValue" :readonly="readonly" @input="onInput" />

      <i v-if="icon" class="custom-date-icon iatse-icon-calendar" @click="focusInput"></i>
    </div>
    <span class="d-flex gap-10">
      <small v-if="error && error?.length" class="text-secondary-red sm-fs-xxs"> {{ Array.isArray(error) ? error.join('. ') : error }} </small>
      <small v-if="localError" class="text-secondary-red sm-fs-xxs"> {{ localError }} </small>
    </span>
  </div>
</template>

<script lang="ts">
import { useFormattedDate } from '@/composable/useFormattedDate';
import { defineComponent, computed, watch, ref } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    modelValue: {
    type: String,
    },
    error: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'date'
    },
    futureAllow: {
      type: Boolean,
      default: false
    },
    pastAllow: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const currentType = computed(() => (props.readonly ? 'text' : props.inputType));
    const maxlength = computed(() => (props.placeholder ? (props.placeholder.length || 10) : 10));
    const inputRef = ref<HTMLInputElement | null>(null);
    const inputValue = ref(props.modelValue);
    const localError = ref<string|null>(null)
    const minDate = computed(() => !props.pastAllow ? new Date().toISOString().split('T')[0] : '1900-01-01');
    const {formatDate} = useFormattedDate();

    const maxDate = computed(() => {
      return props.futureAllow ? '' : new Date().toISOString().split('T')[0];
    });


   // Format date in US timezone (America/New_York)
   const formatDateToUS = (date: string) => {
      const dateObj = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };

      return new Intl.DateTimeFormat('en-US', options).format(dateObj);
    };

    const pattern = computed(() => {
      if (props.placeholder === 'MM/YY') {
        return '^(0[1-9]|1[0-2])/\\d{2}$'
      }
      else if (props.placeholder === 'dd/mm/yyyy') {
        return '^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\\d{4}$';
      }

      return '^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}$';
    });

    watch(() => props.modelValue, (newValue) => {
      inputValue.value = newValue;
    });

    watch(() => localError.value, (newValue) => {
      emit('update:error', newValue);
    });

    const onInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const value = target.value;
      const currentDate = new Date();
      const enteredDate = new Date(value);
      const enteredYear = enteredDate.getFullYear();
      const enteredParts = value.split('-'); // Assume ISO format (yyyy-mm-dd)
      const [year, month, day] = enteredParts.map(Number);

      if (enteredYear < 1900) {
        localError.value = 'Date must not be before the year 1900';
      } else if (enteredYear > 2200) {
        localError.value = 'Date must be before the year 2200';
        return;
      } else if (enteredDate < new Date(currentDate.setHours(0, 0, 0, 0)) && !props.pastAllow) {
        localError.value = `Date must be today or after ${formatDate(String(currentDate))}`;
        return;
      } else {
          localError.value = null;
        }

      if (!props.futureAllow && enteredDate > currentDate) {
          // Adjust the year to the current year if it's causing the future issue
          const adjustedYear = currentDate.getFullYear();

          // Construct a fallback date
          const validDate = new Date(adjustedYear, (month || 1) - 1, day || 1); // Fallback to a safe date

          const formattedDate = `${validDate.getFullYear()}-${String(validDate.getMonth() + 1).padStart(2, '0')}-${String(validDate.getDate()).padStart(2, '0')}`;

          inputValue.value = formattedDate;
          emit('update:modelValue', formattedDate);
            return; // Prevent further action
        }

      inputValue.value = value;
      emit('update:modelValue', value);
    };

    const focusInput = () => {
      if (inputRef.value && !props.readonly) {
        const inputElement = inputRef.value as HTMLInputElement & { showPicker: () => void };

        inputElement.focus();
        inputElement.showPicker();
      }
    };

    return {
      inputRef,
      localError,
      pattern,
      minDate,
      maxlength,
      maxDate,
      inputValue,
      currentType,
      onInput,
      focusInput
    };
  },
});
</script>
