<template>
    <Alerts :success="success" :info="info" :errors="errors" />
    <!-- General Plan Detail Cards -->
    <MessageCard message="All Updates to your address will immediately and permanently change your account with the Fund
                        Office." type="danger" iconClass="iatse-icon-alarm" classes="info_card_full" />
    <!-- Plan Content -->
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- Form -->
        <form id="update-address-form" class="form update-address align-items-start" method="post">
            <h4> Primary Address </h4>
            <div class="inputs-wrapper mb-20">
                <SelectComponent label="Address Type" v-model:selectedOption="formData.Type"
                    :options="profileStore.addressTypes" :single-arrow="true" :error="inputErrors.Type"
                    :isRequired="true" />
                <TextInput type="text" name="mixed" label="Address 1 " placeholder="Enter Address 1" :max=30
                    v-model:modelValue="formData.Street1" :required="true" :error="inputErrors.Street1"
                    :isRequired="true" />
                <TextInput type="text" name="mixed" label="Address 2" placeholder="Enter Address 2" :max=30
                    v-model:modelValue="formData.Street2" :required="true" />
                <TextInput type="text" name="mixed" label="Address 3" placeholder="Enter Address 3" :max=30
                    v-model:modelValue="formData.Street3" :required="true" />
                <TextInput type="text" name="mixed" label="Address 4" placeholder="Enter Address 4" :max=30
                    v-model:modelValue="formData.Street4" :required="true" />
                <TextInput type="text" label="City" placeholder="Enter City" :max=25 v-model:modelValue="formData.City"
                    :required="true" :error="inputErrors.City" :isRequired="true" />

                <SelectComponent label="State" v-model:selectedOption="formData.State" :options="filteredStates"
                    :single-arrow="true" :error="inputErrors.State" :isRequired="true" />
                <SelectComponent label="Country" v-model:selectedOption="formData.Country"
                    :options="profileStore.countries" :single-arrow="true" :error="inputErrors.Country" />

                <TextInput type="text" name="number" label="Postal Code" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="formData.ZipCode" :required="true" :error="inputErrors.ZipCode"
                    :isRequired="true" />
            </div>

            <div class="action-buttons pdt-40">
                <button @click.prevent="resetModal" class="btn btn-secondary"> Discard</button>
                <button @click.prevent="submitData" class="btn btn-primary">
                    {{ loadingSubmit ? 'Saving...' : 'Save Changes' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, reactive, Ref, defineComponent, computed, watch, onMounted } from 'vue';
import { CountryStates, SelectOption, AddressInfo } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import { usePensionStore } from '@/stores/pension';
import { useVacationStore } from '@/stores/vacation';
import { useParticipantStore } from '@/stores/participant';
import useFormSubmit from '@composable/useFormSubmit';
import Alerts from '@components/utils/AlertsComponent.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";


export default defineComponent({
    components: {
        Alerts,
        MessageCard,
        TextInput,
        SelectComponent
    },
    setup(_, { emit }) {
        const profileStore = useProfileStore();
        const pensionStore = usePensionStore();
        const formData: AddressInfo = reactive({ ...profileStore.address });
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const info = ref('');
        const route = 'api/user/profile/my-address';
        const initialData = ref<AddressInfo | null>(null);

        const closeModal = () => {
            emit('close');
        }

        const resetModal = () => {
            Object.assign(formData, {...initialData.value});
        }

        const requiredInputs = {
            Street1: false,
            Type: false,
            City: false,
            State: false,
            Country: false,
            ZipCode: false,
        };

        const inputErrors = ref<Record<string, boolean>>({ ...requiredInputs });

        // Validate Fields -> if Country is US
        const validateFields = () => {
            let isValid = true;
            inputErrors.value = { ...requiredInputs }

            Object.keys(inputErrors.value).forEach((key) => {
                if (!formData[key as keyof AddressInfo]) {
                    isValid = false;
                    inputErrors.value[key] = true;
                }
                else {
                    inputErrors.value[key] = false;
                }
            });

            return isValid;
        };

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = formData?.Country?.key ?? '';
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        watch(filteredStates, () => {
            if (formData.State) {
                formData.State = { key: 0, value: "Select State" };
            }
        })

        // Handle Form Submit
        const submitData = async () => {
            let isValid = true;

            if (formData.Country?.key == 'US') {
                isValid = validateFields();
            }

            if (isValid) {
                const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
                const successSubmit = await handleSubmit();

                if (successSubmit) {
                    profileStore.setAddress(formData);
                    await profileStore.fetchAddress();
                    await pensionStore.fetchDemographics();
                    await useVacationStore().getPanelDetails();
                    await useParticipantStore().fetchGeneralData();

                    closeModal();
                }
            }
        }

        onMounted(() => {
            initialData.value = JSON.parse(JSON.stringify(profileStore.address));
            loading.value = false;
        });

        return {
            formData,
            profileStore,
            filteredStates,
            inputErrors,
            errors,
            success,
            info,
            loading,
            loadingSubmit,
            submitData,
            closeModal,
            resetModal,
        };
    }
})
</script>