<template>
    <div v-show="paginator.last_page > 1" class="pagination-container w-100 mt-30">
        <button v-if="paginator.last_page > 3" class="pagination-btn" :disabled="paginator.current_page === 1"
            @click="prevPage">
            <i class="iatse-icon-arrow icon-reverse"></i>
        </button>

        <template v-for="pageNumber in paginationRange" :key="pageNumber">
            <button class="pagination-btn" @click="goToPage(pageNumber)"
                :class="{ 'active': paginator.current_page === pageNumber }">
                {{ pageNumber }}
            </button>
        </template>

        <button v-if="paginator.last_page > 3" class="pagination-btn"
            :disabled="paginator.current_page === paginator.last_page" @click="nextPage">
            <i class="iatse-icon-arrow"></i>
        </button>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { Paginator } from '@interfaces/interfaces';
export default defineComponent({
    props: {
        paginator: {
            type: Object as () => Paginator,
            required: true
        },
    },
    setup(props, { emit }) {
        const dynamicPageRange = computed(() => {
            const start = Math.max(1, props.paginator.current_page - 10);
            const end = Math.min(props.paginator.last_page, start + 8);
            const range = [];
            for (let i = start; i <= end; i++) {
                range.push(i);
            }
            return range;
        });
        const { paginator } = toRefs(props);
        const lastPage = computed(() => props.paginator.last_page);

        const paginationRange = computed(() => {
            const currentPage = props.paginator.current_page;
            const lastPage = props.paginator.last_page;

            if (lastPage <= 3) return Array.from({ length: lastPage }, (_, i) => i + 1);

            if (currentPage <= 2) {
                return [1, 2, 3];
            } else if (currentPage >= lastPage - 1) {
                return [lastPage - 2, lastPage - 1, lastPage];
            } else {
                return [currentPage - 1, currentPage, currentPage + 1];
            }
        })

        const nextPage = () => {
            if (paginator.value.current_page && paginator.value.current_page < paginator.value.last_page) {
                emit('fetch-data', paginator.value.current_page + 1);
            }
        };

        const prevPage = () => {
            if (paginator.value.current_page && paginator.value.current_page > 1) {
                emit('fetch-data', paginator.value.current_page - 1);
            }
        };

        const goToPage = (pageNumber: number) => {
            console.log(pageNumber, '@pageNumber-goToPage', paginator);
            if (pageNumber >= 1 && pageNumber <= paginator.value.last_page) {
                emit('fetch-data', pageNumber);
            }
        };

        return {
            dynamicPageRange,
            lastPage,
            paginationRange,
            nextPage,
            goToPage,
            prevPage
        };
    }
});
</script>
