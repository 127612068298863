<template>
    <!-- <div class="card">
        <div class="card-body">
            WARNING: If any person makes a false or fraudulent statement in connection with a claim, including
            submitting
            false or fraudulent information or concealing a material fact, the Fund may take action to recover any
            amounts
            paid by the Fund (plus interest and costs) and take any other legal action as it deems appropriate.
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            Please note that all claims for reimbursement must be received by the Fund within 12 months of the date of
            service or the date the premium is paid (in the case of a request for premium reimbursement). In addition,
            you
            (or your dependent, as applicable) must have been enrolled in the Plan C MRP option on the date of the
            service
            (or the date the premium was paid, as applicable) and at the time the reimbursement is submitted to the
            Fund.
        </div>
    </div> -->
    <div class="card">
        <div class="card-body d-flex flex-column gap-4">
            <span>I hereby certify that the :</span>
            <span>
                (i) expenses claimed have not been reimbursed, and are not reimbursable, under any other health plan
                coverage;
            </span>
            <span>
                (ii) the expenses claimed are medical expenses as defined by the Internal Revenue Service;
            </span>
            <span>
                (iii) effective 01/01/2017 - any dependent for whom I am seeking reimbursement is enrolled in an
                employer or union sponsored group health plan that provides minimum value;
            </span>
            <span>
                (iv) for any claim for reimbursement of health plan premiums, I paid for such premium on a post-tax
                basis (e.g., not through a pre-tax flex spending account);
            </span>
        </div>
    </div>
    <div class="d-flex align-items-end justify-content-between gap-2">
        <CheckBox label="By checking this box you are certifying that you have read, understood and agree to the conditions
                listed herein." className="alert-checkbox text-large" v-model:modelValue="agree" id="agree" />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import CheckBox from '@components/form/CheckBox.vue';

export default defineComponent({
    components: {
        CheckBox
    },
    props: {
        agreement: Boolean,
    },
    setup(props, { emit }) {
        const agree = ref(props.agreement);

        watch(() => agree.value, (newValue) => {
            emit('update:agreement', agree.value);
        });

        return { agree }
    }
})
</script>