<template>
  <div class="custom-file-upload">
    <label class="body-text" :for="label">{{ label }}</label>
    <input type="text" class="file-name" v-model="fileName" :multiple="multiple" readonly />
    <button type="button" class="browse-btn btn btn-secondary" @click="triggerFileInput"
      :disabled="disabled">Browse</button>
    <input type="file" class="file-input" ref="fileUploadInput" @change="handleFileChange" style='display: none;' />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, toRefs, Ref } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    reset: Boolean,
  },
  setup(props, { emit }) {
    const fileUploadInput = ref<HTMLInputElement | null>(null);
    const fileName = ref('');
    const { reset } = toRefs(props);

    const triggerFileInput = () => {
      fileUploadInput.value?.click();
    };

    watch(() => reset.value, (newValue) => {
      if (newValue) {
        fileName.value = '';
      }
    });

    const handleFileChange = (event: Event) => {
      const input = event.target as HTMLInputElement;

      if (input.files && input.files.length > 0) {
        fileName.value = input.files[0].name;
        // emit('change', event);
      }
    };

    return {
      fileUploadInput,
      fileName,
      triggerFileInput,
      handleFileChange,
    };
  },
});
</script>