import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useProfileStore } from "@/stores/profile";
import axios from "axios";
import router from "@/router";
import { useParticipantStore } from "./participant";

interface Credentials {
    email: string;
    password: string;
}

interface User {
    name: string;
    roleName: string;
    unreadAlerts: number;
    email: string;
    participantId: string | number;
}

export const useAuthStore = defineStore('auth', () => {
    // State
    const isImpersonatingStorage = localStorage.getItem("isImpersonating");
    const isImpersonating = ref(isImpersonatingStorage === "1");
    const token = ref<string | null>(localStorage.getItem("authToken"));
    const stopImpersonationRoute = ref("superadmin.users.participants");
    const impersonationToken = ref<string | null>(localStorage.getItem("impersonationToken"));
    const impersonationName = ref<string>(localStorage.getItem("impersonationName") || "");
    const user = ref<User | null>(JSON.parse(localStorage.getItem("user") || "null"));

    // Getters
    const isAuthenticated = computed(() => !!token.value);
    const isEmployer = computed(() => {
        const employerRoles = ["ROLE_EMPLOYER", "ROLE_EMPLOYER_PAYOR", "ROLE_EMPLOYER_ADMIN"];
        return user.value ? employerRoles.includes(user.value.roleName) : false;
    });
    const isLocal = computed(() => {
        const localRoles = ["ROLE_LOCAL", "ROLE_LOCAL_ADMIN"];
        return user.value ? localRoles.includes(user.value.roleName) : false;
    });
    const isLocalAdmin = computed(() => {
        return user.value ? user.value.roleName === "ROLE_LOCAL_ADMIN" : false;
    });
    const isSuperAdmin = computed(() => {
        return user.value ? user.value.roleName === "ROLE_ADMINISTRATOR" : false;
    });
    const isParticipant = computed(() => {
        return user.value ? user.value.roleName === "ROLE_PARTICIPANT" : false;
    });
    const userName = computed(() => user.value?.name);
    const userEmail = computed(() => user.value?.email);
    const userRole = computed(() => user.value?.roleName);
    const unreadAlerts = computed(() => user.value?.unreadAlerts);

    const getDashboardRoute = computed(() => {
        if (isSuperAdmin.value) {
            return isImpersonating.value ? "participant.dashboard" : "superadmin.dashboard";
        } else if (isParticipant.value) {
            return "participant.dashboard";
        } else if (isEmployer.value) {
            return "employer.dashboard";
        } else if (isLocal.value) {
            return "local.dashboard";
        }
        return "participant.dashboard";
    });

    // Actions
    const getCsrfCookie = async () => {
        try {
            await axios.get("sanctum/csrf-cookie");
        } catch (error) {
            console.error("Error getting CSRF cookie:", error);
        }
    };

    const authenticateUser = async (credentials: Credentials) => {
        try {
            await getCsrfCookie();
            const response = await axios.post("api/auth", credentials);

            setToken(response.data.token);
            setUser({
                name: response.data.name,
                roleName: response.data.role,
                unreadAlerts: response.data.unreadAlerts,
                email: response.data.email,
                participantId: response.data.participantId
            });

            router.push({ name: getDashboardRoute.value });
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                console.error("Invalid credentials");
                alert("401 Invalid credentials");
            } else {
                alert(error.response.data.message);
            }
        }
    };

    const updateUnreadAlerts = (newCount: number) => {
        if (user.value) {
            user.value.unreadAlerts = newCount;
        }
    };

    const setToken = (newToken: string | null) => {
        token.value = newToken;
        if (newToken) {
            localStorage.setItem("authToken", newToken);
        } else {
            localStorage.removeItem("authToken");
        }
    };

    const setImpersonateToken = (newToken: string | null, name: string) => {
        impersonationToken.value = newToken;
        impersonationName.value = name;

        if (newToken) {
            localStorage.setItem("impersonationToken", newToken);
            localStorage.setItem("impersonationName", name);
            localStorage.setItem("isImpersonating", "1");

            isImpersonating.value = true;
        } else {
            localStorage.removeItem("impersonationToken");
            localStorage.setItem("impersonationName", "name");
            localStorage.setItem("isImpersonating", "0");
            isImpersonating.value = false;
            impersonationName.value = "";
        }
    };

    const setUser = (newUser: User) => {
        user.value = newUser;
        localStorage.setItem("user", JSON.stringify(newUser));
    };

    const clearUser = () => {
        user.value = null;
        localStorage.removeItem("user");
    };

    const startImpersonation = async (userID: number) => {
        try {
            const response = await axios.post("/api/administrator/impersonate/start", {
                UserID: userID,
            });

            setImpersonateToken(response.data.token, response.data.name);
            setUser({
                name: response.data.name,
                roleName: response.data.role,
                unreadAlerts: response.data.unreadAlerts,
                email: response.data.email,
                participantId: response.data.participantId
            });

            impersonationToken.value = response.data.token;
            getStopImpersonationRoute();
        } catch (error) {
            console.log("++ impersonate error ++" + error);
            throw error;
        }
    };

    const stopImpersonation = async () => {
        try {
            const response = await axios.get("api/administrator/impersonate/stop");

            impersonationToken.value = null;
            isImpersonating.value = false;
            impersonationName.value = "";

            setImpersonateToken(null, "");

            setUser({
                name: response.data.name,
                roleName: response.data.role,
                unreadAlerts: 0,
                email: response.data.email,
                participantId: 0,
            });
        } catch (error) {
            console.log("++ impersonate stop error ++" + error);
            throw error;
        }
    };

    const logout = () => {
        setToken(null);
        setImpersonateToken(null, "");
        clearUser();

        useProfileStore().resetUserProfile();
        useParticipantStore().reset();

        router.push({ name: "login" });
    };

    const getStopImpersonationRoute = () => {
        let route = "superadmin.dashboard";

        if (isParticipant.value) {
            route = "superadmin.users.participants";
        } else if (isEmployer.value) {
            route = "superadmin.users.employers";
        } else if (isLocal.value || isLocalAdmin.value) {
            route = "superadmin.users.locals";
        }

        stopImpersonationRoute.value = route;
    };

    return {
        // State
        token,
        stopImpersonationRoute,
        isImpersonating,
        impersonationToken,
        impersonationName,
        user,
        // Getters
        isAuthenticated,
        isEmployer,
        isLocal,
        isLocalAdmin,
        isSuperAdmin,
        isParticipant,
        userName,
        userEmail,
        userRole,
        unreadAlerts,
        getDashboardRoute,
        // Actions
        getCsrfCookie,
        authenticateUser,
        updateUnreadAlerts,
        setToken,
        setImpersonateToken,
        setUser,
        clearUser,
        startImpersonation,
        stopImpersonation,
        logout,
        getStopImpersonationRoute,
    };
});
