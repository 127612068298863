<template>
    <div class="bordered-card bordered-card_secondary gap-20">
        <h4 class="fw-bold">Forms & Documents</h4>
        <template v-for="(resourceGroup, mainKey) in resources" :key="mainKey">
            <div class="primary-group d-flex justify-content-between align-items-center w-100 plan-modal"
                @click="toggleGroup(mainKey)">
                <h5 class="fw-bold"> {{ mainKey }} Documents </h5>
                <!-- Toggle secondary group items on click -->
                <button @click="toggleGroup(mainKey)" class="toggle-btn">
                    <span v-if="!showGroup[mainKey]" class="fs-xl lh-1">+</span>
                    <span v-else class="fs-xl lh-1">-</span>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="showGroup[mainKey]"
                    class="secondary-group d-flex flex-md-row flex-column w-100 pdr-0 pdl-20">
                    <template v-for="(documents, secondaryKey, secondaryIndex) in resourceGroup" :key="secondaryKey">
                        <div class="secondary_group_item d-flex flex-column gap-20">
                            <h5 class="fw-semibold fs-base"> {{ secondaryKey }} </h5>
                                <!-- Display limited documents initially -->
                                 <template  v-for="(doc, docIndex) in getVisibleDocuments(documents, mainKey, secondaryKey)"  :key="docIndex">
                                    <a :href="doc.file_url" target="_blank"
                                        :class="{'fade-in': isExpanded(mainKey, secondaryKey) && docIndex >= 3, 'fade-out': !isExpanded(mainKey, secondaryKey) && docIndex > 3}"
                                        class="document-item gap-10">
                                            <i class="iatse-icon-pdf d-flex">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                            </i>
                                            <span class="fs-base">{{ doc.title }}</span>
                                    </a>
                                 </template>
                            <!-- Expand/Collapse Button -->
                            <button v-if="documents.length > 3" @click="toggleDocs(mainKey, secondaryKey)"
                                    class="btn btn-link p-0 justify-content-start" :class="{'bounce-up': isExpanded(mainKey, secondaryKey), 'bounce-down': !isExpanded(mainKey, secondaryKey)}">
                                    <i class="iatse-icon-select-arrow fs-xxs text-primary"></i>
                                {{ isExpanded(mainKey, secondaryKey) ? 'View Less' : 'View More' }}
                            </button>

                        </div>
                        <div v-if="secondaryIndex < Object.entries(resourceGroup).length - 1" class="divider divider_vertical h-100 m-0"></div>
                    </template>
                </div>
            </transition>
        </template>
    </div>
</template>

<script lang="ts">
import { PropType, ref, defineComponent } from 'vue';
import { FundMaterials } from '@interfaces/local';

export default defineComponent({
    props: {
        resources: [] as PropType<FundMaterials>
    },

    setup() {
        const showGroup = ref<Record<string, boolean>>({});
        const visibleDocs = ref<Record<string, Record<string, boolean>>>({});

        // Toggle the visibility of secondary items for each group
        const toggleGroup = (mainKey: string) => {
            showGroup.value[mainKey] = !showGroup.value[mainKey];
        };

        // Get visible documents (limit to 3 or all based on toggle state)
        const getVisibleDocuments = (documents: any[], mainKey: string, secondaryKey: string) => {
            const expanded = isExpanded(mainKey, secondaryKey);
            return expanded ? documents : documents.slice(0, 3);
        };

        // Check if a category is expanded
        const isExpanded = (mainKey: string, secondaryKey: string) => {
            return visibleDocs.value[mainKey]?.[secondaryKey] || false;
        };

        // Toggle documents visibility for a category
        const toggleDocs = (mainKey: string, secondaryKey: string) => {
            if (!visibleDocs.value[mainKey]) visibleDocs.value[mainKey] = {};
            visibleDocs.value[mainKey][secondaryKey] = !isExpanded(mainKey, secondaryKey);
        };

        return {
            showGroup,
            toggleGroup,
            getVisibleDocuments,
            toggleDocs,
            isExpanded
        };
    }
})
</script>