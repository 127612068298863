import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "step-item-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "step-border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["steps-wrapper", _ctx.classes])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["step-item", {
      active: index + 1 == _ctx.activeStep,
      next: index + 1 == _ctx.activeStep + 1,
      completed: index + 1 < _ctx.activeStep
    }])
          }, _toDisplayString(_ctx.formatStepNumber(index + 1)), 3),
          _createElementVNode("h4", null, " Step " + _toDisplayString(index + 1), 1)
        ]),
        (index < _ctx.steps - 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 2))
}