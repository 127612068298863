<template>
    <div :class="['bordered-card simple-card', classes]">
        <!-- Header: Title & Action Button -->
        <div class="w-100">
            <div class="plan-general-header">
                <h5>{{ title }}</h5>
                <router-link v-if="actionButton && data.status" :to="{ name: data.to, params: { status: data.status } }"
                    class="plan-modal text-underline">
                    <!-- @click="openModal(data.action.to, data.action.title, data.action.classes)"> -->
                    <span class="plan-modal"> {{ actionButton }} </span>
                    <!-- <i class="iatse-icon-arrow-down plan-modal"> </i> -->
                </router-link>
                <router-link v-else-if="actionButton" :to="{ name: data.to }" class="text-underline">
                    <span class="plan-modal"> {{ actionButton }}</span>
                </router-link>
            </div>
            <div class="divider"></div>
        </div>

        <!-- Count -->
        <div v-if="data.type === 'number'">
            <h1> {{ data.count }} </h1>
        </div>

        <!-- List -->
        <div v-if="data.type === 'list'" class="w-100 simple-card_list">
            <div v-for="(item, index) in data.list" :key="index" class="w-100">
                <router-link v-if="item.to" :to="{ name: item.to }" class="plan-modal fw-normal text-decoration-none">
                    {{ item.name }}
                </router-link>
                <div v-if="index < data.list.length - 1" class="divider"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';

export default defineComponent({
    props: {
        classes: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            required: true,
        },
        actionButton: {
            type: String,
            default: 'View All'
        },
        data: {
            type: Object,
        },
    },
    setup(_, { emit }) {
        const modalStore = useModalStore();

        return {
            openModal: modalStore.openModal
        }
    },
})
</script>