<template>
  <div class="text-input">
    <label class="body-text">Card Expiration Date</label>
    <input
      type="text"
      class="links"
      :value="formattedValue"
      @input="onInput"
      placeholder="MM/YY"
      maxlength="5" 
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'CardExpirationField',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    // formattedValue:
    // Takes the raw digits (modelValue) and inserts a slash after the second digit.
    const formattedValue = computed(() => {
      const digits = props.modelValue.replace(/\D/g, '').slice(0,4);
      if (!digits) return '';

      // If we have at least 3 digits, format as 'MM/YY'
      if (digits.length > 2) {
        return digits.slice(0,2) + '/' + digits.slice(2);
      } else {
        // Less than 3 digits typed, just show what we have
        return digits;
      }
    });

    const onInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      // Remove any non-digits and slash
      let digitsOnly = input.value.replace(/\D/g, '');

      // Limit to 4 characters (MMYY)
      digitsOnly = digitsOnly.slice(0,4);

      // Update the parent model value
      emit('update:modelValue', digitsOnly);
    };

    return {
      formattedValue,
      onInput,
    };
  },
});
</script>

<style scoped>
/* Add any desired styling */
</style>
