import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { CobraDueStatement } from '@/interfaces/health';
import { defineStore } from 'pinia';
import { CobraSelectedInvoice } from '@/interfaces/health';

export const useInvoicesStore = defineStore('invoicesStore', () => {
    // State
    const invoices = ref<CobraSelectedInvoice[]>([]);
    const payInAdvanceSelected = ref<boolean> (false);
    const payInAdvance = ref<number>(0);
    const selectedInvoices = ref<number[]>([]);

    // Computed Property: Total Amount
    const total = computed(() => {
        // Calculate the total amount for selected invoices
        let totalAmount = selectedInvoicesDetails.value.reduce((sum, invoice) => sum + invoice.Amount, 0);

        // Add payInAdvance if it's selected and not null
        if (payInAdvanceSelected.value === true && payInAdvance.value !== null) {
            totalAmount += payInAdvance.value;
        }

        return totalAmount;
    });

    // Actions
    function addInvoice(invoice: CobraSelectedInvoice) {
        const exists = invoices.value.some(i => i.InvoiceID === invoice.InvoiceID);
        if (!exists) {
            invoices.value.push(invoice);
        } else {
            console.warn(`Invoice with ID ${invoice.InvoiceID} already exists.`);
        }
    }

    function removeInvoice(invoiceID: number) {
        const index = invoices.value.findIndex(i => i.InvoiceID === invoiceID);
        if (index !== -1) {
            invoices.value.splice(index, 1);
            // Also deselect if selected
            const selIndex = selectedInvoices.value.indexOf(invoiceID);
            if (selIndex !== -1) selectedInvoices.value.splice(selIndex, 1);
        } else {
            console.warn(`Invoice with ID ${invoiceID} does not exist.`);
        }
    }

    function setPayInAdvance(amount: number) {
        payInAdvance.value = amount;
    }

    // Selection Actions
    function toggleInvoiceSelection(invoiceID: number) {
        const idx = selectedInvoices.value.indexOf(invoiceID);
    
        if (idx !== -1) {
            // Deselecting the invoice
            selectedInvoices.value.splice(idx, 1);
        } else {
            // Selecting the invoice
            selectedInvoices.value.push(invoiceID);
        }
    }

    function isInvoiceSelected(invoiceID: number): boolean {
        return selectedInvoices.value.includes(invoiceID);
    }

    // Optionally, a getter to get all selected invoices
    const selectedInvoicesDetails = computed(() => {
        return invoices.value.filter(invoice => selectedInvoices.value.includes(invoice.InvoiceID));
    });

    // Pay in advance functions
    function togglePayInAdvance() {
        payInAdvanceSelected.value = !payInAdvanceSelected.value;
    }

    // Computed Property: Oldest Invoice
    const oldestInvoice = computed(() => {
        if (invoices.value.length === 0) return null;
        return invoices.value.reduce((oldest, current) => {
            const currentDueDate = new Date(current.DueDate);
            const oldestDueDate = new Date(oldest.DueDate);
            return currentDueDate < oldestDueDate ? current : oldest;
        }, invoices.value[0]);
    });

    return {
        invoices,
        total,
        selectedInvoices,
        addInvoice,
        removeInvoice,
        toggleInvoiceSelection,
        isInvoiceSelected,
        selectedInvoicesDetails,
        setPayInAdvance,
        togglePayInAdvance,
        payInAdvance,
        payInAdvanceSelected,
        oldestInvoice
    };
});