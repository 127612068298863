<template>
    <table :class="classes">
        <thead>
            <tr>
                <th v-if="toggle" class="toggle-th"></th>
                <th v-for="(header, key) in table.headers" :key="key">
                    {{ header.title ? header.title : (typeof header === 'object' ? '' : header) }}
                </th>
            </tr>
        </thead>

        <!-- Simple Table With Subitems -->
        <tbody v-if="toggle && table.rows.length">
            <template v-for="(row, mainIndex) in  table.rows " :key="mainIndex">
                <tr class="mobile-table-header hide-desktop" v-if="headerArray">
                    <td>{{ headerArray.title }}</td>
                    <td v-if="headerArray.actionText" class="text-underline plan-modal" data-modal-name="assign-fund">
                        {{ headerArray.actionText }}
                    </td>
                </tr>
                <template v-for="(rowType, index) in  row " :key="index">
                    <tr class="parent-row" v-if="rowType.key === 'Parent'">
                        <td v-if="toggle" class="cell toggle-cell" @click="toggleCell(mainIndex)">
                            <p class="view-details">{{ toggleStates[mainIndex] ? '-' : '+' }}</p>
                        </td>
                        <td v-for="item in  rowType.value " :key="item.key" class="cell">
                            <p class="cell-header hide-desktop"> {{ item.key }} </p>
                            <router-link v-if="item.value.link"
                                :to="{ name: item.value.link.name, params: generateParams(item.value.link.params) }">
                                {{ item.value.data }}
                            </router-link>
                            <p class="cell-text" v-else v-html="renderCell(item.value.data)"> </p>
                        </td>
                    </tr>

                    <!-- Subitems Row -->
                    <tr v-if="toggleStates[mainIndex] && rowType.key === 'Children'" class="subitems-row toggle">
                        <td class="subitems-wrapper" :colspan='table.headers.length + 1'>
                            <table>
                                <tbody>
                                    <tr v-for="( children, index ) in  rowType.value " :key="index">
                                        <td v-if="toggle" class="cell toggle-cell"></td>
                                        <td v-for="( child, index ) in  children " :key="index" class="cell">
                                            <p class="cell-header hide-desktop">{{ child.key }}</p>
                                            <p class="cell-text">{{ child?.value?.data }}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </template>
            </template>
        </tbody>

        <!-- Simple Table - Radio Input -->
        <tbody v-else-if="type == 'radio' && table.rows.length">
            <template v-for="( row, mainIndex ) in  table.rows " :key="mainIndex">
                <tr class="parent-row">
                    <td class="cell" :class="{ 'radio-cell': index === 0 }" v-for="( item, index ) in  row "
                        :key="index">
                        <p class="cell-header hide-desktop"> {{ item.key }} </p>
                        <RadioButton v-if="index === 0" :options="[item.value?.data]" :name='item.key'
                            v-model:modelValue="selectedValue" @change="handleChange" :value="item.value?.data" />
                        <p class="cell-text" v-else> {{ item.value?.data }} </p>
                    </td>
                </tr>
            </template>
        </tbody>

        <!-- Simple Table -->
        <tbody v-else-if="table.rows.length">
            <template v-for="( row, mainIndex ) in  table.rows " :key="mainIndex">
                <tr class="parent-row">
                    <template v-for="( item, index ) in  row" :key="index">
                        <td class="cell" v-if="index !== 'EntityID' && index !== 'Info'">
                            <!-- Key for Mobile View -->
                            <p class="cell-header hide-desktop"> {{ item?.key || index }} </p>

                            <!-- Open a Modal -->
                            <p class="cell-text plan-modal" v-if="item?.value?.modal && item?.value?.data"
                                @click="openModal(item.value.modal.path, item.value.modal.name, item.value.modal.classes, null, item.value.modal.props)"
                                v-html="renderCell(item.value?.data)">
                            </p>

                            <!-- Goes To a Route -->
                            <router-link v-else-if="item.value?.link"
                                :to="{ name: item.value.link.name, params: generateParams(item.value.link.params) }">
                                {{ item.value.data }}
                                <i v-if="item.value.link.icon" :class="item.value.link.icon"> </i>
                            </router-link>

                            <!-- Cell Value -->
                            <p class="cell-text" v-else-if="item.value?.data" v-html="renderCell(item.value?.data)"></p>
                            <p class="cell-text" v-else-if="typeof item === 'string'" v-html="renderCell(item)"></p>
                        </td>
                    </template>

                    <!-- Slot for Table Actions -->
                    <td v-if="hasActions" class="table-actions">
                        <slot name="actions" :entity="row.EntityID" />
                        <slot v-if="hasInfo && row.Info.value.data" name="info" :info="row.Info.value.data" />
                    </td>
                </tr>
            </template>
        </tbody>
        <tbody v-else>
            <tr class="text-center">
                <td :colspan="table.headers.length"> No Data Available </td>
            </tr>
        </tbody>
    </table>

</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useModalStore } from '@/stores/modal';
import RadioButton from '@/components/form/RadioButton.vue';

export default defineComponent({
    props: {
        table: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: ''
        },
        classes: {
            type: String,
            default: ''
        },
        headerArray: {
            type: Object,
            default: null
        },
        toggle: {
            type: Boolean,
            default: false
        },
        selected: {
            type: String || Number,
            default: null
        },
        hasActions: {
            type: Boolean,
            default: false
        },
        hasInfo: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { emit }) {
        const modalStore = useModalStore();
        const renderCell = (cell: string) => {
            if (cell === 'doc') {
                return `<img src="${require('@/assets/images/icons/pdf_primary.svg')}" alt="doc icon" width="18" height="18" />`;
            }

            return `<p>${cell}</p>`;
        }

        const selectedValue = ref(props.selected);

        watch(() => props.selected, (newValue) => {
            selectedValue.value = newValue;
        });


        const toggleStates = ref<Record<number, boolean>>({});

        const isNumeric = (value: any) => {
            return !isNaN(value - parseFloat(value));
        }

        const toggleCell = (index: number) => {
            toggleStates.value[index] = !toggleStates.value[index];
        }

        const generateParams = (paramsObj: { key: string, value: string | number }) => {
            const params: { [key: string]: string | number } = {};
            params[paramsObj.key] = paramsObj.value;

            return params;
        };

        const handleChange = (event: Event) => {
            const target = event.target as HTMLInputElement | null;

            if (target) {
                emit('change', target.value);
            }
        }


        return {
            toggleStates,
            selectedValue,
            renderCell,
            isNumeric,
            generateParams,
            toggleCell,
            handleChange,
            openModal: modalStore.openModal,
        }
    }
});
</script>