<template>
    <main class="main" @click="handleClickOutside" :class="{ 'overflow-hidden': modals?.length }">
      <SidebarComponent
        v-if="isAuthenticated && !hideSidebar"
        :active="activeMenu"
        @open-menu="openMenu"
      />
      <MobileHeader
        v-if="isAuthenticated"
        @openModal="openModal"
        @open-menu="openMenu"
      />

      <section
        class="main-content"
        :class="{ 'authenticated': isAuthenticated, 'fixed': activeMenu, 'admin': isSuperAdmin }"
        id="content"
      >
        <HeaderComponent
          v-if="!hideHeader && showHeader"
          @openModal="openModal"
          @click.stop
        />
        <router-view />
      </section>

      <!-- Render Modals -->
      <div class="modal-container" @click.stop>
        <ModalComponent
          v-for="(modal, index) in modals"
          :key="index"
          :isVisible="modal.isVisible"
          :index="index"
          :modalType="modal.type"
          :title="modal.title"
          :classNames="modal.classes"
          :fund="modal.fund"
          :modalProps="modal.modalProps"
          :secondTitle="modal.secondTitle"
          :headerButtons="modal.headerButtons"
          @close="closeModal"
          @close-above="closeAbove"
          @handle-submit="handleSubmit"
        >
          <template #steps v-if="modal.type === 'add-dependent'">
            <MultiStep :steps="2" :activeStep="currentStep" classes="steps-wrapper_header" />
          </template>
        </ModalComponent>
      </div>
      <div v-if="modals?.length || activeMenu" class="modal-overlay"></div>
    </main>
  </template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import { useDependentStore } from '@/stores/dependent';
import { useRoute } from 'vue-router';

import HeaderComponent from './components/HeaderComponent.vue';
import ModalComponent from './components/ModalComponent.vue';
import SidebarComponent from './components/sidebar/SidebarComponent.vue';
import MobileHeader from './components/sidebar/MobileHeader.vue';
import MultiStep from './components/form/MultiStep.vue';

export default defineComponent({
  components: {
    HeaderComponent,
    SidebarComponent,
    MobileHeader,
    ModalComponent,
    MultiStep
  },

  setup() {
    const authStore = useAuthStore();
    const modalStore = useModalStore();
    const dependentStore = useDependentStore();
    const route = useRoute();
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const modals = computed(() => modalStore.modals);
    const showHeader = computed(() => !route.path.startsWith('/file'));
    const activeMenu = ref(false);
    const currentStep = computed(() => dependentStore.currentStep);
    const isSuperAdmin = computed(() => authStore.isSuperAdmin);
    const isLocal = computed(() => authStore.isLocal);

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Handle Click outside modals
      if (!target.closest('.modal-container') && !target.closest('.plan-modal')) {
        modalStore.closeAllModals();
      }

      // Handle Click outside Mobile Menu
      if (!target.closest('.mobile-menu') && !target.closest('.main-sidebar') && !target.closest('.plan-modal')) {
        activeMenu.value = false;
      }
    };

    const areAllModalsClosed = computed(() => modalStore.modals.length === 0);

    watch(areAllModalsClosed, (newVal) => {
      const body = document.body;

      if (newVal) {
        body.classList.remove('overflow-disabled');
      } else {
        body.classList.add('overflow-disabled');
      }
    });

    const openMenu = () => {
      activeMenu.value = !activeMenu.value;
      console.log(activeMenu, '@toggle');
    }

    const closeAbove = (index: number) => {
      modalStore.closeAbove(index);
    }

    const hiddenRoutesSidebar = ['/login', '/authorization/login', '/authorization/verify', '/authorization/setup'];
    const hiddenRoutesHeader = ['/login', '/authorization/login', '/authorization/verify', '/authorization/setup'];

    const hideSidebar = computed(() => hiddenRoutesSidebar.includes(route.path));
    const hideHeader = computed(() => hiddenRoutesHeader.includes(route.path));

    return {
      isAuthenticated,
      isSuperAdmin,
      isLocal,
      modals,
      activeMenu,
      currentStep,
      showHeader,
      openMenu,
      closeAbove,
      openModal: modalStore.openModal,
      closeModal: modalStore.closeModal,
      handleSubmit: modalStore.handleSubmit,
      handleClickOutside,
      hideSidebar,
      hideHeader
    };
  }
})
</script>