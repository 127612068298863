<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- General Plan Detail Cards -->
        <div class="plan-details">
            <ProfileInfo type="pension" :items="firstCard" :has-divider="false" :use-container="true" />
        </div>
        <div class="bordered-card overflow-visible sm-p-0">
            <h4 class="pdb-10"> Payments and Contributions </h4>
            <table class="striped small toggle equal-widths no-underline">
                <thead>
                    <tr>
                        <th v-for="(header, key) in pensionStore.paymentHistory.headers" :key="key">{{ header.title }}
                        </th>
                    </tr>
                </thead>

                <tbody v-if="pensionStore.paymentHistory?.rows?.length">
                    <template v-for="(row, mainKey) in  pensionStore.paymentHistory?.rows " :key="mainKey">
                        <tr class="parent-row sm-pdl-10 sm-pdr-10">
                            <td colspan="6">
                                <div class="table-row_wrapper d-flex flex-column gap-20">
                                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap gap-20">
                                        <div v-for="(item, key) in row.Parent" :key="key" class="cell d-flex gap-10 w-auto"
                                            @click="row.Children && key === 'Date' ? toggleCell(mainKey) : null">
                                            <p v-if="row.Children && key === 'Date'" class="toggle-cell view-details">
                                                {{ toggleStates[mainKey] ? '-' : '+' }}
                                            </p>
                                            <p class="cell-header hide-desktop"> {{ key }} </p>
                                            <p class="cell-text" :class="{ 'toggle-cell': key === 'Date' }">{{ item }}
                                            </p>
                                        </div>
                                    </div>
                                    <transition name="slide-fade">
                                        <div v-if="toggleStates[mainKey] && row.Children" class="subitems-columns p-15 sm-pdl-5 sm-pdr-0 sm-pdt-0">
                                            <div class="d-flex subitems-columns_wrapper"
                                                v-for="(child, index) in  Object.values(row.Children) " :key="index">
                                                <div class="d-flex flex-column gap-2">
                                                    <h5 class="text-dark fw-bold sm-fs-xs"> Details </h5>
                                                    <div class="d-flex subitems-columns_details">
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Date Issued </p>
                                                            <h5 class="sm-fs-xs"> {{ child.Date }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Payment Type </p>
                                                            <h5 class="sm-fs-xs"> {{ child.PaymentDestination ?? '-' }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Clear Date </p>
                                                            <h5 class="sm-fs-xs"> {{ child.ClearDate }} </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :class="['divider', { 'hide-mobile': index % 2 === 1 }]">
                                                </div>
                                                <div class="d-flex flex-column gap-2">
                                                    <h5 class="text-dark fw-bold sm-fs-xs"> Deductions </h5>
                                                    <div class="d-flex subitems-columns_details">
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Gross </p>
                                                            <h5 class="sm-fs-xs"> {{ child.Gross }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Deductions </p>
                                                            <h5 class="sm-fs-xs"> {{ child.Deductions }} </h5>
                                                        </div>
                                                        <div class="d-flex flex-column gap-2">
                                                            <p> Net </p>
                                                            <h5 class="sm-fs-xs"> {{ child.Net }} </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :class="['divider', { 'hide-mobile': index % 2 === 1 }]">
                                                </div>
                                                <div class="d-flex flex-column gap-2">
                                                    <h5 class="text-dark fw-bold sm-fs-xs"> Destination </h5>
                                                    <div class="d-flex flex-column gap-2"
                                                        v-if="child.PaymentDestination === 'Address'">
                                                        <p> Address </p>
                                                        <h5 class="sm-fs-xs"> {{ JSON.parse(child.Destination)?.FullAddress }}
                                                        </h5>
                                                    </div>
                                                    <div v-else class="d-flex subitems-columns_details">
                                                        <div class="d-flex flex-column gap-2">
                                                            <p>Address</p>
                                                            <h5 class="sm-fs-xs">{{ JSON.parse(child.Destination).BankClassDescription }}
                                                            </h5>
                                                        </div>
                                                        <transition name="fade">
                                                            <div class="d-flex subitems-columns_details"
                                                                v-if="toggleBanks[mainKey]">
                                                                <div class="d-flex flex-column gap-2">
                                                                    <p>Account Number</p>
                                                                    <h5 class="sm-fs-xs">{{ JSON.parse(child.Destination).BankAcctNo }}
                                                                    </h5>
                                                                </div>
                                                                <div class="d-flex flex-column gap-2">
                                                                    <p>Routing Number</p>
                                                                    <h5 class="sm-fs-xs">{{ JSON.parse(child.Destination).RoutingCode }}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </transition>
                                                        <button @click.prevent="toggleBank(mainKey)"
                                                            class="ml-2 text-underline fw-bold text-dark button-slide"
                                                            :class="{ 'bounce-right': !isVisible }">
                                                            {{ toggleBanks[mainKey] ? 'Hide' : 'Show' }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="parent-row">
                        <td class="cell text-center" colspan="6">
                            <p>No data available</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="plan-details w-auto w-sm-100">
            <ProfileInfo title="Tax Details" :items="secondCard" :has-divider="true" :multiple="pensionStore.taxDetails.length"  classes="pdb-35" actionButton=""/>
        <!--actionButton="View/Change" @open-modal="openModal('update-tax-details', 'Update Tax Details', 'plan-upload plan-card-info')" /> -->
        </div>

        <!-- Related Links -->
        <div class="account-actions">
            <h4> Related Links </h4>
            <div class="action-buttons">
                <button class="btn btn-secondary plan-modal" @click="openModal('beneficiaries',
                'My Beneficiaries',
                'plan-tables-striped beneficiary',
                null,
                null,
                null,
                [{ name: 'Add Beneficiary', type: 'primary', action: 'add-beneficiary', classes: 'plan-form plan-upload' }]
                )">
                    Beneficiaries
                </button>
                <template v-if="isPensioner">
                    <button class="btn btn-tertiary btn-large plan-modal"
                        @click="openModal('check-destination', 'Payment Destination Setup', 'plan-form', 'pension')">
                        ACH/Check Destination</button>
                    <button class="btn btn-tertiary btn-large plan-modal"
                        @click="openModal('check-replacement', 'Check Replacement', 'plan-form', 'pension')">
                        Check Replacement
                    </button>
                </template>
                <template v-else>
                    <a href="" class="btn btn-tertiary btn-large plan-modal"
                        @click.prevent="openModal('pension-application', 'Pension Application', 'plan-form', 'application')">Pension
                        Application
                    </a>
                    <a href="" class="btn btn-tertiary btn-large plan-modal"
                        @click.prevent="openModal('pension-application', 'Estimate Request', 'plan-form', 'estimate')">
                        Estimate Request
                    </a>
                </template>
                <a href="" @click.prevent class="btn btn-secondary"> Forms & Documents </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useAuthStore } from '@/stores/auth';
import { usePensionStore } from '@/stores/pension';
import { TaxDetail } from '@/interfaces/pension';

import ProfileInfo from '@/components/cards/ProfileInfo.vue';

export default defineComponent({
    components: {
        ProfileInfo,
    },
    setup() {
        const pensionStore = usePensionStore();
        const authStore = useAuthStore();
        const loading = ref(true);
        const isVisible = ref(true);
        const isPensioner = computed(() => pensionStore.pensionerDetails?.IsPensioner);

        const firstCard = computed(() => {
            return [
                { label: 'Participant ID', value: authStore.user?.participantId },
                { label: 'Participation Date', value: pensionStore.data?.participationDate ?? '-' },
                { label: 'Plan', value: pensionStore.pensionerDetails?.Plan },
                { label: 'Status', value: pensionStore.pensionerDetails?.Status ?? '-' },
                { label: 'Year Vested ', value: pensionStore.data?.vestingYear },
                { label: 'Retirement', value: pensionStore.pensionerDetails?.RetirementDate ?? '-' },
                { label: 'Pension End', value: pensionStore.pensionerDetails?.PensionEnd ?? '-' },
            ]
        })

        const secondCard = computed(() => {
            if (!pensionStore.taxDetails?.length) {
                return [
                    { label: 'Tax Status', value: 'None' },
                    { label: 'Exemptions', value: '-' },
                ];
            }

            return pensionStore.taxDetails?.map((taxDetail: TaxDetail) => {
                return [
                    { label: 'Tax Status', value: taxDetail.Status || 'None' },
                    { label: 'Exemptions', value: taxDetail.TotalExemptions ?? '-', class: 'right' },
                    { label: 'CWT', value: !Number(taxDetail.CWT) ? '-' : taxDetail.CWT },
                    { label: 'SWT', value: !Number(taxDetail.SWT) ? '-' : taxDetail.SWT, class: 'right' },
                    { label: 'FWT', value: !Number(taxDetail.FWT) ? '-' : taxDetail.FWT },
                    { label: 'Local Code', value: taxDetail.LocalTax ?? '-', class: 'right' },
                    { label: 'State Code', value: taxDetail.StateTax ?? '-' },
                ];
            });
        });

        const fetchData = async () => {
            if (!pensionStore.data) {
                await pensionStore.fetchPensionCredits();
            }

            if (!pensionStore.paymentHistory?.rows?.length) {
                await pensionStore.fetchPaymentHistory();
            }

            if (pensionStore.paymentHistory.rows?.length) {
                pensionStore.paymentHistory.rows?.forEach((_, index) => {
                    toggleStates.value[index] = false;
                    toggleBanks.value[index] = false;
                });
            }

            loading.value = false;
        }

        const isNumeric = (value: any) => {
            return !isNaN(value - parseFloat(value));
        }

        const toggleStates = ref<Record<number, boolean>>({});
        const toggleBanks = ref<Record<number, boolean>>({});

        const toggleCell = (index: number) => {
            Object.keys(toggleStates.value).forEach(key => {
                toggleStates.value[Number(key)] = Number(key) === index ? !toggleStates.value[Number(key)] : false;
            });
        }

        const toggleBank = (index: number) => {
            isVisible.value = !isVisible.value;
            Object.keys(toggleStates.value).forEach(key => {
                toggleBanks.value[Number(key)] = Number(key) === index ? !toggleBanks.value[Number(key)] : false;
            });

            setTimeout(() => isVisible.value = !isVisible.value, 300);
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            loading,
            isVisible,
            firstCard,
            secondCard,
            pensionStore,
            toggleStates,
            toggleBanks,
            isNumeric,
            isPensioner,
            toggleCell,
            toggleBank,
            openModal: useModalStore().openModal,
        };
    }
});
</script>