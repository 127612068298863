import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "toggle-th"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "mobile-table-header hide-desktop"
}
const _hoisted_4 = {
  key: 0,
  class: "text-underline plan-modal",
  "data-modal-name": "assign-fund"
}
const _hoisted_5 = {
  key: 0,
  class: "parent-row"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "view-details" }
const _hoisted_8 = { class: "cell-header hide-desktop" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "subitems-row toggle"
}
const _hoisted_11 = ["colspan"]
const _hoisted_12 = {
  key: 0,
  class: "cell toggle-cell"
}
const _hoisted_13 = { class: "cell-header hide-desktop" }
const _hoisted_14 = { class: "cell-text" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "cell-header hide-desktop" }
const _hoisted_17 = {
  key: 1,
  class: "cell-text"
}
const _hoisted_18 = { key: 2 }
const _hoisted_19 = {
  key: 0,
  class: "cell"
}
const _hoisted_20 = { class: "cell-header hide-desktop" }
const _hoisted_21 = ["onClick", "innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = {
  key: 0,
  class: "table-actions"
}
const _hoisted_25 = { key: 3 }
const _hoisted_26 = { class: "text-center" }
const _hoisted_27 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_ctx.toggle)
          ? (_openBlock(), _createElementBlock("th", _hoisted_1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.headers, (header, key) => {
          return (_openBlock(), _createElementBlock("th", { key: key }, _toDisplayString(header.title ? header.title : (typeof header === 'object' ? '' : header)), 1))
        }), 128))
      ])
    ]),
    (_ctx.toggle && _ctx.table.rows.length)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.rows, (row, mainIndex) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: mainIndex }, [
              (_ctx.headerArray)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.headerArray.title), 1),
                    (_ctx.headerArray.actionText)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(_ctx.headerArray.actionText), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (rowType, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (rowType.key === 'Parent')
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                        (_ctx.toggle)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 0,
                              class: "cell toggle-cell",
                              onClick: ($event: any) => (_ctx.toggleCell(mainIndex))
                            }, [
                              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.toggleStates[mainIndex] ? '-' : '+'), 1)
                            ], 8, _hoisted_6))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rowType.value, (item) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: item.key,
                            class: "cell"
                          }, [
                            _createElementVNode("p", _hoisted_8, _toDisplayString(item.key), 1),
                            (item.value.link)
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  to: { name: item.value.link.name, params: _ctx.generateParams(item.value.link.params) }
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.value.data), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"]))
                              : (_openBlock(), _createElementBlock("p", {
                                  key: 1,
                                  class: "cell-text",
                                  innerHTML: _ctx.renderCell(item.value.data)
                                }, null, 8, _hoisted_9))
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.toggleStates[mainIndex] && rowType.key === 'Children')
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                        _createElementVNode("td", {
                          class: "subitems-wrapper",
                          colspan: _ctx.table.headers.length + 1
                        }, [
                          _createElementVNode("table", null, [
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rowType.value, (children, index) => {
                                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                  (_ctx.toggle)
                                    ? (_openBlock(), _createElementBlock("td", _hoisted_12))
                                    : _createCommentVNode("", true),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(children, (child, index) => {
                                    return (_openBlock(), _createElementBlock("td", {
                                      key: index,
                                      class: "cell"
                                    }, [
                                      _createElementVNode("p", _hoisted_13, _toDisplayString(child.key), 1),
                                      _createElementVNode("p", _hoisted_14, _toDisplayString(child?.value?.data), 1)
                                    ]))
                                  }), 128))
                                ]))
                              }), 128))
                            ])
                          ])
                        ], 8, _hoisted_11)
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ], 64))
          }), 128))
        ]))
      : (_ctx.type == 'radio' && _ctx.table.rows.length)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.rows, (row, mainIndex) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: mainIndex,
                class: "parent-row"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (item, index) => {
                  return (_openBlock(), _createElementBlock("td", {
                    class: _normalizeClass(["cell", { 'radio-cell': index === 0 }]),
                    key: index
                  }, [
                    _createElementVNode("p", _hoisted_16, _toDisplayString(item.key), 1),
                    (index === 0)
                      ? (_openBlock(), _createBlock(_component_RadioButton, {
                          key: 0,
                          options: [item.value?.data],
                          name: item.key,
                          modelValue: _ctx.selectedValue,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
                          onChange: _ctx.handleChange,
                          value: item.value?.data
                        }, null, 8, ["options", "name", "modelValue", "onChange", "value"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(item.value?.data), 1))
                  ], 2))
                }), 128))
              ]))
            }), 128))
          ]))
        : (_ctx.table.rows.length)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_18, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.rows, (row, mainIndex) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: mainIndex,
                  class: "parent-row"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (item, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (index !== 'EntityID' && index !== 'Info')
                        ? (_openBlock(), _createElementBlock("td", _hoisted_19, [
                            _createElementVNode("p", _hoisted_20, _toDisplayString(item?.key || index), 1),
                            (item?.value?.modal && item?.value?.data)
                              ? (_openBlock(), _createElementBlock("p", {
                                  key: 0,
                                  class: "cell-text plan-modal",
                                  onClick: ($event: any) => (_ctx.openModal(item.value.modal.path, item.value.modal.name, item.value.modal.classes, null, item.value.modal.props)),
                                  innerHTML: _ctx.renderCell(item.value?.data)
                                }, null, 8, _hoisted_21))
                              : (item.value?.link)
                                ? (_openBlock(), _createBlock(_component_router_link, {
                                    key: 1,
                                    to: { name: item.value.link.name, params: _ctx.generateParams(item.value.link.params) }
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.value.data) + " ", 1),
                                      (item.value.link.icon)
                                        ? (_openBlock(), _createElementBlock("i", {
                                            key: 0,
                                            class: _normalizeClass(item.value.link.icon)
                                          }, null, 2))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"]))
                                : (item.value?.data)
                                  ? (_openBlock(), _createElementBlock("p", {
                                      key: 2,
                                      class: "cell-text",
                                      innerHTML: _ctx.renderCell(item.value?.data)
                                    }, null, 8, _hoisted_22))
                                  : (typeof item === 'string')
                                    ? (_openBlock(), _createElementBlock("p", {
                                        key: 3,
                                        class: "cell-text",
                                        innerHTML: _ctx.renderCell(item)
                                      }, null, 8, _hoisted_23))
                                    : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128)),
                  (_ctx.hasActions)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_24, [
                        _renderSlot(_ctx.$slots, "actions", {
                          entity: row.EntityID
                        }),
                        (_ctx.hasInfo && row.Info.value.data)
                          ? _renderSlot(_ctx.$slots, "info", {
                              key: 0,
                              info: row.Info.value.data
                            })
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("tbody", _hoisted_25, [
              _createElementVNode("tr", _hoisted_26, [
                _createElementVNode("td", {
                  colspan: _ctx.table.headers.length
                }, " No Data Available ", 8, _hoisted_27)
              ])
            ]))
  ], 2))
}