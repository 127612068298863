<template>
  <div class="d-flex gap-4 mt-60 flex-wrap flex-md-nowrap">
    <SimpleCard title="New Info Change Requests" :data="infoChangeData" />
    <SimpleCard title="New Form Submissions" :data="submissionsData" />
    <SimpleCard title="Users & Groups" actionButton="" :data="{ type: 'list', list: userGroups }" />
  </div>
</template>

<script lang="ts">
import { ref, reactive, onMounted } from 'vue';
import InfoSection from '@components/superadmin/InfoSection.vue'
import SimpleCard from '@components/cards/SimpleCard.vue'
import axios from 'axios';
import { useSuperadminStore } from '@/stores/superadmin';

export default {
  components: {
    SimpleCard
  },
  setup() {
    const claimSubmissionsCount = ref(0);
    const superadminStore = useSuperadminStore();

    const infoChangeData = reactive({
      type: "number",
      count: 0,
      to: 'superadmin.requests',
      status: 1,
    });

    const submissionsData = reactive({
      type: "number",
      count: 0,
      to: 'superadmin.form.submissions',
    });

    const userGroups = [
      { name: 'Search Employers', to: 'superadmin.users.employers' },
      { name: 'Search Locals', to: 'superadmin.users.locals' },
      { name: 'Search Participants', to: 'superadmin.users.participants' }
    ];

    const fetchData = () => {
      axios.get('api/administrator/dashboard')
        .then(response => {
          superadminStore.counts.infoChange = response.data.PendingRequests;
          superadminStore.counts.submissionsData = response.data.FormSubmissionsCount;
          superadminStore.counts.claimSubmissions = response.data.ClaimSubmissionsCount;

          infoChangeData.count = response.data.PendingRequests;
          submissionsData.count = response.data.FormSubmissionsCount;
          claimSubmissionsCount.value = response.data.ClaimSubmissionsCount;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    };

    onMounted(fetchData);

    return {
      userGroups,
      infoChangeData,
      submissionsData,
      claimSubmissionsCount
    }
  },
};
</script>