import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex gap-4 mt-60 flex-wrap flex-md-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleCard = _resolveComponent("SimpleCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SimpleCard, {
      title: "New Info Change Requests",
      data: $setup.infoChangeData
    }, null, 8, ["data"]),
    _createVNode(_component_SimpleCard, {
      title: "New Form Submissions",
      data: $setup.submissionsData
    }, null, 8, ["data"]),
    _createVNode(_component_SimpleCard, {
      title: "Users & Groups",
      actionButton: "",
      data: { type: 'list', list: $setup.userGroups }
    }, null, 8, ["data"])
  ]))
}