<template>
    <div class="container mt-4">
        <Alerts :errors="errors" />
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>Contribution History</h1>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    To view detailed contributions for each Production/Employer,click on the plus sign to the left of
                    Production Name. If you wish to change the filters settings, click 'Change My Current Filters'
                    button to
                    return to the filter page.
                </div>
            </div>
            <!-- Results -->
            <div v-if="!loading" class="row">
                <HistoryResults :loading="loading" :loadingTotals="loadingTotals" :pageChanged="pageChanged"
                    :changePage="changePage" @reset-filters="resetFilters"
                    @fetch-pagination-data="fetchPaginationData" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref, computed } from 'vue';
import { History, HistoryItem } from '@/interfaces/local';
import { RouteParams, useRoute, useRouter } from 'vue-router';
import { useContributionsStore } from '@/stores/contributions';
import Alerts from '@components/utils/AlertsComponent.vue';
import HistoryResults from '@components/local/contribution-history/HistoryResults.vue';
import axios from 'axios';

export default {
    components: {
        Alerts,
        HistoryResults
    },

    setup() {
        const loading = ref(true);
        const pageChanged = ref(false);
        const changePage = ref(false);
        const loadingTotals = ref(false);
        const errors: Ref<string[]> = ref([]);
        const params: Ref<RouteParams | null> = ref(useRoute().params);
        const contributionsStore = useContributionsStore();
        const hasTotals = computed(() => (contributionsStore.fetchTotals !== null));
        const router = useRouter();
        const page = ref(1);

        const resetFilters = () => {
            contributionsStore.setContributions([]);
            contributionsStore.setTotals(null);

            router.push({ name: 'local.contribution-history' });
        }

        const searchData = async () => {
            contributionsStore.setContributions([]);

            if (!changePage.value) {
                loading.value = true;
            }

            if (changePage.value) {
                pageChanged.value = false;
            }

            await axios
                .post('api/local/contribution-history', { ...params.value, Page: page.value })
                .then(response => {
                    contributionsStore.setContributions(
                        Object.entries(response.data.Records).map(([Key, value]) => {
                            const HistoryItem = value as HistoryItem;
                            return { Key, HistoryItem };
                        }) as History[]
                    );
                    contributionsStore.setPaginator(response.data.Paginator);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loading.value = false;

                    if (changePage.value) {
                        pageChanged.value = true;
                    }

                    changePage.value = false;

                    setTimeout(() => errors.value = [], 3500)
                })
        };

        const getTotals = async () => {
            loadingTotals.value = true;

            await axios
                .post('api/local/contribution-history-totals', { ...params.value })
                .then(response => {
                    contributionsStore.setTotals(response.data);
                })
                .catch(error => {
                    console.error('There have been an error')
                })
                .finally(() => {
                    loadingTotals.value = false;
                })
        }

        const fetchPaginationData = async (pageNumber: number) => {
            page.value = pageNumber;
            changePage.value = true;
            contributionsStore.setContributions([]);

            await searchData();
        }

        onMounted(async () => {
            await searchData();

            if (!hasTotals.value) {
                await getTotals();
            }
        })

        return {
            loading,
            loadingTotals,
            errors,
            changePage,
            pageChanged,
            resetFilters,
            fetchPaginationData,
            searchData
        }
    }
}
</script>