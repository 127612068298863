<template>
    <p class="plan-header-additional"> Please Distribute <span class="detail">40%</span> allocation of <span
            class="detail">{{ beneficiary.Name }}</span> among others beneficiary.</p>

    <div class="plan-content">
        <form id="distribute-allocation-form" class="form distribute-allocation" method="POST">
            <RangeSlider :sliders="sliders" :isEditable="isEditable"/>

            <div class="action-buttons">
                <a href="" class="btn btn-primary plan-modal" @click.prevent="handleSubmit">
                    {{ isEditable ? 'Save' : 'Distribute allocation' }}
                </a>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { Slider } from '@/interfaces/interfaces';
import RangeSlider from '@/components/form/RangeSlider.vue';

export default defineComponent({
    components: {
        RangeSlider,
    },
    setup() {
        const isEditable = ref(false);
        const modalStore = useModalStore();
        const beneStore = useBeneficiaryStore();
        const beneficiary = computed(() => beneStore.beneficiary);
        const sliders = computed(() => beneStore.getSliders);

        const handleSubmit = () => {
            // If is Editable close the modal @Save, else close the modal => then open it editable
            if (isEditable.value) {
                // @handle submit request here
                modalStore.closeModal(modalStore.modals.length - 1)
            } else {
                const currentModal = modalStore.currentModal;
                if(currentModal) {
                    currentModal.isVisible = false;

                    setTimeout(() => {
                        currentModal.isVisible = true;
                        isEditable.value = true;
                    }, 300)
                }
            }
        }

        return {
            openModal: modalStore.openModal,
            handleSubmit,
            beneficiary,
            sliders,
            isEditable
        };
    }
});
</script>