<template>
    <!-- <AlertsComponent :error="error" /> -->
    <div class="plan-content">
        <BeneficiaryCard v-if="dependentsCards" title="Beneficiary" mode="multiple" :data="dependentsCards"
            :addItem="true" :hasDivider="true" customClass="green-card beneficiary-card" @add="addItem"
            @edit-dep="handleEditDep" :discard="discard" />

        <div class="action-buttons">
            <a href="" class="btn btn-secondary" @click.prevent="resetModal"> Discard </a>
            <a href="" class="btn btn-primary plan-modal" @click.prevent="handleSaveSelection">
                Save and Continue
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, watch } from 'vue';

import BeneficiaryCard from '@/components/cards/BeneficiaryCard.vue';
import { useBeneficiaryStore } from '@/stores/beneficiary';

export default defineComponent({
    components: {
        BeneficiaryCard,
    },
    setup(_, { emit }) {
        const beneStore = useBeneficiaryStore();
        const dependentsCards = computed(() => beneStore.getDependentsCards(false));
        const selectedDependents = ref<string[]>([]);
        const discard = ref<boolean>(false);

        const closeModal = () => {
            emit('close');
        }

        const resetModal = () => {
            discard.value = true;
        }

        const handleSaveSelection = () => {
            const selectedIds = Object.values(selectedDependents.value);
            const newDependentsToAdd = selectedIds.filter(id =>
                !beneStore.newBeneficiaries?.some(beneficiary => beneficiary.DependentID == id)
            );

            if (newDependentsToAdd.length > 0) {
                beneStore.getDependentsCards(true, Object.values(selectedDependents.value));
            }

            closeModal();
        }

        const addItem = (payload: string[]) => {
            selectedDependents.value = payload;
        }

        const handleEditDep = (payload: string | number) => {
            beneStore.editDependent(payload);
            beneStore.dependentEdit = true;
            closeModal();
        }

        return {
            dependentsCards,
            discard,
            resetModal,
            closeModal,
            addItem,
            handleEditDep,
            handleSaveSelection,
        }
    }
})
</script>
