<template>
    <MessageCard message="Your most recent claims history is displayed below. To view a specific claim listed, click on the claim number. For those received via this website, but not yet reviewed, you can click on the Web Reference number to view /edit your uploaded documents or add additional documents. To search for a specific date range or for claims not listed below please click the Search button and choose your search criteria." type="danger" iconClass="iatse-icon-alarm" />
    <LoaderComponent v-if="loading" />
    <!-- Plan Content -->
    <div v-else class="plan-content">
        <!-- Form -->
        <form id="plan-c-mrp-claims-form" class="form plan-c-mrp-claims" method="POST">
            <div class="cards">
                <div class="card transparent">
                    <div class="card-header">
                        <h2>New Claims</h2>
                        <a href="" class="btn btn-primary plan-modal"
                            @click.prevent="openModal('claim-submission', 'New Claim Submission', 'plan-upload plan-card-info')">
                            Submit a New Claim </a>
                    </div>
                </div>
                <div class="card transparent">
                    <div class="card-header">
                        <h2>Existing Claims</h2>
                        <a href="" class="btn btn-primary full-width"
                            @click.prevent="openModal('claim-search', 'Claim History Search', 'plan-upload plan-card-info')">
                            Go To Existing Claim
                        </a>
                    </div>
                </div>
            </div>
            <div class="content">
                <CoveredCard :links="links" />

                <!-- Table -->
                <div class="table-wrapper">
                    <DataTable :table="data" classes="claims small scrollable" />
                </div>

                <div class="action-buttons">
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('check-destination', 'Payment Destionation Setup', 'plan-form', 'medicare', 'check')">
                        Change Mailing Address
                    </a>
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('check-destination', 'Payment Destionation Setup', 'plan-form', 'medicare', 'directdeposit')">
                        Claims Payment Setup
                    </a>
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('claim-search', 'Claim History Search', 'plan-upload plan-card-info')">
                        Search Claim History
                    </a>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useStepStore } from '@/stores/step';
import { useClaimStore } from '@/stores/claim';
import DataTable from '@/components/form/DataTable.vue';
import CoveredCard from '@/components/participant/claims/CoveredCard.vue';

export default defineComponent({
    components: {
        DataTable,
        CoveredCard
    },
    setup() {
        const modalStore = useModalStore();
        const stepStore = useStepStore();
        const claimStore = useClaimStore();
        const data = computed(() => claimStore.claims);
        const loading = ref(true);

        const fetchData = async () => {
            loading.value = true;
            claimStore.clearClaim();

            await claimStore.fetchClaims();

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
            stepStore.setCurrentStep(0);
        });

        return {
            data,
            loading,
            links: claimStore.claimLinks,
            openModal: modalStore.openModal,
        }

    }
})
</script>