import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-content" }
const _hoisted_2 = { class: "action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BeneficiaryCard = _resolveComponent("BeneficiaryCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dependentsCards)
      ? (_openBlock(), _createBlock(_component_BeneficiaryCard, {
          key: 0,
          title: "Beneficiary",
          mode: "multiple",
          data: _ctx.dependentsCards,
          addItem: true,
          hasDivider: true,
          customClass: "green-card beneficiary-card",
          onAdd: _ctx.addItem,
          onEditDep: _ctx.handleEditDep,
          discard: _ctx.discard
        }, null, 8, ["data", "onAdd", "onEditDep", "discard"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "",
        class: "btn btn-secondary",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetModal && _ctx.resetModal(...args)), ["prevent"]))
      }, " Discard "),
      _createElementVNode("a", {
        href: "",
        class: "btn btn-primary plan-modal",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSaveSelection && _ctx.handleSaveSelection(...args)), ["prevent"]))
      }, " Save and Continue ")
    ])
  ]))
}