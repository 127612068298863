<template>
    <div class="plan-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <p>
                        By digitally signing, the undersigned hereby certifies that:
                    </p>
                    <p>
                        I wish to enroll in the Plan C Medical Reimbursement Program (MRP) as a stand-alone option, as described in the Plan C Summary Plan Description booklet.
                    </p>
                    <p>
                        To be eligible for the Plan C-MRP as a stand-alone option, I understand that I, and any dependents I enroll, must be covered by a group health plan (i.e. employer or union sponsored) that provides minimum value as defined in the Patient Protection and Affordable Care Act (ACA).
                    </p>
                    <p>
                        Accordingly, to enroll in this option and waive enrollment in Plan C1, C2, C3 or C4, I must click the box and submit proof of other group health coverage (copy of front and back of coverage ID card along with a statement from the insurer or plan sponsor if my ID card does not specify that it is group coverage).
                    </p>
                    <p>
                        By clicking the box, I hereby certify that my and my enrolled dependents’ (if any) other medical coverage is through an employer or union sponsored group health plan that provides minimum value under the ACA. In addition, I understand that recertification and proof of other group health coverage (employer or union sponsored) must be submitted annually at the Plan’s Open Enrollment period.
                    </p>
                    <p>
                        I also agree to inform the Fund immediately if I (or my dependents) lose such other coverage.
                    </p>

                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between">
                        <label for=""><input type="checkbox" v-model="termsAccepted">  By checking this box, you are certifying that you have read, understood and agree to the conditions listed herein.</label>
                        <button :disabled="!termsAccepted" @click.prevent="proceed" class="btn btn-primary">Proceed</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCoverageStore } from '@/stores/coverage';

export default {
    setup() {
        const openModal = useModalStore().openModal;
        const termsAccepted = ref(false);

        const proceed = () => {
            openModal('hw-mrp-documents', 'Attach MRP Documentation', 'plan-c-mrp active');
        };

        return {
            openModal,
            termsAccepted,
            proceed
        };
    }
};
</script>