<template>
  <AlertComponent :success="success" :error="error" />

  <div class="pdt-30">
    <div class="d-flex justify-content-between align-items-center pdb-20">
      <h2> Info Change Request </h2>
      <button class="btn btn-primary" @click="exportToExcel">
        {{ loadingExport ? 'Exporting...' : 'Export To Excel' }}
      </button>
    </div>
    <div class="bordered-card bordered-card_secondary">
      <div class="filters d-flex gap-4 flex-wrap flex-md-nowrap justify-content-between w-100">
        <div class="d-flex gap-4 col-md-6 col-12">
          <SelectComponent :defaultOption="{ key: 0, value: 'Request Type' }" @update:options="handleRequestType"
            :options="requestTypes" :single-arrow="true" type="filters" customClass="fs-xs" />
          <SelectComponent v-model:selectedOption="filters.Status" :options="statuses" :single-arrow="true"
            customClass="fs-xs" />
        </div>
        <TextInput type="text" name="mixed" placeholder="Search by Id, and Name..." :max=30 v-model:modelValue="filters.Search"
          customClass="search col-md-4 col-sm-6" />
      </div>
      <LoaderComponent v-if="loading" />
      <table v-else class="small striped toggle no-underline min-height">
        <thead class="large large_padding">
          <tr>
            <th> NBF Participant ID </th>
            <th> First Name </th>
            <th> Last Name </th>
            <th> Web Submission Date </th>
            <th> Request Type </th>
            <th> Status </th>
          </tr>
        </thead>
        <tbody v-if="requests && requests.length">
          <tr class="large" v-for="request in requests" :key="request.UIAdminID">
            <td class="plan-modal" @click="reviewRequest(request.UIAdminID, 'Review ' + request.RequestType)">
              <a href="javascript;" @click.prevent>
                {{ request.PersonID }}
              </a>
            </td>
            <td> {{ request.FirstName }} </td>
            <td> {{ request.LastName }} </td>
            <td>{{ request.WebSubmissionDate }} </td>
            <td>{{ request.RequestType }} </td>
            <td class="cell col-md-1">
              <small class="label label_small label_green_secondary w-fit"
                :class="{ 'label_danger_secondary': request.Status == 'Denied', 'label_blue': request.Status == 'Pending' }">
                <span class="bullet"></span>
                {{ request.Status }}
              </small>
            </td>
          </tr>
        </tbody>
        <tbody v-else class="">
          <tr>
            <td colspan="6">
              <p class="text-center"> No requests have been found</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <PaginationComponent v-if="requests && requests.length > 0 && !loading" :paginator="paginator"
      @fetch-data="fetchPaginationData" />
  </div>
</template>

<script lang="ts">
import { watch, ref, reactive, onMounted, Ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { Request, Paginator, SelectOption } from '@interfaces/interfaces';
import { saveAs } from 'file-saver';
import { useModalStore } from '@/stores/modal';
import { useSuperadminStore } from '@/stores/superadmin';
import axios from 'axios';

import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";
import PaginationComponent from '@components/utils/PaginationComponent.vue';

export default {
  components: {
    PaginationComponent,
    TextInput,
    SelectComponent,
  },

  setup() {
    const superadminStore = useSuperadminStore();
    const success = ref(null);
    const loading = ref(false);
    const loadingSearch = ref(false);
    const error = ref(null);
    const exportExcel = ref(0);
    const loadingExport = ref(false);
    const requestTypes = computed(() => superadminStore.requestsPage.requestTypes)
    const statuses = computed(() => superadminStore.requestsPage.statuses)
    const paginator = computed(() => superadminStore.requestsPage.paginator)
    const requests = computed(() => superadminStore.requestsPage.requests)
    const filters = reactive({
      RequestType: [] as string[],
      Status: { key: 0, value: 'Status' },
      Search: null,
    });

    const debouncedHandleSearch = async () => {
      loadingSearch.value = true;
      superadminStore.requestsPage.page = 0;

      await fetchData(false);
      loadingSearch.value = false;
    }

    const handleRequestType = (payload: string[]) => {
      filters.RequestType = payload;
      debouncedHandleSearch();
    }

    watch(filters, () => {
      debouncedHandleSearch();
    });

    const exportToExcel = async () => {
      loadingExport.value = true;
      exportExcel.value = 1;
      await handleDownload();
      exportExcel.value = 0;
      loadingExport.value = false;
    }

    // Handle Download Excel File
    const handleDownload = async () => {
      try {
        const response = await axios.get(`api/administrator/change-requests`, {
          params: { Export: exportExcel.value },
          responseType: 'blob',
        });

        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        saveAs(blob, 'requests.xlsx');
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    const fetchData = async (isLoading: boolean) => {
      if (isLoading) {
        loading.value = true;
      }

      await superadminStore.fetchRequests({ ...filters, Status: filters.Status.key, RequestType: filters.RequestType });

      if (isLoading) {
        loading.value = false;
      }
    };

    const fetchPaginationData = (pageNumber: number) => {
      superadminStore.requestsPage.page = pageNumber;
      fetchData(false);
    }

    const reviewRequest = (requestID: number, title: string) => {
      superadminStore.reviewReq = requestID;
      useModalStore().openModal('review-request', title, 'plan-upload plan-card-info');
    }

    onMounted(async () => await fetchData(true));

    return {
      statuses,
      requestTypes,
      filters,
      success,
      loading,
      loadingExport,
      error,
      requests,
      paginator,
      exportToExcel,
      reviewRequest,
      fetchData,
      handleRequestType,
      fetchPaginationData
    };
  },
};
</script>
