<template>
    <div class="bordered-card bordered-card_secondary gap-10">
        <h4 class="fw-bold">Reports</h4>
        <div class="d-flex gap-10 w-100 flex-md-row flex-column">
            <div class="bordered-card bordered-card_secondary justify-content-between pdt-25 pdb-25 gap-25 flex-md-row flex-wrap">
                <div class="d-flex flex-column align-items-start gap-10 flex-2">
                    <p class="fw-bold">Contributions & Work History Report </p>
                    <h6 class="card-title"> Lorem ipsum dolor sit amet consectetur. Malesuada diam velit etiam etiam
                        consequat. </h6>
                </div>
                <router-link class="btn btn-primary border-radius-8 pdl-30 pdr-30 w-sm-100 w-fit flex-1"
                    :to="{ name: 'local.contribution-history' }">
                    Work History Report
                </router-link>
            </div>
            <div class="bordered-card bordered-card_secondary justify-content-between pdt-25 pdb-25 gap-25 flex-md-row flex-wrap">
                <div class="d-flex flex-column align-items-start gap-10 flex-2">
                    <p class="fw-bold">Create your own report </p>
                    <h6 class="card-title"> Lorem ipsum dolor sit amet consectetur. Malesuada diam velit etiam etiam
                        consequat. </h6>
                </div>
                <router-link class="btn btn-primary border-radius-8 pdl-30 pdr-30 w-sm-100 w-fit flex-1"
                    :to="{ name: 'local.create-report' }">
                    Create Report
                </router-link>
            </div>
        </div>

        <!-- <div v-for="(contribution) in contributions" :key="contribution.EmployerAliasID"
            class="w-100">
            <div
                class="contribution-item d-flex gap-2 align-items-center justify-content-between w-100">
                <router-link :to="{
                    name: 'local.recent-contribution', params: {
                            Employers: contribution.EmployerAliasID,
                            StartDate: contribution.WorkStart,
                            EndDate: contribution.WorkEnd
                        }
                    }" class="contribution-link w-100"
                >
                    {{ contribution.Employer }}
                </router-link>
                <span class="contribution-date w-100">{{ contribution.ReceiptDate }}</span>
            </div>
        </div> -->
    </div>
</template>

<script lang="ts">
import { ContributionPreview } from '@/interfaces/local';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        contributions: {
            type: Object as PropType<ContributionPreview | null>,
            required: false
        }
    }
})
</script>