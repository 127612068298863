import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmployerAuth = _resolveComponent("EmployerAuth")!
  const _component_EmployerRegistration = _resolveComponent("EmployerRegistration")!

  return (_ctx.employerType === 'auth')
    ? (_openBlock(), _createBlock(_component_EmployerAuth, {
        key: 0,
        states: _ctx.states,
        loadingSubmit: _ctx.loadingSubmit,
        errors: _ctx.errors
      }, null, 8, ["states", "loadingSubmit", "errors"]))
    : (_ctx.employerType === 'register')
      ? (_openBlock(), _createBlock(_component_EmployerRegistration, {
          key: 1,
          loadingSubmit: _ctx.loadingSubmit,
          securityQuestions: _ctx.securityQuestions,
          employerRequest: _ctx.employerRequest,
          errors: _ctx.errors
        }, null, 8, ["loadingSubmit", "securityQuestions", "employerRequest", "errors"]))
      : _createCommentVNode("", true)
}