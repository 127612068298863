import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-content" }
const _hoisted_2 = {
  key: 1,
  class: "plan-details flex-column"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_ProfileInfo = _resolveComponent("ProfileInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ProfileInfo, {
            title: "Personal Information",
            actionButton: null,
            items: _ctx.personItems,
            classes: "plan-general-superadmin light"
          }, null, 8, ["items"]),
          _createVNode(_component_ProfileInfo, {
            title: "Contact Information",
            actionButton: null,
            items: _ctx.contactItems,
            classes: "plan-general-superadmin light"
          }, null, 8, ["items"]),
          _createVNode(_component_ProfileInfo, {
            title: "Addresses",
            actionButton: null,
            items: _ctx.addressItems,
            multiple: true,
            classes: "plan-general-superadmin light"
          }, null, 8, ["items"])
        ]))
  ]))
}