import { defineStore } from "pinia";
import AlertsComponent from "@/components/user/AlertsComponent.vue";
import ArchivedAlerts from "@/components/user/ArchivedAlerts.vue";
import AccountSettings from "@/components/user/AccountSettings.vue";
import ProfileComponent from "@/components/user/ProfileComponent.vue";
import PersonalInformation from "@/components/user/profile/PersonalInformation.vue";
import AddressInformation from "@/components/user/profile/AddressInformation.vue";
import ContactInformation from "@/components/user/profile/ContactInformation.vue";
import VacationHistory from "@/components/participant/vacation/VacationHistory.vue";
import CheckReplacement from "@/components/participant/vacation/CheckReplacement.vue";
import CheckDestination from "@/components/participant/vacation/CheckDestination.vue";
import AnnuityApplication from "@/components/participant/annuity/AnnuityApplication.vue";
import PensionApplication from "@/components/participant/pension/PensionApplication.vue";
import PensionerProfile from "@/components/participant/pension/PensionerProfile.vue";
import PensionCreditsHistory from "@/components/participant/pension/PensionCreditsHistory.vue";
import PensionPaymentHistory from "@/components/participant/pension/PensionPaymentHistory.vue";
import DependentsTable from "@/components/participant/dependents/DependentsTable.vue";
import AddDependent from "@/components/participant/dependents/AddDependent.vue";
import BeneficiariesTable from "@/components/participant/beneficiaries/BeneficiariesTable.vue";
import CreateFromDependents from "@/components/participant/beneficiaries/CreateFromDependents.vue";
import AddBeneficiary from "@/components/participant/beneficiaries/AddBeneficiary.vue";
import DistributeAllocation from "@/components/participant/beneficiaries/DistributeAllocation.vue";
import AssignFund from "@/components/participant/beneficiaries/AssignFund.vue";
import MissingContribution from "@/components/participant/MissingContribution.vue";
import ClaimsComponent from "@/components/participant/claims/ClaimsComponent.vue";
import ClaimSubmission from "@/components/participant/claims/ClaimSubmission.vue";
import ClaimHistoryResults from "@/components/participant/claims/ClaimHistoryResults.vue";
import ClaimHistorySearch from "@/components/participant/claims/ClaimHistorySearch.vue";
import CappCoPayment from "@/components/participant/coverage/CappCoPayment.vue";
import MakeCoverage from "@/components/participant/coverage/MakeCoverage.vue";
import SuccessModal from "@/components/cards/SuccessModal.vue";
import EnterCodeTwoFactor from '@/components/EnterCodeTwoFactor.vue';
import WorkHistoryView from "@/views/participant/WorkHistoryView.vue";
import DocumentsView from "@/views/participant/dependents/DocumentsView.vue";
import ClaimsDocuments from "@/components/participant/claims/ClaimsDocuments.vue";
import PlanCSummary from "@/views/participant/health-welfare/PlanCSummary.vue";
import CappStatement from "@/views/participant/health-welfare/CappStatement.vue";
import CappCheckout from "@/views/participant/health-welfare/CappCheckout.vue";
import MrpTerms from "@/views/participant/health-welfare/MrpTerms.vue";
import MrpDocuments from "@/views/participant/health-welfare/MrpDocuments.vue";
import CappPaymentsHistory from "@/views/participant/health-welfare/CappPaymentsHistory.vue";
import MrpHistory from '@/views/participant/health-welfare/MrpHistory.vue';
import PlanRmrpSummary from "@/views/participant/health-welfare/PlanRmrpSummary.vue";
import PlanASummary from "@/views/participant/health-welfare/PlanASummary.vue";
import PlanCobraSummary from "@/views/participant/health-welfare/PlanCobraSummary.vue";
import CobraPaymentsHistory from "@/views/participant/health-welfare/CobraPaymentsHistory.vue";
import CobraStatements from "@/views/participant/health-welfare/CobraStatements.vue";
import CobraCheckout from "@/views/participant/health-welfare/CobraCheckout.vue";
import PlanRetireeSummary from "@/views/participant/health-welfare/PlanRetireeSummary.vue";
import RetireePaymentsHistory from "@/views/participant/health-welfare/RetireePaymentsHistory.vue";
import CoverageHistory from "@/components/participant/health-welfare/CoverageHistory.vue";
import RequestIDCard from "@/components/participant/health-welfare/RequestIDCard.vue";
import EditAlert from "@/views/superadmin/alerts/EditAlert.vue";
import EditUser from "@/components/superadmin/users/EditUser.vue";
import AddAutoresponder from "@/views/superadmin/autoresponders/AddAutoresponder.vue";
import ReviewRequestView from "@/views/superadmin/requests/ReviewRequestView.vue";
import SmartSearch from "@/views/smartsearch/SmartSearch.vue";
import CreateUser from "@/views/superadmin/CreateUser.vue";
import EditDemographics from "@/components/user/EditDemographics.vue";
import RepresentativePin from "@/views/local/RepresentativePin.vue";
import MissingContributionsInquiry from "@/views/local/MissingContributionsInquiry.vue";
import MemberProfile from "@/views/local/MemberProfile.vue";
import UploadBaa from "@/components/local/UploadBaa.vue";
import LocalProfile from "@/components/local/LocalProfile.vue";
import PaymentStatus from "@/components/PaymentStatus.vue";
import { ModalProps, HeaderButton } from "@/interfaces/interfaces";
import { Component } from "vue";

interface Modal {
    type: string;
    title?: string | null;
    classes?: string | null;
    fund?: string | null;
    isVisible?: boolean;
    modalProps?: ModalProps | null;
    secondTitle?: string | null;
    headerButtons?: HeaderButton[] | null;
    queryParams?: Record<string, string|boolean|null> | null;
    onClose?: () => void;
}

type ComponentMap = {
    [key: string]: Component | null;
};

export const useModalStore = defineStore({
    id: "modal",
    state: () => ({
        modals: [] as Modal[],
        currentModal: null as Modal | null,
    }),

    actions: {
        openModal(
            type: string,
            title?: string | null,
            classes?: string | null,
            fund?: string | null,
            modalProps?: ModalProps | null,
            secondTitle?: string | null,
            headerButtons?: HeaderButton[] | null,
            queryParams?: Record<string, string| boolean| null> | null,
            onClose?: () => void
        ) {
            const existingModal = this.modals.findIndex(
                (modal) => modal.type === type
            );

            if (existingModal !== -1) {
                this.closeAbove(existingModal);
            } else {
                const newModal = {
                    type,
                    title,
                    classes,
                    fund,
                    modalProps,
                    secondTitle,
                    headerButtons,
                    isVisible: true,
                    queryParams,
                    onClose
                };

                const mainContent = document.querySelector(".main-content");
                if (mainContent) mainContent.scrollTop = 0;
                else window.scrollTo(0, 0);

                this.modals.push(newModal);
                this.currentModal = newModal;
            }
        },
        closeModal(index: number) {
            const modal = this.modals[index];
            if (!modal) return;

            // onClose function
            if (typeof modal.onClose === "function") {
                modal.onClose();
            }

            modal.isVisible = false;

            if (modal.type !== "success-modal") {
                setTimeout(() => this.modals.splice(index, 1), 300);
            } else {
                setTimeout(() => this.modals.splice(index, 1), 50);
            }
        },
        closeLastModal() {
            this.closeModal(this.modals.length - 1);
        },
        closeAllModals() {
            this.modals.forEach((modal) => (modal.isVisible = false));
            setTimeout(() => (this.modals = []), 300);
        },
        closeAbove(index: number) {
            for (let i = this.modals.length - 1; i > index; i--) {
                const modal = this.modals[i];
                if (!modal) return;

                // onClose function
                if (typeof modal.onClose === "function") {
                    modal.onClose();
                }

                modal.isVisible = false;

                setTimeout(() => this.modals.splice(i, 1), 300);
            }
        },
        handleSubmit(payload?: string) {
            const index = this.modals.length - 1;
            const topModal = this.modals[index];

            if (
                topModal?.modalProps &&
                typeof topModal.modalProps.onConfirm === "function"
            ) {
                const onConfirm = topModal.modalProps.onConfirm;
                this.closeModal(index);
                setTimeout(() => onConfirm(payload), 300);
            }
        },
        getModalContent(type: string): ComponentMap[keyof ComponentMap] | null {
            const componentsMap: ComponentMap = {
                alerts: AlertsComponent,
                profile: ProfileComponent,
                dependents: DependentsTable,
                beneficiaries: BeneficiariesTable,
                vacation: VacationHistory,
                claims: ClaimsComponent,
                smartsearch: SmartSearch,
                "archived-alerts": ArchivedAlerts,
                "account-settings": AccountSettings,
                "update-personal-info": PersonalInformation,
                "update-address": AddressInformation,
                "update-contact-information": ContactInformation,
                "check-replacement": CheckReplacement,
                "check-destination": CheckDestination,
                "annuity-application": AnnuityApplication,
                "pension-application": PensionApplication,
                "pensioner-profile": PensionerProfile,
                "pension-credits-history": PensionCreditsHistory,
                "pension-payment-history": PensionPaymentHistory,
                "work-history": WorkHistoryView,
                "add-dependent": AddDependent,
                "dependent-documents": DocumentsView,
                "claim-documents": ClaimsDocuments,
                "add-beneficiary": AddBeneficiary,
                "create-from-dependents": CreateFromDependents,
                "distribute-allocation": DistributeAllocation,
                "assign-fund": AssignFund,
                "missing-contribution": MissingContribution,
                "claim-submission": ClaimSubmission,
                "claim-history-results": ClaimHistoryResults,
                "claim-search": ClaimHistorySearch,
                "make-coverage": MakeCoverage,
                "make-co-payment": CappCoPayment,
                "success-modal": SuccessModal,
                "enter-code-2fa": EnterCodeTwoFactor,
                "hw-plan-c-summary": PlanCSummary,
                "hw-capp-statement": CappStatement,
                "hw-capp-checkout": CappCheckout,
                "hw-mrp-terms": MrpTerms,
                "hw-mrp-documents": MrpDocuments,
                "hw-capp-payments-history": CappPaymentsHistory,
                "hw-mrp-history": MrpHistory,
                "hw-plan-rmrp-summary": PlanRmrpSummary,
                "hw-plan-a-summary": PlanASummary,
                "coverage-history": CoverageHistory,
                "request-card": RequestIDCard,
                "hw-plan-cobra-summary": PlanCobraSummary,
                "edit-alert": EditAlert,
                "add-autoresponder": AddAutoresponder,
                "review-request": ReviewRequestView,
                "edit-user": EditUser,
                "hw-cobra-payments-history": CobraPaymentsHistory,
                "hw-cobra-statements": CobraStatements,
                "hw-cobra-checkout": CobraCheckout,
                "hw-plan-retiree-summary": PlanRetireeSummary,
                "hw-retiree-payments-history": RetireePaymentsHistory,
                'payment-status': PaymentStatus,
                "superadmin-create-user": CreateUser,
                "user-edit-demographics": EditDemographics,
                "local-representative-pin": RepresentativePin,
                "local-missing-contributions": MissingContributionsInquiry,
                "local-member-profile": MemberProfile,
                "local-profile": LocalProfile,
                "local-upload-baa": UploadBaa,
            };

            return componentsMap[type] || null;
        },
    },
});
