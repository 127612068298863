import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "range-slider" }
const _hoisted_2 = {
  key: 0,
  class: "main-label"
}
const _hoisted_3 = { class: "range-slider-input" }
const _hoisted_4 = { class: "range-slider-input_wrapper" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = ["onUpdate:modelValue", "min", "max", "step", "id", "disabled", "onInput"]
const _hoisted_7 = { class: "slider-value" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliders, (slider) => {
      return (_openBlock(), _createElementBlock("div", {
        key: slider.id,
        class: "range-slider-item"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(slider.label || 'Slider'), 1),
            _createVNode(_component_RadioButton, {
              options: _ctx.beneTypes,
              name: `${_ctx.title}-${slider.label}-Type`,
              modelValue: slider.level,
              "onUpdate:modelValue": ($event: any) => ((slider.level) = $event),
              id: `${_ctx.title}-${slider.label}-Type`
            }, null, 8, ["options", "name", "modelValue", "onUpdate:modelValue", "id"])
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "range",
            class: "slider",
            "onUpdate:modelValue": ($event: any) => ((_ctx.values[slider.entityId]) = $event),
            min: slider.min || 0,
            max: slider.max || 100,
            step: slider.step || 1,
            id: slider.id,
            style: _normalizeStyle({ '--value': _ctx.sliderValuesAsPercentage[slider.entityId] + '%' }),
            disabled: !_ctx.isEditable || !slider.editable,
            onInput: ($event: any) => (_ctx.updateValue(slider.entityId, slider.id))
          }, null, 44, _hoisted_6), [
            [_vModelText, _ctx.values[slider.entityId]]
          ])
        ]),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.sliderValuesAsPercentage[slider.entityId] > 100 ? 100.00 : _ctx.sliderValuesAsPercentage[slider.entityId]) + "%", 1),
        (_ctx.deleteButton)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: "",
              class: "btn btn-secondary",
              onClick: _withModifiers(($event: any) => (_ctx.deleteSlider(slider.entityId, slider.id)), ["prevent"])
            }, "Delete", 8, _hoisted_8))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}