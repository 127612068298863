<template>
    <Alerts :errors="errors" :success="success" />
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content gap-25">
        <div class="d-flex gap-20 justify-content-between w-100">
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=25
                v-model:modelValue="localInfo.FirstName" :readonly="true" />
            <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12
                v-model:modelValue="localInfo.MiddleName" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="localInfo.LastName" :readonly="true" />
            <TextInput type="text" label="Designation" placeholder="Enter Designation" :max=100
                v-model:modelValue="localInfo.Designation" :readonly="true" />
        </div>

        <!-- Update Phone Number -->
        <form class="d-flex gap-20 justify-content-between w-100">
            <TextInput type="text" name="phone" label="Phone" placeholder="Phone" :max=30
                v-model:modelValue="localInfo.Phone" :required="true" custom-class="w-75" />
            <div class="h-fit d-flex justify-content-end w-25 flex-column">
                <button type="submit" class="btn btn-secondary toggle-control lh-1 text-nowrap h-fit"
                    :disabled="loadingUpdate['password']" @click.prevent>
                    Change Phone no
                </button>
            </div>
        </form>

        <div class="divider mt-0 mb-0"></div>

        <!-- Update Email -->
        <form id="email-change-form" class="d-flex gap-20 justify-content-between w-100" @submit.prevent="updateEmail">
            <TextInput type="email" v-model:modelValue="email" label="Email Address" :placeholder=userEmail :readonly="true" custom-class="w-75" />
            <div class="h-fit d-flex justify-content-end w-25 flex-column">
            <button type="submit" class="btn btn-secondary toggle-control lh-1 text-nowrap h-fit"
                :disabled="loadingUpdate['password']"> Change Email Address </button>
            </div>
        </form>

        <div class="divider mt-0 mb-0"></div>

        <!-- Update Password -->
        <form id="password-change-form" class="d-flex gap-20 justify-content-between w-100" @submit.prevent="updatePassword">
            <div class="w-75 d-flex gap-20">
                <TextInput type="password" label="New Password" placeholder="Enter New password"
                    v-model:modelValue="password" name="password" />
                <TextInput type="password" label="Confirm Password" placeholder="Enter Confirm New password"
                    v-model:modelValue="passwordConfirmation" name="password_confirmation" />
            </div>
            <div class="h-fit d-flex justify-content-end w-25 flex-column">
                <button type="submit" class="btn btn-secondary toggle-control lh-1 text-nowrap h-fit" :disabled="loadingUpdate['password']"> Change Password </button>
            </div>
        </form>

        <div class="divider mt-0 mb-0"></div>

          <!-- Update Security Questions -->
          <form id="security-questions-form" class="form account-settings" @submit.prevent="updateSecurityQuestion">
            <h3> Security Questions
                <small v-if="numberOfQuestions > 1" class="fs-sm pdl-20 fw-light"> *Setup at least 3 Security Questions.</small>
            </h3>
            <template v-if="userQuestions.length || selectedQuestions.length">
                <SecurityQuestionSelect v-for="index in selectedQuestions.length" :key="index"
                    label="Security Question"
                    :index="index"
                    :options="availableQuestionsForSelect(`select${index}`).value"
                    :userQuestion="userQuestions[index - 1]"
                    :selected-question="selectedQuestions[index - 1]"
                    :questionId="userQuestions[index - 1]?.ID"
                    :resetInputs="resetInputs"
                    :showAddNewQuestion="showAddNewQuestion"
                    @handle-questions="handleQuestions"
                    @delete="removeQuestion"
                    @toggle-edit="editQuestion"
                />
            </template>

            <a href="" v-if="selectedQuestions.length < 3" @click.prevent="addNewQuestion" class="btn btn-secondary add-btn">
                <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p> Add New Security Question
            </a>

            <div class="d-flex w-full gap-2" v-if="hasEditable">
                <button type="submit" class="btn btn-primary action-btn"> Update </button>
                <a href="" @click.prevent="discardChanges" class="btn btn-secondary action-btn"> Discard </a>
            </div>
        </form>

        <!-- 2 Factor Authentication -->
        <form id="two-fa-form" class="form account-settings" @submit.prevent="setTwoFactorAuth">
            <h3 class="w-100"> Two-Factor Authentication (Required) </h3>
            <a href="" @click.prevent="toggleUpdateSetting('twoFactorAuth')"
                class="btn btn-secondary initial-btn toggle-control"> Setup 2-Factor Authentication </a>

            <transition name="slide-fade">
                <div class="w-full update-inputs content-toggle" v-if="updateSettingsState.twoFactorAuth">
                    <TextInput type="tel" label="Verified Phone numberd" placeholder="******3333" :max=12
                        :modelValue="phoneNumber" />
                    <a href="" class="btn btn-secondary toggle-control"
                        @click.prevent="toggleUpdateSetting('twoFactorAuth')"> Configure </a>
                </div>
            </transition>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, computed } from 'vue';
import { SecurityQuestion } from '@/interfaces/interfaces';
import { SelectedQuestion, SelectedQuestionRecord } from '@/interfaces/employer';
import { useLocalStore } from '@/stores/local';
import { useAuthStore } from '@/stores/auth';
import axios from 'axios';

import SecurityQuestionSelect from '@components/user/SecurityQuestionSelect.vue';
import TextInput from '@components/form/TextInput.vue';
import Alerts from '@components/utils/AlertsComponent.vue';

type SettingsState = {
    password: boolean;
    email: boolean;
    securityQuestion: boolean;
    twoFactorAuth: boolean;
    newEmail: boolean;
};

type SettingType = keyof SettingsState;

export default {
    components: {
        SecurityQuestionSelect,
        TextInput,
        Alerts
    },
    setup() {
        const localStore = useLocalStore();
        const securityQuestionsArray: Ref<Array<{ key: number, value: string }>> = ref([]);
        const userQuestions: Ref<SecurityQuestion[]> = ref([]);
        const localInfo = computed(() => localStore.general);
        const showPasswordConfirmation = ref(false);
        const currentPassword = ref('');
        const password = ref('');
        const passwordConfirmation = ref('');
        const email = ref('');
        const newEmail = ref('');
        const emailConfirmation = ref('');
        const emailReadOnly = ref(true);
        const phoneNumber = ref('');
        const errors = ref<string[]>([]);
        const success = ref('');
        const userEmail = ref('');
        const selectedQuestions: Ref<SelectedQuestion[]> = ref([]);
        const loading = ref(true);
        const resetInputs = ref(false);
        const hasEditable = computed(() => Object.values(selectedQuestions.value).some(question => question.IsEditable === true));
        const showAddNewQuestion = ref(selectedQuestions.value.length < 3);
        const numberOfQuestions = 3;

        const updateSettingsState = ref<SettingsState>({
            password: false,
            email: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newEmail: false,
        });

        const loadingUpdate = ref<SettingsState>({
            password: false,
            email: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newEmail: false,
        });


        // Toggle the visibility of editable fields
        const toggleUpdateSetting = (settingType: SettingType) => {
            updateSettingsState.value[settingType] = !updateSettingsState.value[settingType];
        };


        const togglePasswordConfirmation = () => {
            showPasswordConfirmation.value = !showPasswordConfirmation.value;
        }

        const discardChanges = () => {
            resetInputs.value = true;

            selectedQuestions.value = selectedQuestions.value.map((question: SelectedQuestion) => {
                if (question.IsEditable) {
                    return {
                        ...question,
                        Answer: "",
                        Question: 0,
                    };
                }

                return question;
            });
        }

        const editQuestion = (index: number) => {
            showAddNewQuestion.value = false;

            (selectedQuestions.value[index] as SelectedQuestion).IsEditable = !(selectedQuestions.value[index] as SelectedQuestion)?.IsEditable;
        }

        const removeQuestion = (id: number) => {
            axios.delete(`api/user/settings-remove-question/${id}`)
                .then(response => {
                    success.value = response.data.success;

                    fetchData(false);
                })
                .catch((error: any) => {
                    if (error.response) {
                        errors.value = Object.values(error.response.data.errors).flat() as string[];
                    }
                })
        }

        const addNewQuestion = () => {
            showAddNewQuestion.value = true;

            const questionObj: SelectedQuestion = {
                Answer: "",
                ExistingQuestion: 0,
                Question: 0,
                IsEditable: true,
            }

            selectedQuestions.value.push(questionObj);
        }

        const selectedQuestionsId = ref<{ [key: string]: number }>({ select1: 0, select2: 0, select3: 0 });

        // Get Available Security Questions
        const availableQuestionsForSelect = (selectKey: string) => computed(() => {
            return securityQuestionsArray.value?.filter(q => {
                return !Object.entries(selectedQuestionsId.value)
                    .filter(([key, value]) => key !== selectKey && value !== 0)
                    .map(([, value]) => value)
                    .includes(q.key as number);
            });
        })

        // Handle Security Questions Changes
        const handleQuestions = (payload: SelectedQuestion, index: number) => {
            selectedQuestionsId.value[`select${index}`] = payload.Question;
        }

        // [Get] Security Questions
        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get('api/user/settings')
                .then(response => {
                    securityQuestionsArray.value = Object.entries(response.data.SecurityQuestionsArray).map(([key, value]) => ({
                        key: Number(key),
                        value: String(value)
                    }));
                    userEmail.value = response.data.Email;
                    email.value = response.data.Email;

                    if (response.data.UserSecurityQuestions.length > 0) {
                        response.data.UserSecurityQuestions.forEach((element: SecurityQuestion, index: number) => {
                            selectedQuestionsId.value[`select${index + 1}`] = element.SecurityQuestionID as number;
                        });
                    }

                    const updatedUserQuestions: SelectedQuestion[] = [];
                    userQuestions.value = response.data.UserSecurityQuestions;
                    userQuestions.value.map((question: SecurityQuestion) => {
                        const questionObj: SelectedQuestion = {
                            Answer: "",
                            ExistingQuestion: question.ID,
                            Question: question.SecurityQuestionID as number,
                            IsEditable: false,
                        }

                        updatedUserQuestions.push(questionObj);
                    });

                    selectedQuestions.value = updatedUserQuestions;
                });

            await localStore.fetchGeneralInfo();

            loading.value = false;
        };

        // Submit Form
        const updateSettings = async (settingType: SettingType, formData: Record<string, any>) => {
            resetInputs.value = false;
            loadingUpdate.value[settingType] = true;

            axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
                .then(response => {
                    success.value = response.data.success;

                    fetchData(false);

                    setTimeout(() => {
                        if (settingType === 'password') {
                            password.value = '';
                            passwordConfirmation.value = '';
                        } else if (settingType === 'email') {
                            // emailConfirmation.value = '';
                            newEmail.value = '';
                        }
                    }, 1000);
                })
                .catch((error: any) => {
                    if (error.response) {
                        errors.value = Object.values(error.response.data.errors).flat() as string[];
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        errors.value = [];
                        success.value = '';
                    }, 3500);

                    updateSettingsState.value[settingType] = false;
                    loadingUpdate.value[settingType] = false;
                });
        };

        // Password form submit
        const updatePassword = () => {
            const formData = {
                password: password.value,
                password_confirmation: passwordConfirmation.value,
            };

            updateSettings('password', formData);
        };

        // Email form submit
        const updateEmail = () => {
            const formData = {
                email: email.value,
                newEmail: newEmail.value
                // email_confirmation: emailConfirmation.value,
            };

            updateSettings('email', formData);
            emailReadOnly.value = true;
        };

        // Security Question form submit
        const updateSecurityQuestion = () => {
            const filteredQuestions = Object.values(selectedQuestions.value).filter(question => question.IsEditable);

            const formData = {
                security_questions: filteredQuestions,
            };

            updateSettings('securityQuestion', formData);
        };

        // Set 2 Factor Authentication
        const setTwoFactorAuth = () => {
            const formData = {
                phoneNumber: phoneNumber.value,
            };
        };

        const addNewEmail = () => {
            updateSettingsState.value['newEmail'] = !updateSettingsState.value['newEmail'];
        }

        onMounted(async () => await fetchData(true));

        return {
            errors,
            success,
            loading,
            loadingUpdate,
            localInfo,
            resetInputs,
            discardChanges,
            password,
            currentPassword,
            passwordConfirmation,
            newEmail,
            email,
            emailConfirmation,
            updateEmail,
            addNewEmail,
            userEmail,
            emailReadOnly,
            phoneNumber,
            selectedQuestions,
            toggleUpdateSetting,
            updateSettingsState,
            updateSettings,
            togglePasswordConfirmation,
            updatePassword,
            hasEditable,
            updateSecurityQuestion,
            availableQuestionsForSelect,
            securityQuestionsArray,
            userQuestions,
            numberOfQuestions,
            setTwoFactorAuth,
            showAddNewQuestion,
            addNewQuestion,
            editQuestion,
            removeQuestion,
            handleQuestions,

        };
    },
}
</script>