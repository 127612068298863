<template>
     <div class="d-flex flex-column gap-20 w-100">
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="email" label="Email" placeholder="Email" :max=100
                v-model:modelValue="local.Email" :is-required="true" :error="errors.Email"/>
            <TextInput type="text" name="mixed" label="PIN" placeholder="PIN" :max=6
                v-model:modelValue="local.PIN" :is-required="true" :error="errors.PIN"/>
        </div>
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="text" label="First Name" placeholder="First Name" :max=12
                v-model:modelValue="local.FirstName" :is-required="true" :error="errors.FirstName"/>
            <TextInput type="text" label="Middle Name" placeholder="Middle Name" :max=12
                v-model:modelValue="local.MiddleName" />
            <TextInput type="text" label="Last Name" placeholder="Last Name" :max=25
                v-model:modelValue="local.LastName" :is-required="true" :error="errors.LastName"/>
        </div>
        <TextInput type="text" name="mixed" label="Local # " placeholder="Local #" :max=20
                v-model:modelValue="local.OrgName" :is-required="true" :error="errors.OrgName"/>
        <TextInput type="password" label="Create Password" placeholder="Enter Password" :max=50
            v-model:modelValue="local.Password" :is-required="true" :error="errors.Password"/>
        <TextInput type="password" label="Confirm Password" placeholder="Enter Password" :max=50
            v-model:modelValue="local.Password_confirmation" :is-required="true" :error="errors.Password_confirmation"/>
        <div class="pdt-10">
            <p class="fw-semibold">Security Questions</p>
            <small>Please select a question and enter an answer, which will be used if you ever forget your password.</small>
        </div>

        <SelectComponent label="Security Question" :error="errors['SecurityQuestions.0.Question']" v-model:selectedOption="local.SecurityQuestion1.Question" :options="availableQuestionsForSelect(1).value" :is-required="true" @change="handleQuestionSelect(1)"/>
        <TextInput type="text" name="mixed" label="Answer" placeholder="Enter Answer" :max=50 v-model:modelValue="local.SecurityQuestion1.Answer" :is-required="true" :error="errors['SecurityQuestions.0.Answer']"/>

        <SelectComponent label="Security Question 2" :error="errors['SecurityQuestions.1.Question']" v-model:selectedOption="local.SecurityQuestion2.Question" :options=availableQuestionsForSelect(2).value :is-required="true" @change="handleQuestionSelect(2)"/>
        <TextInput type="text" name="mixed" label="Answer" placeholder="Enter Answer 2" :max=50 v-model:modelValue="local.SecurityQuestion2.Answer" :is-required="true" :error="errors['SecurityQuestions.1.Answer']"/>

        <SelectComponent label="Security Question 3" :error="errors['SecurityQuestions.2.Question']" v-model:selectedOption="local.SecurityQuestion3.Question" :options=availableQuestionsForSelect(3).value :is-required="true" @change="handleQuestionSelect(3)"/>
        <TextInput type="text" name="mixed" label="Answer" placeholder="Enter Answer 3" :max=50 v-model:modelValue="local.SecurityQuestion3.Answer" :is-required="true" :error="errors['SecurityQuestions.2.Answer']"/>
    </div>

    <div class="d-flex mt-40 gap-20 justify-content-center">
        <!-- <button  type="button" class="btn btn-tertiary w-100 lh-1 border-radius-8">Back</button> -->
        <button type="button" class="btn btn-primary w-100 lh-1 border-radius-8" @click="handleRegister" :disabled="loadingSubmit">
            Submit
            <svg v-if="loadingSubmit" width="24" height="16" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                enable-background="new 0 0 0 0" xml:space="preserve">
                <path fill="#8EC986"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                        from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                </path>
            </svg>
        </button>
    </div>
</template>

<script lang="ts">
import { SelectOption, LocalRegister } from '@/interfaces/interfaces';
import { PendingLocal } from '@/interfaces/admin';
import { reactive, PropType, defineComponent, ref, Ref, computed } from 'vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';

export default defineComponent({
    props: {
        securityQuestions: {
            type: Array as PropType<SelectOption[]>,
            required: true
        },
        localRequest: Object as PropType<PendingLocal | null>,
        loadingSubmit: Boolean,
        errors: Array as PropType<string[]>,
    },
    components: {
        TextInput,
        SelectComponent,
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const local: LocalRegister = reactive({
            OrgName: props.localRequest?.OrgName ?? '',
            Email: props.localRequest?.Email ?? '',
            PIN: props.localRequest?.Pin ?? '',
            FirstName: props.localRequest?.FirstName ?? '',
            MiddleName: props.localRequest?.MiddleName ?? '',
            LastName: props.localRequest?.LastName ?? '',
            Password: '',
            Password_confirmation: '',
            SecurityQuestion1: { Question: { key: 0, value: 'Select Question'}, Answer: '' },
            SecurityQuestion2: { Question: { key: 0, value: 'Select Question'}, Answer: '' },
            SecurityQuestion3: { Question: { key: 0, value: 'Select Question'}, Answer: '' },
        });

        const selectedQuestions: Ref<{ [key: string]: number }> = ref({
            1: 0,
            2: 0,
            3: 0,
        });

        const availableQuestionsForSelect = (selectKey: number) => computed(() => {
            console.log('>> key: ', selectedQuestions.value);
            return props.securityQuestions?.filter(q => {
                return !Object.entries(selectedQuestions.value)
                    .filter(([key, value]) => Number(key) !== selectKey && value !== 0)
                    .map(([, value]) => value)
                    .includes(q.key as number);
            });
        })

        // Method to handle dropdown change and update selected questions
        const handleQuestionSelect = (index: number) => {

            const questionKey = `SecurityQuestion${index}` as keyof LocalRegister;
            const securityQuestion = local[questionKey] as { Question: SelectOption, Answer: string };
            selectedQuestions.value[index] = securityQuestion.Question.key as number;
            console.log('>> key: ', index, securityQuestion, selectedQuestions.value);

        };

        const handleRegister = () => {
            const { SecurityQuestion1, SecurityQuestion2, SecurityQuestion3, ...restOfLocal } = local;

            const SecurityQuestions = [
                SecurityQuestion1,
                SecurityQuestion2,
                SecurityQuestion3,
            ].map(question => ({ Question: question.Question.key, Answer: question.Answer }));

            emit('register', { ...restOfLocal, SecurityQuestions });
        }

        return {
            local,
            handleRegister,
            availableQuestionsForSelect,
            handleQuestionSelect,
        };
    },
});
</script>