<template>
    <div class="pdt-30">
        <div class="d-flex justify-content-between align-items-center pdb-20">
            <h2>Autoresponders</h2>
            <button class="btn btn-primary plan-modal"
                @click="openModal('add-autoresponder', 'Add Autoresponder', 'plan-upload  plan-card-info')"> Create new
                Autoresponder
            </button>
        </div>

        <div class="bordered-card bordered-card_secondary">
            <table class="small striped toggle no-underline">
                <thead class="large">
                    <tr>
                        <th colspan="6">
                            <div class="pdl-10 pdr-10 d-flex justify-content-between w-100">
                                <div class="col-md-1"> Title </div>
                                <div class="col-md-2"> Form </div>
                                <div class="col-md-1"> Action </div>
                                <div class="col-md-2"> Result </div>
                                <div class="col-md-1"> Actions </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <LoaderComponent v-if="loading" />
                <tbody v-else-if="autoresponders.length > 0">
                    <template v-for="autoresponder in autoresponders" :key="autoresponder.UIAutoresponderID">
                        <tr class="large">
                            <td colspan="6">
                                <div class="d-flex flex-column w-100">
                                    <div class="d-flex justify-content-between w-100">
                                        <div class="cell col-md-1">{{ autoresponder.Description }}</div>
                                        <div class="cell col-md-1">{{ autoresponder.Form }}</div>
                                        <div class="cell col-md-2">{{ autoresponder.Action }}</div>
                                        <div class="cell col-md-2">{{ autoresponder.Result }}</div>

                                        <!-- Edit button -->
                                        <div class="cell col-md-1">
                                            <i class="plan-modal iatse-icon-edit fs-6 fw-semibold"
                                                @click="handleEdit(autoresponder.UIAutoresponderID)">
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="text-center py-5">
                        <td colspan="6"> No Existing Autoresponders</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import { onMounted, ref, Ref } from 'vue';
import { Autoresponder } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import { useSuperadminStore } from '@/stores/superadmin';
import { useRoute } from 'vue-router';

export default {
    setup() {
        const autoresponders: Ref<Autoresponder[] | []> = ref([]);
        const loading = ref(true);
        const success = ref(useRoute().query.success || null);
        const openModal = useModalStore().openModal;

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/administrator/autoresponders')
                .then(response => {
                    autoresponders.value = response.data.Autoresponders;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false)
        };

        const handleEdit = (autoresponderID: number) => {
            useSuperadminStore().autoresponder = autoresponderID;
            openModal('add-autoresponder', 'Edit Autoresponder', 'plan-upload  plan-card-info');
        }

        onMounted(async () => {
            await fetchData();

            if (success.value) {
                setTimeout(() => success.value = null, 3000)
            }
        });

        return { autoresponders, loading, success, openModal, handleEdit }
    }
}
</script>