import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 4,
  class: "text-center w-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.isPdf)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              src: _ctx.documentUrl,
              width: "100%",
              height: "1000",
              id: "smartsearch"
            }, null, 8, _hoisted_1))
          : (_ctx.isDocx)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "text-center w-100",
                href: _ctx.documentUrl,
                download: ""
              }, "Download Word Document", 8, _hoisted_2))
            : (_ctx.isExcel)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  class: "text-center w-100",
                  href: _ctx.documentUrl,
                  download: ""
                }, "Download Excel Document", 8, _hoisted_3))
              : (_ctx.isImage)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 3,
                    src: _ctx.documentUrl,
                    alt: "Image",
                    width: "100%"
                  }, null, 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("p", _hoisted_5, "Couldn't load the document."))
      ], 64))
}