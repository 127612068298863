<template>
    <div class="plan-content">
        <div class="container-fluid">
            <form @submit.prevent="payCapp">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Card Type</label>
                                    <input type="text" id="" name="text-group" placeholder="" maxlength="" class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-20">
                            <div class="col-12">
                                <div class="text-input">
                                    <label for="" class="body-text">Cardholder's Name</label>
                                    <input type="text" id="" name="text-group" placeholder="" maxlength=""   class="links">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-20">
                            <div class="col-12">
                                <CardNumberField v-model="cardNumber" />
                            </div>
                        </div>
                        <div class="row mt-20">
                            <div class="col-6">
                                <CardExpirationField v-model="cardExpiration" />
                            </div>
                            <div class="col-6">
                                <div class="text-input">
                                    <label for="" class="body-text">CVV</label>
                                    <input type="text" id="" name="text-group" placeholder="Add CVV" maxlength="" v-model="cvv"  class="links">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="capp-summary">
                        <div class="capp-summary__option">
                                <small>Selected Coverage</small>
                                <h4>{{ selectedInvoice?.Title || 'No coverage selected' }}</h4>
                        </div>
                        <div class="capp-summary__content">
                                <div class="amount-owed">
                                    <p>Amount owed</p>
                                    <h4>{{ formatCurrency(selectedInvoice?.AmountOwed) || '$0.00' }}</h4>
                                </div>
                        </div>
                        <div class="capp-summary__action mt-10">
                             <a href="#" class="btn btn-primary w-100" @click.prevent="openModal('hw-capp-statement', 'Invoices', 'plan-upload plan-card-info plan-c-mrp-claims')">Change Coverage</a>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-12">
                        <div class="d-flex w-100 justify-content-between">
                            <button class="btn btn-tertiary">Cancel</button>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCoverageStore } from '@/stores/coverage'; // Import the coverage store
import CardNumberField from '@/components/form/CardNumberField.vue';
import CardExpirationField from '@/components/form/CardExpirationField.vue';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { useCappInvoicesStore } from '@/stores/cappInvoice';

export default {
    components: {
        CardNumberField,
        CardExpirationField
    },
    setup() {
        const openModal = useModalStore().openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const cappInvoicesStore = useCappInvoicesStore();
        const selectedInvoice = cappInvoicesStore.selectedInvoice;
        const coverageStore = useCoverageStore(); // Get a reference to the coverage store

        // Reactive variables for form inputs
        const postalCode = ref('');
        const cardType = ref('');
        const cardholderFirstName = ref('');
        const cardholderLastName = ref('');
        const cardNumber = ref('');
        const cardExpiration = ref('');
        const cvv = ref('');
        const error = ref('');

        const payCapp = async () => {
            try {
                // Prepare POST data
                const data = {
                    option: selectedInvoice?.OptionKey ?? null,
                    postal_code: postalCode.value,
                    card_type: cardType.value,
                    cardholder_first_name: cardholderFirstName.value,
                    cardholder_last_name: cardholderLastName.value,
                    card_number: cardNumber.value,
                    card_expiration: cardExpiration.value,
                    card_cvv: cvv.value,
                };

                // Make the POST request
                const response = await axios.post('/api/checkout/capp', data);

                // Check if the response status indicates success (2xx)
                if (response.status >= 200 && response.status < 300) {
                    const { success, transaction_id, error_code, error_message } = response.data.data;
                    if (success) {
                        // Payment was successful
                        openModal('payment-status', 'x', 'modal-success', null, null, undefined, undefined, {
                            success: true,
                            transactionId: transaction_id,
                            message: 'Payment Successful!',
                            type: 'capp'
                        });

                        // Refetch health data after payment success
                        await coverageStore.fetchHealthData();
                    } else {
                        // Payment was unsuccessful
                        openModal('payment-status', 'x', 'modal-success', null, null, undefined, undefined, {
                            success: false,
                            errorCode: error_code,
                            message: error_message || 'Payment was unsuccessful.',
                            type: 'capp'
                        });
                    }
                } else {
                    // Handle unexpected HTTP status codes
                    openModal('payment-status', 'x', 'modal-success', null, null, undefined, undefined, {
                        success: false,
                        transactionId: null,
                        message: 'An unexpected error occurred. Please try again later.',
                        type: 'capp'
                    });
                }
            } catch (err) {
                let errorMessage = 'An error occurred during payment processing.';
                if (axios.isAxiosError(err)) {
                    if (err.response) {
                        const { status, data } = err.response;
                        console.error(`Error response status: ${status}`, data);
                        errorMessage = data?.error_message || data?.message || errorMessage;
                    } else if (err.request) {
                        console.error('No response received:', err.request);
                        errorMessage = 'No response from the server. Please check your network connection.';
                    } else {
                        console.error('Error', err.message);
                        errorMessage = err.message;
                    }
                } else {
                    console.error('Unexpected error:', err);
                }

                openModal('payment-status', 'x', 'modal-success', null, null, undefined, undefined, {
                    success: false,
                    message: errorMessage,
                    type: 'capp'
                });
            }
        };

        return {
            openModal,
            formatCurrency,
            selectedInvoice,
            cardType,
            cardNumber,
            cardExpiration,
            cvv,
            payCapp
        };
    }
};
</script>