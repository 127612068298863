<template>
    <div class="form form-columns_4 gap-20 w-100 flex-column flex-md-row">
        <!-- First row -->
        <div class="item rounded bg-light-green pdl-20 pdr-20 pdt-15 pdb-15 cursor-pointer">
            <CheckBox label="Work Period" className="alert-checkbox light" id="WorkPeriod"
                v-model:modelValue="localFields.WorkPeriod" @change="emitUpdate" :showLabel="true" />
        </div>
        <div class="item rounded bg-light-green pdl-20 pdr-20 pdt-15 pdb-15 cursor-pointer">
            <CheckBox label="Member's Name" className="alert-checkbox light" id="Name"
                v-model:modelValue="localFields.Name" @change="emitUpdate" :showLabel="true" />
        </div>
        <div class="item rounded bg-light-green pdl-20 pdr-20 pdt-15 pdb-15 cursor-pointer">
            <CheckBox label="Member's NBF ID" className="alert-checkbox light" id="PersonID"
                v-model:modelValue="localFields.PersonID" @change="emitUpdate" :showLabel="true" />
        </div>
        <div class="item rounded bg-light-green pdl-20 pdr-20 pdt-15 pdb-15 cursor-pointer">
            <CheckBox label="Employer" className="alert-checkbox light" id="Employer"
                v-model:modelValue="localFields.Employer" @change="emitUpdate" :showLabel="true" />
        </div>
    </div>
    <div class="form form-columns_4 gap-20 w-100 flex-column flex-md-row">
        <!-- Second row -->
        <div class="item rounded bg-light-green pdl-20 pdr-20 pdt-15 pdb-15 cursor-pointer">
            <CheckBox label="Production" className="alert-checkbox light" id="Production"
                v-model:modelValue="localFields.Production" @change="emitUpdate" :showLabel="true" />
        </div>
        <div class="item rounded bg-light-green pdl-20 pdr-20 pdt-15 pdb-15 cursor-pointer">
            <CheckBox label="Payor" className="alert-checkbox light" id="Payor" v-model:modelValue="localFields.Payor"
                @change="emitUpdate" :showLabel="true" />
        </div>
        <div class="item rounded bg-light-green pdl-20 pdr-20 pdt-15 pdb-15 cursor-pointer">
            <CheckBox label="Receipt Dates" className="alert-checkbox light" id="ReceiptDate"
                v-model:modelValue="localFields.ReceiptDate" @change="emitUpdate" :showLabel="true" />
        </div>
        <div></div>
    </div>
</template>

<script lang="ts">
import { reactive, defineComponent, watch, PropType } from 'vue';
import { LocalReportSelectedFields } from '@/interfaces/local';
import CheckBox from '@/components/form/CheckBox.vue';


export default defineComponent({
    props: {
        fields: {
            type: Object as PropType<LocalReportSelectedFields>,
            required: true,
        },
    },

    components: {
        CheckBox
    },

    setup(props, { emit }) {
        const localFields = reactive({ ...props.fields });

        watch(() => props.fields, (newFields) => {
            Object.assign(localFields, newFields);
        }, {deep: true});

        const emitUpdate = () => {
            emit('update-fields', localFields);
        };

        return {
            localFields,
            emitUpdate
        };
    }
})
</script>