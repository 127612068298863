import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "plan-content"
}
const _hoisted_2 = {
  key: 0,
  class: "alerts-wrapper pdr-10"
}
const _hoisted_3 = {
  key: 0,
  class: "alerts-header"
}
const _hoisted_4 = { class: "text-500-bold" }
const _hoisted_5 = {
  key: 0,
  class: "alerts-header-alert-checkbox"
}
const _hoisted_6 = { class: "alerts-cards" }
const _hoisted_7 = { class: "bordered-card-header" }
const _hoisted_8 = { class: "fw-semibold" }
const _hoisted_9 = { class: "text-grey fs-xs" }
const _hoisted_10 = { class: "bordered-card-body" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "alerts-wrapper"
}
const _hoisted_13 = { class: "text-center w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_AlertsComponent = _resolveComponent("AlertsComponent", true)!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_CheckBox = _resolveComponent("CheckBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.max)
      ? (_openBlock(), _createBlock(_component_MessageCard, {
          key: 0,
          message: "Please read the messages below. When you are done, check the box next to the\n        message and it will be archived. <br />\n        This screen also contains a repository of messages you've previously viewed which are stored here for historic reference.",
          type: "danger",
          iconClass: "iatse-icon-alarm",
          classes: "info_card_full"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_AlertsComponent, { success: _ctx.success }, null, 8, ["success"]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 1 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.alerts.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alertItem, key) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                    (!_ctx.max)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("p", _hoisted_4, _toDisplayString(alertItem.key), 1),
                          (key === 0 && _ctx.hasUnreadAlerts)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_CheckBox, {
                                  label: "Mark All As Read",
                                  className: "alert-checkbox",
                                  id: "all",
                                  onChange: _ctx.readAll,
                                  showLabel: true,
                                  left: true
                                }, null, 8, ["onChange"])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(alertItem.value, (alert) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["bordered-card", {'active': alert.AlertRead == 0}]),
                          key: alert.AlertID
                        }, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("h5", _hoisted_8, _toDisplayString(alert.type?.Name), 1),
                            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.useTimeFormatter(alert.DateCreated)), 1)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("p", {
                              innerHTML: alert.Message
                            }, null, 8, _hoisted_11),
                            (Number(alert.AlertRead) == 0 && !_ctx.max)
                              ? (_openBlock(), _createBlock(_component_CheckBox, {
                                  key: 0,
                                  label: "Mark as Read",
                                  className: "alert-checkbox",
                                  id: alert.AlertID,
                                  modelValue: alert.AlertRead,
                                  "onUpdate:modelValue": ($event: any) => ((alert.AlertRead) = $event),
                                  showLabel: false,
                                  onChange: ($event: any) => (_ctx.markRead(alert.AlertID, alert.AlertRead))
                                }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "onChange"]))
                              : _createCommentVNode("", true)
                          ])
                        ], 2))
                      }), 128))
                    ])
                  ], 64))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.max ? 'No unread alerts' : 'No alerts'), 1)
              ]))
        ]))
  ], 64))
}