<template>
    <AlertsComponent :errors="errors" :success="success" />
    <LoaderComponent v-if="loading" />
    <div class="plan-content" v-else>
        <div class="plan-details">
            <ProfileInfo :items="items" :multiple="true" classes="plan-general-superadmin" />
        </div>

        <!-- Change Email -->
        <div id="email-change-form" class="form account-settings pdt-25 gap-30 w-50">
            <h4 class="w-100"> Change Email </h4>
            <div class="w-full d-flex gap-3 align-items-end">
                <TextInput type="email" v-model:modelValue="userEmail" label="Current Email Address"
                    :placeholder="userEmail" :readonly="true" />
                <button type="button" v-if="!updateSettingsState.email" class="btn btn-primary lh-1 h-fit"
                    @click="toggleUpdateSetting('email')">
                    Update
                </button>
            </div>
        </div>

        <div v-if="updateSettingsState.email" class="w-full d-flex flex-column">
            <div class="w-full d-flex gap-3 align-items-end">
                <TextInput type="email" v-model:modelValue="newEmail" label="New Email Address"
                    placeholder="Enter New Email Address" />
                <TextInput type="email" v-model:modelValue="newEmailConfirmation" label="Confirm New Email Address"
                    placeholder="Confirm New Email Address" />
            </div>
            <div class="w-full d-flex gap-3 align-items-end pdt-20">
                <button type="button" class="btn btn-primary lh-1 h-fit" @click="updateEmail"> Update </button>
                <button type="button" class="btn btn-secondary lh-1 h-fit" @click="toggleUpdateSetting('email')">
                    Discard </button>
            </div>
        </div>

        <div class="divider divider_green mt-40 mb-40"> </div>

        <!-- Forget Password -->
        <form id="email-change-form" class="form account-settings gap-30" @submit.prevent="updateEmail">
            <h4 class="w-100"> Forget Password </h4>
            <div class="w-full d-flex gap-3">
                <TextInput type="password" v-model:modelValue="password" label="Change Password" placeholder="....." />
                <TextInput type="password" v-model:modelValue="passwordConfirmation" label="Re-Enter New Password"
                    placeholder="....." />
            </div>
            <button type="button" class="btn btn-primary lh-1 h-fit" @click="updatePassword">
                Update Password
            </button>
        </form>

        <div class="divider divider_green mt-40 mb-40"> </div>

        <!-- Two-Factor Authentication  -->
        <form id="email-change-form" class="form account-settings gap-30" @submit.prevent="updateEmail">
            <h4 class="w-100"> Two-Factor Authentication (Required) </h4>
        </form>
    </div>

    <!-- Actions  -->
    <div class="action-buttons d-flex justify-content-between w-100">
        <a href="" @click.prevent="impersonate" class="btn btn-secondary">Masquerade as this user</a>
        <button type="button" class="btn btn-primary" @click="handleSave"> Save </button>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import { ParticipantResponse, EmployerResponse, LocalResponse } from '@/interfaces/admin';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';

import ProfileInfo from '@components/cards/ProfileInfo.vue';
import TextInput from '@components/form/TextInput.vue';

import axios from "axios";

type SettingsState = {
    password: boolean;
    email: boolean;
    twoFactorAuth: boolean;
};

type SettingType = keyof SettingsState;
type UserResponse = ParticipantResponse | EmployerResponse | LocalResponse;

export default defineComponent({
    props: {
        modalProps: {
            type: Object,
            required: true
        }
    },
    components: {
        ProfileInfo,
        TextInput
    },
    setup(props, { emit }) {
        const authStore = useAuthStore();
        const router = useRouter();
        const loading = ref(true);
        const password = ref('');
        const passwordConfirmation = ref('');
        const data = ref<UserResponse | null>(null);
        const newEmail = ref('');
        const newEmailConfirmation = ref('');
        const userEmail = computed(() => data.value?.Email);
        const role = computed(() => data.value?.Role);
        const resetInputs = ref(false);
        const errors = ref<string[]>([]);
        const success = ref('');
        const updateSettingsState = ref<SettingsState>({
            password: false,
            email: false,
            twoFactorAuth: false,
        });

        const loadingUpdate = ref<SettingsState>({
            password: false,
            email: false,
            twoFactorAuth: false,
        });

        const items = computed(() => {
            const dataFields = data.value?.Data;

            if (!dataFields) {
                return [];
            }

            // Convert each field in Data object to { label, value, itemClass } format
            const fieldItems = Object.keys(dataFields).map(key => ({
                label: key,
                value: (dataFields[key as keyof typeof dataFields] ?? '--'),
                itemClass: ''
            }));

            // Split into arrays of 5 or 6
            return fieldItems.reduce((result, item, index) => {
                const number = role.value === 'Local' ? 6 : 5;
                const chunkIndex = Math.floor(index / number);

                if (!result[chunkIndex]) {
                    result[chunkIndex] = [];
                }

                result[chunkIndex].push(item);
                return result;
            }, [] as { label: string; value: string; itemClass: string }[][])
        });


        const fetchData = async (isLoading: boolean) => {
            if (isLoading) {
                loading.value = true
            }

            if (props.modalProps.UserID) {
                await axios
                    .get(`api/administrator/user/${props.modalProps.UserID}`)
                    .then((response) => {
                        data.value = response.data;
                    })
                    .catch((error) => {
                        console.error("There was an error!", error);
                    });
            }

            loading.value = false;
        };

        // Submit Form
        const updateSettings = async (settingType: SettingType, formData: Record<string, any>) => {
            resetInputs.value = false;
            loadingUpdate.value[settingType] = true;

            axios.post(`api/user/settings/${props.modalProps.UserID}`, { ...formData, _method: 'PATCH' })
                .then(response => {
                    success.value = response.data.success;
                    updateSettingsState.value[settingType] = false;
                    fetchData(false);
                    setTimeout(() => {
                        if (settingType === 'password') {
                            password.value = '';
                            passwordConfirmation.value = '';
                        } else if (settingType === 'email') {
                            newEmail.value = '';
                            newEmailConfirmation.value = '';
                        }
                    }, 1000);
                })
                .catch((error: any) => {
                    if (error.response) {
                        errors.value = Object.values(error.response.data.errors).flat() as string[];
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        errors.value = [];
                        success.value = '';
                    }, 3500);

                    loadingUpdate.value[settingType] = false;
                });
        };

        // Toggle the visibility of editable fields
        const toggleUpdateSetting = (settingType: SettingType) => {
            updateSettingsState.value[settingType] = !updateSettingsState.value[settingType];
        };

        // Email form submit
        const updateEmail = () => {
            const formData = {
                email: newEmail.value,
                email_confirmation: newEmailConfirmation.value,
            };

            updateSettings('email', formData);
        };

        // Password form submit
        const updatePassword = () => {
            const formData = {
                password: password.value,
                password_confirmation: passwordConfirmation.value,
            };

            updateSettings('password', formData);
        };

        const impersonate = async () => {
            try {
                await authStore.startImpersonation(props.modalProps.UserID);
                emit('close');

                if (role.value === 'Employer') {
                    router.push({ name: 'employer.dashboard' });
                } else if (role.value === 'Local') {
                    router.push({ name: 'local.dashboard' });
                } else {
                    router.push({ name: 'participant.dashboard' });
                }
            } catch (error) {
                console.error("Impersonation error:", error);
            }
        }

        const handleSave = () => {
            emit('close')
        }

        onMounted(async () => await fetchData(true));

        return {
            errors,
            success,
            loading,
            items,
            userEmail,
            newEmail,
            newEmailConfirmation,
            password,
            passwordConfirmation,
            updateSettingsState,
            updateEmail,
            updatePassword,
            toggleUpdateSetting,
            impersonate,
            handleSave
        };
    }
});
</script>