<template>
    <Alerts :success="success" :errors="errors" />
    <div class="pdt-30">
        <div class="d-flex justify-content-between align-items-center pdb-20">
            <h2 class="">Claim Submissions</h2>
            <button class="btn btn-primary" @click="downloadCSV">
                {{ loadingExport ? 'Exporting...' : 'Export To CSV' }}
            </button>
        </div>
        <div v-show="success" class="alert alert-success">{{ success }}</div>
        <form class="bordered-card bordered-card_secondary" @submit.prevent>
            <div class="filters d-flex gap-4 flex-wrap flex-md-nowrap justify-content-between w-100">
                <div class="d-flex gap-4 col-md-6 col-sm-12">
                    <SelectComponent v-model:selectedOption="filters.Relationship" :options="relationships"
                        :single-arrow="true" />
                    <SelectComponent v-model:selectedOption="filters.ClaimStatus" :options="statuses"
                        :single-arrow="true" />
                </div>
                <TextInput type="text" placeholder="Search by Id, and Name..." :max=30
                    v-model:modelValue="filters.Participant" customClass="search" />
            </div>
            <table class="small striped toggle no-underline min-height">
                <thead class="large">
                    <tr>
                        <th>
                            <div class="pdl-10 pdr-10 d-flex justify-content-between w-100 table-header">
                                <div class="col-md-1">Claim #</div>
                                <div class="col-md-1">Web Ref #</div>
                                <div class="col-md-1">Rcpt Date</div>
                                <div class="col-md-1">Participant <br /> ID</div>
                                <div class="col-md-1">Participant <br /> Name</div>
                                <div class="col-md-1">Patient <br /> Name</div>
                                <div class="col-md-1">DOS</div>
                                <div class="col-md-1">Relationship</div>
                                <div class="col-md-2">Notes</div>
                                <div class="col-md-1">Status</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <!-- <LoaderComponent v-if="true" /> -->
                <LoaderComponent v-if="loading || loadingSearch" />
                <tbody v-else-if="claimsHistory?.length">
                    <template v-for="submission in  claimsHistory  " :key="submission.ClaimID">
                        <tr :class="['large', { 'closed': submission.Status === 'Closed' }]">
                            <td>
                                <div class="d-flex flex-column w-100 entry-row">
                                    <!-- Row for main entry data -->
                                    <div
                                        class="d-flex justify-content-between flex-wrap flex-md-nowrap w-100 sm-gap-20">
                                        <div
                                            class="cell col-md-1 col-sm-5 d-flex justify-content-between align-items-center">
                                            {{ submission.ClaimID }}
                                        </div>
                                        <div
                                            class="cell col-md-1 col-sm-5 d-flex justify-content-between align-items-center">
                                            {{ submission.NEICID }}
                                        </div>
                                        <div
                                            class="cell col-md-1 col-sm-5 d-flex justify-content-between align-items-center">
                                            {{ submission.DateReceived }}
                                        </div>
                                        <div
                                            class="cell col-md-1 col-sm-5 d-flex justify-content-between align-items-center">
                                            {{ submission.PersonID }}
                                        </div>
                                        <div
                                            class="cell col-md-1 col-sm-5 text-wrap  d-flex justify-content-between align-items-center">
                                            <span class="w-50">{{ submission.ParticipantName }}</span>
                                        </div>
                                        <div
                                            class="cell col-md-1 col-sm-5 text-wrap  d-flex justify-content-between align-items-center">
                                            <span class="w-50">{{ submission.PatientName }}</span>
                                        </div>
                                        <div
                                            class="cell col-md-1 col-sm-5 text-wrap  d-flex justify-content-between align-items-center">
                                            <div class="d-flex flex-column w-fit">
                                                <span class="text-start">{{ submission.From_DOS }}</span>
                                                <span class="text-center"> - </span>
                                                <span class="text-start">{{ submission.To_DOS }}</span>
                                            </div>
                                        </div>
                                        <div
                                            class="cell col-md-1 col-sm-5 d-flex justify-content-between align-items-center">
                                            {{ submission.Relationship }}
                                        </div>
                                        <div @click="toggleNotes(submission.ClaimID ?? submission.NEICID)"
                                            class="cell col-md-2 col-sm-5 sm-pdl-0 pdr-25 d-flex justify-content-between align-items-center plan-modal">
                                            <span>{{ getTruncatedNotes(submission?.Notes) }}</span>
                                            <template v-if="submission?.Notes?.length > 25">
                                                <i class="plan-modal iatse-icon-minus pdl-10"
                                                    v-if="isExpanded(submission.ClaimID ?? submission.NEICID)"></i>
                                                <i class="plan-modal iatse-icon-table-plus pdl-10"
                                                    v-if="!isExpanded(submission.ClaimID ?? submission.NEICID)"></i>
                                            </template>
                                        </div>
                                        <div class="cell col-md-1 col-sm-5 d-flex justify-content-between align-items-center">
                                            <small class="label label_small w-fit" :class="{
        'label_danger_secondary': ['Denied', 'Rejected'].includes(submission.ClaimStatus),
        'label_green_secondary': ['Received', 'Processed'].includes(submission.ClaimStatus),
        'label_blue': submission.ClaimStatus === 'In-Process'
    }
        "> <span class="bullet"></span>
                                                {{ submission.ClaimStatus }}
                                            </small>
                                        </div>
                                    </div>

                                    <!-- Expandable notes section for long text -->
                                    <transition name="slide-fade">
                                        <div v-if="isExpanded(submission.ClaimID) && submission?.Notes?.length > 30"
                                            class="text-center pdt-20 pdb-10">
                                            <p>{{ submission?.Notes }}</p>
                                        </div>
                                    </transition>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="text-center py-5">
                        <td colspan="8"> No submissions found </td>
                    </tr>
                </tbody>
            </table>
            <PaginationComponent v-if="!loading && !loadingSearch" :paginator="paginator"
                @fetch-data="fetchPaginationData" />
        </form>
    </div>
</template>

<script lang="ts">
import { onMounted, reactive, ref, watch, Ref } from 'vue';
import { ClaimHistory } from '@interfaces/health';
import { Paginator } from '@/interfaces/admin';
import { SelectOption } from '@/interfaces/interfaces';
import { saveAs } from 'file-saver';
import { useOptionsFormatter } from "@utils/optionFormatter";

import PaginationComponent from '@components/utils/PaginationComponent.vue';
import Alerts from "@/components/utils/AlertsComponent.vue";
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Patient {
    ID: string;
    FullName: string;
}

interface Filters {
    ReceiptDate: {
        StartDate?: string,
        EndDate?: string,
    };
    PersonID: number | null;
    ClaimStatus: SelectOption;
    ClaimType: string | null;
    Relationship: SelectOption | null;
}

export default {
    components: {
        Alerts,
        SelectComponent,
        TextInput,
        PaginationComponent
    },

    setup() {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const loadingExport = ref(false);
        const changePage = ref(false);
        const success = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const statuses = ref<SelectOption[]>([]);
        const relationships = ref<SelectOption[]>([]);
        const claimsHistory: Ref<ClaimHistory[]> = ref([]);
        const initialFilters = {
            Participant: null,
            ClaimStatus: statuses.value[0],
            Relationship: 0,
        };
        const page = ref(1);
        const filters: Filters = reactive({ ...JSON.parse(JSON.stringify(initialFilters)) });
        const paginator: Paginator = reactive({ per_page: 15, total: 300, last_page: 300, current_page: 1 });

        const resetFilters = () => {
            claimsHistory.value = [];
            changePage.value = false;
            Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
        }

        watch(filters, async () => {
            page.value = 1;
            loadingSearch.value = true;
            changePage.value = true;
            await searchData();
            loadingSearch.value = false;
        });

        const expandedRows = ref<{ [key: number]: boolean }>({});

        const toggleNotes = (index: number) => {
            expandedRows.value[index] = !expandedRows.value[index];
        };

        const isExpanded = (index: number) => {
            return !!expandedRows.value[index];
        };

        const getTruncatedNotes = (notes: string) => {
            const maxLength = 25;
            return notes?.length > maxLength ? notes?.substring(0, maxLength) + '...' : notes;
        };

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/claims-search')
                .then(response => {
                    statuses.value = useOptionsFormatter(response.data.Statuses);
                    relationships.value = useOptionsFormatter(response.data.Relationships);
                    filters.ClaimStatus = statuses.value[0];
                    filters.Relationship = relationships.value[0];
                })

            loading.value = false;
        };

        // Get Claims History
        const searchData = async () => {
            loadingSearch.value = true;
            claimsHistory.value = [];

            await axios.post('api/administrator/claim-submissions', {
                ...filters, ClaimStatus: filters.ClaimStatus.value, Relationship: filters.Relationship?.key, Page: page.value
            })
                .then(response => {
                    claimsHistory.value = response.data.Claims;
                    Object.assign(paginator, response.data.Paginator);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;
                    changePage.value = false;

                    setTimeout(() => {
                        success.value = null
                        errors.value = []
                    }, 3500);
                })
        }

        const fetchPaginationData = async (pageNumber: number) => {
            page.value = pageNumber;
            changePage.value = true;
            await searchData();
        }

        const downloadCSV = async () => {
            loadingExport.value = true;

            await handleDownload();

            loadingExport.value = false;
        }

        // Handle Download CSV File
        const handleDownload = async () => {
            try {
                const response = await axios.post('api/administrator/claim-submissions', {
                    ...filters, ClaimStatus: filters.ClaimStatus.value, Relationship: filters.Relationship?.key,
                    Page: page.value,
                    Export: true,
                }, {
                    responseType: 'blob',
                });

                const blob = new Blob([response.data], {
                    type: response.headers['content-type'],
                });

                saveAs(blob, 'claims.csv');
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        };

        onMounted(async () => await fetchData());

        return {
            loading,
            loadingSearch,
            loadingExport,
            success,
            errors,
            filters,
            statuses,
            relationships,
            claimsHistory,
            paginator,
            changePage,
            fetchPaginationData,
            getTruncatedNotes,
            toggleNotes,
            isExpanded,
            resetFilters,
            searchData,
            downloadCSV
        };
    }
}
</script>
