<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                The screen displays a record of payments you have made towards Plan C coverage
            </div>
        </div>
    </div>
    <!-- Contribution Tables -->
    <div class="contributions-tables cobra-payments">
        <div class="contributions-table-wrapper">
            <div class="contributions-table-item no-striped">
                <h4>{{ title }}</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Statement Date</th>
                            <th>Coverage Period</th>
                            <th>Receipt Date</th>
                            <th>Payment Ref</th>
                            <th>Check / Payment Amount</th>
                            <th>Source</th>
                            <th>View Statement</th>
                        </tr>
                    </thead>
                    <tbody v-if="dataLoaded && cappDocuments.length > 0">
                        <tr v-for="document in cappDocuments" :key="document.DocumentID">
                            <td>{{ document.StatementDate }}</td>
                            <td>{{ document.CoveragePeriod }}</td>
                            <td>{{ document.ReceiptDate }}</td>
                            <td>{{ '-' }}</td>
                            <td>{{ '-' }}</td>
                            <td>{{ '-' }}</td>
                            <td><a href="javascript;"
                                    @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document.DocumentID })">
                                    <img src="@assets/images/icons/pdf_primary.svg" alt="pdf" />
                                </a></td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7">No statements found.</td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex w-100 justify-content-center justify-content-md-end mt-3">

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { CappDocument } from '@/interfaces/health';
import { useCoverageStore } from '@/stores/coverage';

export default {
    setup() {
        const dataLoaded = ref(false);
        const modalStore = useModalStore();
        const openModal = modalStore.openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const cappDocuments = ref<CappDocument[]>([]);

        const fetchData = () => {
          // Fetch data from the API, sending a flag if periods are already fetched
            axios
                .get('/api/participant/health-welfare/capp-payments-history')
                .then((response) => {
                    // If periods have not been fetched yet, store them

                    // Store other plan summary details
                    cappDocuments.value = response.data.CappDocuments;
                    dataLoaded.value = true;
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        onMounted(() => {
            fetchData();
        });

        return {
            dataLoaded,
            cappDocuments,
            openModal
        };
    }
}
</script>