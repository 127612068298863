<template>
    <table class="small">
        <thead class="large large_padding">
            <tr>
                <th>Document</th>
                <th>Web Submission Date</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody v-if="documents.length > 0">
            <tr class="large" v-for="document in documents" :key="document.DocumentID">
                <td>
                    <a href="javascript;"
                        @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document.DocumentID })">
                        {{ document.Type }}
                    </a>
                </td>
                <td>{{ new Date(document.Date).toLocaleDateString() }}</td>
                <td>{{ document.Status }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { Document } from '@/interfaces/interfaces';
import { PropType } from 'vue';
import { useModalStore } from '@/stores/modal';

export default {
    props: {
        documents: {
            type: Array as PropType<Document[] | []>,
            required: true
        }
    },
    setup() {
        return {
            openModal: useModalStore().openModal
        }
    }
}
</script>