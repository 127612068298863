<template>
    <div class="bordered-card bordered-card_secondary bg-light-green">
        <div class="d-flex w-100 justify-content-between align-items-md-center flex-md-row flex-column gap-20 align-items-start">
            <p class="fw-semibold sm-fs-xs">Add New Sort By Column</p>
            <div class="d-flex gap-20 align-items-center justify-content-md-end w-50 w-sm-100 justify-content-start">
                <SelectComponent :options=availableColumns :singleArrow="true" v-model:selectedOption="selectedColumn" customClass="w-75" />
                <button class="btn btn-secondary lh-1" @click="addColumn">Add</button>
            </div>
        </div>
        <table cellpadding="20" class="sorting-fields">
            <tr v-for="(column, index) in localColumns" :key="index">
                <th class="fw-semibold py-2 sm-fs-xs">{{ column.value }}</th>
                <td class="px-2 py-2 col-md-3">
                    <RadioButton :options="column.sortOptions" :name="column.key" v-model="localSorting[column.key]"
                        :id="column.key" @change="emitUpdate" customClass="primary equal-widths small" />
                </td>
            </tr>
        </table>
    </div>
    <div class="d-flex gap-20 align-items-end w-100">
        <TextInput v-model="customTemplate.value" placeholder="Enter Template Name" label="Save Steps Selected as Template" />
        <button class="btn btn-secondary lh-1" @click="emitSaveTemplate" :disabled="loadingSubmit">Save</button>
    </div>
</template>

<script lang="ts">
import { reactive, defineComponent, watch, PropType, ref } from 'vue';
import { SelectOption } from '@/interfaces/interfaces';
import { LocalReportSortedFields, LocalReportColumn } from '@/interfaces/local';
import SelectComponent from '@components/form/SelectComponent.vue';
import TextInput from '@components/form/TextInput.vue';

export default defineComponent({
    props: {
        loadingSubmit: Boolean,
        sorting: {
            type: Object as PropType<LocalReportSortedFields>,
            required: true,
        },
        columns: {
            type: Array as PropType<LocalReportColumn[]>,
            required: true,
        },
        template: {
            type:  Object as PropType<SelectOption>,
            default: () => ({
                key: 0,
                value: ''
            })
        },
    },

    components: {
        SelectComponent,
        TextInput
    },

    setup(props, { emit }) {
        const localSorting = reactive({ ...props.sorting });
        const localColumns = reactive(props.columns.filter((column: LocalReportColumn) => column.show === true));
        const availableColumns = ref(props.columns.filter((column: LocalReportColumn) => column.show === false));
        const selectedColumn = ref<SelectOption | null>(null);
        const customTemplate = ref<SelectOption | null>({...props.template});

        watch(() => props.sorting, (newSorting) => {
            Object.assign(localSorting, newSorting);
        }, {deep: true});

        watch(() => props.template, (newTemplate) => {
            customTemplate.value = newTemplate;
        }, {deep: true});

        const emitUpdate = () => {
            emit('update-sorting', localSorting);
        };

        const emitSaveTemplate = () => {
            emit('save-template', customTemplate.value);
        };

        const addColumn = () => {
            if (selectedColumn.value) {
                const columnToAdd = availableColumns.value.find(
                    (col) => col.key == selectedColumn.value?.key
                );

                if (columnToAdd && !localColumns?.find((col) => col.key === columnToAdd.key)) {
                    localColumns.push(columnToAdd);
                    availableColumns.value = availableColumns.value.filter(
                        (col) => col.key !== columnToAdd.key
                    );

                    emit('update-columns', columnToAdd);
                }


                selectedColumn.value = null;
            }
        };

        return {
            localSorting,
            localColumns,
            selectedColumn,
            customTemplate,
            availableColumns,
            emitSaveTemplate,
            emitUpdate,
            addColumn,
        };
    }
})
</script>