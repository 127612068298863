<template>
    <div>
      <input v-model="personId" type="text" placeholder="PersonID" /><br/>
      <button class="btn btn-primary mt-20" @click="createUser">Create User</button>
      <p class="mt-20" v-if="responseMessage">{{ responseMessage }}</p>
    </div>
  </template>

  <script lang="ts">
  import { ref } from 'vue';
  import axios from 'axios';

  export default {
    setup() {
      const personId = ref('');
      const responseMessage = ref('');
      const loading = ref(false);

      const createUser = async () => {
        loading.value = true;
        try {
            const response = await axios.post('/api/administrator/create-user', { PersonID: personId.value });
            if (response.status === 200) {
                responseMessage.value = 'User created successfully!';
            }
        }
        catch (error: unknown) {
            if (axios.isAxiosError(error) && error.response) {
                responseMessage.value = `Error: ${error.response.data.message}`;
            } else {
                responseMessage.value = 'An error occurred. Please try again.';
            }
        }
        finally {
          loading.value = false;
        }
      };

      return {
        personId,
        responseMessage,
        loading,
        createUser,
      };
    },
  };
  </script>