<template>
   <AlertsComponent :errors="errors" />
    <div class="plan-content">
        <div v-for="(row, index) in rows" :key="index" class="form form-columns_3">
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=25
                v-model:modelValue="row.FirstName" :required="true" :error="inputErrors.FirstName"
                :isRequired="true" />
            <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12
                v-model:modelValue="row.MiddleName" :required="true" :error="inputErrors.MiddleName"
                />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="row.LastName" :required="true" :error="inputErrors.LastName"
                :isRequired="true" />
            <TextInput type="text" label="Designation" placeholder="Enter Designation" :max=100
                v-model:modelValue="row.Designation" :required="true" />
            <TextInput type="text" name="phone" label="Phone" placeholder="Phone" :max=30
                v-model:modelValue="row.Phone" :required="true" @keypress="validateNumber" :isRequired="true"  />
            <TextInput type="email" label="Email Address" placeholder="Enter Email Address" :max=50
                v-model:modelValue="row.Email" :required="true" :isRequired="true" />
            <div class="btn-delete d-flex ms-auto">
                <i class="iatse-icon-trash icon_delete plan-modal fs-4" @click="deleteRepresentative(index)"></i>
            </div>
            <div v-if="index < rows.length - 1" class="divider mb-30"> </div>
        </div>

        <button class="btn btn-secondary w-fit" @click="addRepresentative"> + Add Another Representative</button>

        <div class="action-buttons pdt-40">
            <button @click.prevent="closeModal" class="btn btn-secondary"> Back</button>
            <button @click.prevent="handleSubmit" class="btn btn-primary">
                {{ loadingSubmit ? 'Submitting...' : 'Create Local Account' }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Member } from '@/interfaces/local';
import { ref, Ref, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';

import TextInput from '@components/form/TextInput.vue';

export default defineComponent ({
    components: {
        TextInput
    },
    setup(_, { emit }) {
        const modalStore = useModalStore();
        const loadingSubmit = ref(false);
        const row = { FirstName: '', MiddleName: '', LastName: '', Designation: '', Phone: '', Email: '' };
        const inputErrors = { FirstName: false, MiddleName: false, LastName: false, Designation: false, Phone: false, Email: false };
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const router = useRouter();
        const rows: Ref<Member[]> = ref([
            { ...row }
        ]);

        const closeModal = () => {
            emit('close');
        }

        const addRepresentative = () => {
            rows.value.push(row);
        }

        const deleteRepresentative = (index: number) => {
            rows.value.splice(index, 1);

        }

        const validateNumber = (event: KeyboardEvent) => {
            const charCode = event.charCode;

            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            }
        }

        const handleSubmit = async () => {
            loadingSubmit.value = true;

            const formData = {
                Representatives: rows.value
            }

            await axios
                .post('api/local/representative-accounts', { ...formData })
                .then((response) => {
                    const modalProps = {
                        title: "Successful",
                        content: {
                            type: "text",
                            value: response.data?.success,
                            icon: "iatse-icon-user-verified",
                        },
                        onConfirmButton: "Go Back to Representative Accounts",
                        onConfirm: () => {
                            useModalStore().closeAllModals();
                        },
                    };

                    modalStore.openModal('success-modal', null, 'modal-success', null, modalProps);
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                    clearMessages();
                })

            loadingSubmit.value = false;
        }

        const clearMessages = () => {
            loadingSubmit.value = false;

            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 3500);
        }

        return {
            loadingSubmit,
            rows,
            inputErrors,
            errors,
            validateNumber,
            addRepresentative,
            deleteRepresentative,
            closeModal,
            handleSubmit
        };
    }
})
</script>