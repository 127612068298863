<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="contributions-tables">
                    <div class="contributions-table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date Claim Received</th>
                                    <th>Date Claim  Processed</th>
                                    <th>Claim Reference #</th>
                                    <th>Payment Reference #</th>
                                    <th>Payment Amount</th>
                                    <th>Admin fees</th>
                                    <th>From CAPP </th>
                                </tr>
                            </thead>
                            <tbody v-if="dataLoaded && history && history.length > 0">
                                <template v-for="(row, index) in history" :key="index">
                                    <tr v-for="claim in row.Claims" :key="claim.ClaimNumber">
                                        <td>{{ claim.ReceiveDate }}</td>
                                        <td>{{ claim.PostingDate }}</td>
                                        <td>{{ claim.ClaimNumber }}</td>
                                        <td>{{ claim.Reference ?? '-' }}</td>
                                        <td>{{ amount(claim) }}</td>
                                        <td>{{ formatCurrency(claim.Amount.AdminFee) }}</td>
                                        <td>{{ formatCurrency(claim.Amount.Gross) }}</td>
                                    </tr>
                                </template>
                                <tr class="totals">
                                    <td colspan="4">Totals</td>
                                    <td><b>{{ formatCurrency(totals.NetTotal) }}</b></td>
                                    <td><b>{{ formatCurrency(totals.AdminTotal) }}</b></td>
                                    <td><b>{{ formatCurrency(totals.GrossTotal) }}</b></td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="7">No Claims found for selected period.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { MrpHistory } from '@/interfaces/health';

export default {
    setup() {
        const dataLoaded = ref(false);
        const modalStore = useModalStore();
        const openModal = modalStore.openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const from = modalStore.currentModal?.queryParams?.From ?? '';
        const to = modalStore.currentModal?.queryParams?.To ?? '';
        const history = ref<MrpHistory[]> ([]);
        const totals = ref({
            GrossTotal: 0,
            AdminTotal: 0,
            NetTotal: 0,
        });

        const fetchData = () => {
            axios
                .get('/api/participant/health-welfare/mrp-history',
                    {
                        params: { From: from, To: to },
                    }
                )
                .then((response) => {
                    history.value = response.data.History;
                    totals.value = response.data.Totals;
                    dataLoaded.value = true;
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        onMounted(() => {
            fetchData();
        });

        const amount = computed(() => {
            return (claim: { Amount: { Gross: number; AdminFee: number } }) => 
                formatCurrency(claim.Amount.Gross - claim.Amount.AdminFee);
        });

        return {
            dataLoaded,
            formatCurrency,
            history,
            totals,
            amount
        };
    }
}
</script>