import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "plan-content" }
const _hoisted_2 = { class: "action-buttons" }
const _hoisted_3 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_SelectComponent = _resolveComponent("SelectComponent")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, {
      success: _ctx.success,
      errors: _ctx.errors
    }, null, 8, ["success", "errors"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
        : (_openBlock(), _createElementBlock("form", {
            key: 1,
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
            class: "form"
          }, [
            _createVNode(_component_TextInput, {
              type: "text",
              label: "Title",
              max: 50,
              modelValue: _ctx.autoresponder.Description,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.autoresponder.Description) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_TextInput, {
              type: "text",
              label: "Email Subject",
              max: 50,
              modelValue: _ctx.autoresponder.EmailSubject,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.autoresponder.EmailSubject) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_TextInput, {
              type: "text",
              label: "Action",
              max: 50,
              modelValue: _ctx.autoresponder.Action,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.autoresponder.Action) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_SelectComponent, {
              label: "Result",
              selectedOption: _ctx.autoresponder.Result,
              "onUpdate:selectedOption": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.autoresponder.Result) = $event)),
              options: [{ key: 0, value: 'Select Result' }],
              "single-arrow": true
            }, null, 8, ["selectedOption"]),
            _createVNode(_component_Textarea, {
              label: "Email Body",
              modelValue: _ctx.autoresponder.EmailBody,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.autoresponder.EmailBody) = $event)),
              customClass: "fw-light fs-small"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["prevent"])),
                class: "btn btn-secondary"
              }, "Cancel"),
              _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.loadingSubmit ? 'Submitting...' : 'Update'), 1)
            ])
          ], 32))
    ])
  ], 64))
}