import axios from 'axios'; // Assuming axiosService is already set up as shown before

class DocumentService {
  // View a document by ID
  async viewDocument(documentId: string): Promise<Blob> {
    try {
      const response = await axios.get(`/api/smartsearch/file/${documentId}`, { responseType: 'blob' });
      console.log(response.data, '@smart search');
      return response.data;
    }
    catch (error) {
      throw new Error('Failed to view document');
    }
  }

  // View a document by Storage Path
  async viewStorageDocument(path: string, type?: string): Promise<Blob> {
    try {
      const url = type ? `/api/smartsearch/file-storage/${type}/${path}` : `/api/smartsearch/file-storage/${path}`;

      const response = await axios.get(url, { responseType: 'blob' });
      return response.data;
    }
    catch (error) {
      throw new Error('Failed to view document');
    }
  }

  // Upload a document
  async uploadDocument(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    }
    catch (error) {
      throw new Error('Failed to upload document');
    }
  }
}

export default new DocumentService();
