<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content pdt-15">
        <!-- General Plan Detail Cards -->
        <div class="plan-details">
            <ProfileInfo title="Demographics" :multiple="true" :items="demographics" :greenItems="spouseDetails"
                :has-divider="true" :use-container="true" :alert="isBadAddress"
                @open-modal="openModal('profile', 'My Profile', 'plan-profile plan-tables')" />
        </div>

        <div class="plan-details w-100">
            <ProfileInfo title="Pension Details" type="pension" :items="pensionDetails" :has-divider="true"
                actionButton="View/Change" />
        </div>

        <div class="plan-details plan-general-details flex-column align-items-start">
            <div class="plan-general-header pdb-20">
                <h4 class="d-flex sm-fs-sm"> Payment Destination
                    <span v-if="pensionStore?.pensionerDetails?.HasPaymentsOnHold"
                        class="label label_dark_green pdl-40 pdr-40 ml-15">
                        Pension Payments on Hold - Please Call Us at 800-456-3863
                    </span>
                </h4>
                <p class="plan-modal"
                    @click="openModal('check-destination', 'ACH/Check Destination', 'plan-form', 'pension')">
                    <span class="text-underline plan-modal sm-fs-sm"> Update </span>
                    <i class="iatse-icon-arrow-down plan-modal"> </i>
                </p>
            </div>
            <table class="striped small toggle">
                <thead>
                    <tr>
                        <th v-for="(header, key) in pensionStore.paymentDestination.headers" :key="key">
                            {{ header.title }}
                        </th>
                    </tr>
                </thead>

                <tbody v-if="pensionStore.paymentDestination.rows.length">
                    <template v-for="(row, mainKey) in pensionStore.paymentDestination.rows" :key="mainKey">
                        <tr class="parent-row sm-pdl-0 sm-pdr-0">
                            <td v-for="(item, key) in row" :key="key" class="cell w-sm-45">
                                <p class="cell-header hide-desktop"> {{ key }} </p>
                                <p class="cell-text">{{ item }}</p>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="parent-row">
                        <td colspan="3" class="cell text-center">
                            <p class="cell-header">No payment destination has been set up. </p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <button class="btn btn-secondary plan-modal w-fit mt-4"
                @click.prevent="openModal('pension-payment-history', 'Pension > Pensioner Profile', 'plan-profile plan-tables pension-credits', 'pension', null, 'Payment History')">
                View Payment History
            </button>
        </div>

        <!-- Related Links -->
        <div class="account-actions">
            <h4> Related Links </h4>
            <div class="action-buttons">
                <button class="btn btn-secondary plan-modal" @click.prevent="openModal(
                    'beneficiaries',
                    'My Beneficiaries',
                    'plan-tables-striped beneficiary',
                    null,
                    null,
                    null,
                    [{ name: 'Add Beneficiary', type: 'primary', action: 'add-beneficiary', classes: 'plan-form plan-upload' }]
                )">
                    Beneficiaries
                </button>
                <template v-if="isPensioner">
                    <button class="btn btn-tertiary btn-large plan-modal"
                        @click="openModal('check-destination', 'Payment Destination Setup', 'plan-form', 'pension')">
                        ACH/Check Destination</button>
                    <button class="btn btn-tertiary btn-large plan-modal"
                        @click="openModal('check-replacement', 'Check Replacement', 'plan-form', 'pension')">
                        Check Replacement
                    </button>
                </template>
                <template v-else>
                    <a href="" class="btn btn-tertiary btn-large plan-modal"
                        @click.prevent="openModal('pension-application', 'Pension Application', 'plan-form', 'application')">Pension
                        Application
                    </a>
                    <a href="" class="btn btn-tertiary btn-large plan-modal"
                        @click.prevent="openModal('pension-application', 'Estimate Request', 'plan-form', 'estimate')">
                        Estimate Request
                    </a>
                </template>
                <a href="" @click.prevent class="btn btn-secondary"> Forms & Documents </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useAuthStore } from '@/stores/auth';
import { usePensionStore } from '@/stores/pension';
import { useProfileStore } from '@/stores/profile';
import { useFormattedDate } from '@/composable/useFormattedDate';
import { PensionStatus } from '@/interfaces/pension';

import ProfileInfo from '@/components/cards/ProfileInfo.vue';
import { useCurrencyFormatter } from '@/composable/useCurrencyFormatter';

export default defineComponent({
    components: {
        ProfileInfo,
    },
    setup() {
        const pensionStore = usePensionStore();
        const profileStore = useProfileStore();
        const loading = ref(true);
        const isBadAddress = computed(() => Number(pensionStore.demographics?.Address.BadAddress) === 1);
        const isPensioner = computed(() => pensionStore.pensionerDetails?.IsPensioner);

        const { formatCurrency } = useCurrencyFormatter();

        const demographics = computed(() => {
            return [[
                { label: 'Name', value: pensionStore.demographics?.Name },
                { label: 'Participant ID', value: pensionStore.demographics?.ParticipantID },
                { label: 'SSN', value: pensionStore.demographics?.SSN ?? '-' },
                { label: 'Sex', value: pensionStore.demographics?.Sex ?? '-' },
                { label: 'Marital Status', value: pensionStore.demographics?.MaritalStatus ?? '-' },
                { label: 'Date of Death', value: pensionStore.demographics?.DateofDeath ?? '-' },
                { label: 'Date of Marriage', value: pensionStore.demographics?.DateofMarriage ?? '-' },
            ], [
                { label: 'Street', value: pensionStore.demographics?.Address?.Street1 ?? '-' },
                { label: 'City', value: pensionStore.demographics?.Address?.City ?? '-' },
                { label: 'State', value: Number(pensionStore.demographics?.Address?.State?.key) === 0 ? '-' : pensionStore.demographics?.Address?.State?.value },
                { label: 'Country', value: Number(pensionStore.demographics?.Address?.Country?.key) === 0 ? '-' : pensionStore.demographics?.Address?.Country?.value },
                { label: 'ZIP', value: pensionStore.demographics?.Address?.Zip ?? '-' },
            ]];
        })

        const pensionDetails = computed(() => {
            return [
                { label: 'Plan', value: pensionStore.pensionerDetails?.Plan ?? '-' },
                { label: 'Type', value: pensionStore.pensionDetails?.Type ?? '-' },
                { label: 'Option', value: pensionStore.pensionDetails?.Option ?? '-' },
                { label: 'Retirement', value: pensionStore.pensionerDetails?.RetirementDate ?? '-' },
                { label: 'End Date', value: pensionStore.pensionDetails?.EndDate ?? '-' },
                { label: 'First Check', value: pensionStore.pensionDetails?.FirstCheck ?? '-' },
                { label: 'Gross', value: formatCurrency(Number(pensionStore.pensionDetails?.MonthlyPayments?.Gross)) },
                { label: 'Net', value: formatCurrency(Number(pensionStore.pensionDetails?.MonthlyPayments?.Net)) }
            ]
        })

        const spouseDetails = computed(() => {
            return [
                { label: 'Spouse Name', value: pensionStore.demographics?.SpouseName ?? '-' },
                { label: 'Date of Birth', value: pensionStore.demographics?.DateofBirth ?? '-' },
                { label: 'Date of Marriage/Divorce ', value: pensionStore.demographics?.DateofDivorce ?? '-' },
            ]
        })

        const fetchData = async () => {
            if (!profileStore.personalData) {
                await profileStore.fetchPersonalData();
            }

            if (!pensionStore.demographics || !pensionStore.pensionDetails || !pensionStore.paymentDestination?.rows?.length) {
                await pensionStore.fetchDemographics();
            }

            loading.value = false;
        }

        const toggleStates = ref<Record<number, boolean>>({});

        const isNumeric = (value: any) => {
            return !isNaN(value - parseFloat(value));
        }

        const toggleCell = (index: number) => {
            toggleStates.value[index] = !toggleStates.value[index];
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            loading,
            isPensioner,
            isBadAddress,
            demographics,
            pensionDetails,
            pensionStore,
            toggleStates,
            spouseDetails,
            isNumeric,
            toggleCell,
            openModal: useModalStore().openModal,
        };
    }
});
</script>