<template>
    <table class="small striped toggle no-underline">
        <thead class="large large_padding">
            <tr>
                <th> Local </th>
                <th> First Name </th>
                <th> Last Name </th>
                <th> Middle Name </th>
                <th> Title </th>
                <th> Email Address </th>
                <th> Phone </th>
                <th> Type </th>
                <th> Account actions </th>
            </tr>
        </thead>
        <tbody>
            <tr class="large" v-for="local in locals" :key="local.UILocalRequestID">
                <td class="col-md-1">{{ local.OrgName }} </td>
                <td class="col-md-1">{{ local.FirstName }}</td>
                <td class="col-md-1">{{ local.LastName }}</td>
                <td class="col-md-1">{{ local.MiddleName }}</td>
                <td class="col-md-2">{{ local.Title }}</td>
                <td class="col-md-2">{{ local.Email }}</td>
                <td class="col-md-1">{{ local.Phone }}</td>
                <td class="col-md-1">{{ local.Type }}</td>
                <td class="col-md-1">
                    <div class="actions">
                        <span class="tooltip-wrapper" data-tooltip="Send PIN">
                            <i class="plan-modal iatse-icon-lock-check" @click="sendPin(local.UILocalRequestID)"></i>
                        </span>
                        <p> / </p>
                        <span class="tooltip-wrapper" data-tooltip="Reject Request">
                            <i class="plan-modal iatse-icon-lock" @click="rejectRequest(local.UILocalRequestID)"></i>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { PendingLocal, Paginator } from '@/interfaces/admin';

export default defineComponent({
    props: {
        locals: {
            type: Object as PropType<PendingLocal[]>,
            required: true
        },
        actions: Object,
        processed: Number
    },

    setup(_, { emit }) {
        const sendPin = (requestID: number) => {
            emit('send-pin', requestID);
        }

        const rejectRequest = (requestID: number) => {
            emit('reject-request', requestID);
        }

        return {
            sendPin,
            rejectRequest,
        };
    }
});
</script>