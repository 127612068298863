import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon_wrapper" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['info_card', _ctx.cardTypeClass, _ctx.classes])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.iconClass)
      }, null, 2)
    ]),
    _createElementVNode("p", { innerHTML: _ctx.message }, null, 8, _hoisted_2)
  ], 2))
}