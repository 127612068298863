<template>
  <div class="steps-wrapper" :class="classes">
    <template v-for="(step, index) in steps" :key="index">
      <div class="step-item-wrapper">
        <div class="step-item" :class="{
      active: index + 1 == activeStep,
      next: index + 1 == activeStep + 1,
      completed: index + 1 < activeStep
    }">
          {{ formatStepNumber(index + 1) }}
        </div>
        <h4> Step {{ index + 1 }}</h4>
      </div>
      <div v-if="index < steps - 1" class="step-border"></div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    steps: {
      type: Number,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  setup() {
    const formatStepNumber = (step: number) => {
      return step.toString().padStart(2, '0');
    };

    return {
      formatStepNumber
    }
  },
});
</script>