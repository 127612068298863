<template>
    <div class="text-input">
      <label class="body-text">Card Number</label>
      <input
        type="text"
        class="links"
        :value="formattedValue"
        @input="onInput"
        placeholder="XXXX XXXX XXXX XXXX"
        maxlength="19"
      />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, computed } from 'vue';
  
  export default defineComponent({
    name: 'CardNumberField',
    props: {
      modelValue: {
        type: String,
        default: '',
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const formattedValue = computed(() => {
        // Get only digits and limit to 16
        const digits = props.modelValue.replace(/\D/g, '').slice(0, 16);
        if (!digits) return ''; 
        
        // Insert a space after every 4 digits
        return digits.match(/.{1,4}/g)?.join(' ') || '';
      });
  
      const onInput = (event: Event) => {
        const input = event.target as HTMLInputElement;
        // Strip spaces and non-digit characters
        const noSpaces = input.value.replace(/\s+/g, '');
        const digitsOnly = noSpaces.replace(/\D/g, '');
        const truncated = digitsOnly.slice(0, 16);
        emit('update:modelValue', truncated);
      };
  
      return {
        formattedValue,
        onInput,
      };
    },
  });
  </script>
  
  <style scoped>
  /* Add any CSS styling needed */
  </style>
  