<template>
  <form @submit.prevent="login">
    <div class="container mt-5">
      <div class="row justify-content-center">
          <div class="col-md-6">
              <div class="card">
                  <div class="card-header">Login</div>
                  <div class="card-body">
                        <div class="mb-3">
                            <label for="email" class="form-label">Email address</label>
                            <input v-model="credentials.email" type="email" id="email" class="form-control" placeholder="Email" required>
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input v-model="credentials.password" type="password" id="password" name="password" class="form-control" placeholder="Password">
                        </div>
                        <div class="form-check mb-3">
                            <input class="form-check-input" type="checkbox" id="remember" name="remember">
                            <label class="form-check-label" for="remember">
                            Remember me
                            </label>
                        </div>
                        <button type="submit" class="btn btn-primary">Login</button>
                        <p class="mt-10">Don't have an account? <router-link :to="{ name: 'register' }">
                            Click here to register
                        </router-link></p>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router'

export default {
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const credentials = ref({ email: '', password: '' });

    const login = async () => {
      try {
        await authStore.authenticateUser(credentials.value);
        // Redirect to home route after successful login
        // router.push({ name: 'home' }); // Use your home route's name
      } catch (error) {
        // Handle login error (e.g., display an error message)
        console.error('Login failed:', error);
      }
    };

    return { credentials, login };
  },
};
</script>
