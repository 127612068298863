<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="section-content" :class="{ 'hidden': modals?.length }">
        <h1>My IATSE NBF Funds</h1>
        <p v-if="errorMessage"> {{ 'Internal error, please try again later. If this issues persist please contact the Fund Office.' }}</p>
        <div v-else>
            <component v-if="!loading" :is="healthComponent" :data="healthData" :alerts="alerts.Health"
                :hasPlanCUpcomingInvoice="hasPlanCUpcomingInvoice" :hasPlanCCurrentInvoice="hasPlanCCurrentInvoice"
                :hasPlanCobraCurrentInvoice="hasPlanCobraCurrentInvoice"
                :hasPlanCobraUpcomingInvoice="hasPlanCobraUpcomingInvoice" :showNextCoverageLink="showNextCoverageLink"
                @upcoming-coverage="upcomingCoverage" />
            <AnnuityPanel v-if="annuityData" :data="annuityData" />
            <PensionPanel v-if="pensionData" :data="pensionData" :alerts="alerts?.Pension || null" />
            <VacationPanel v-if="vacationData && vacationData?.ENABLED" />
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, watch, defineAsyncComponent, Component, computed } from 'vue';
import axios from 'axios';
import { HealthDashboardInfo } from '@/interfaces/health';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { useVacationStore } from '@/stores/vacation';
import { useCoverageStore } from '@/stores/coverage';
import { usePensionStore } from '@/stores/pension';
import { useParticipantStore } from '@/stores/participant';
import { VacationDashboardInfo } from '@/interfaces/interfaces';
import HealthNoCoverage from '../../components/participant/dashboard/funds/HealthNoCoverage.vue';
import AnnuityPanel from '../../components/participant/dashboard/AnnuityPanel.vue';
import PensionPanel from '../../components/participant/dashboard/PensionPanel.vue';
import VacationPanel from '../../components/participant/dashboard/VacationPanel.vue';

export default {
    setup() {
        // Setup variables
        const loading = ref(true);
        const openModal = useModalStore().openModal;
        const annuityData = ref(null);
        const pensionData = ref(null);
        const vacationData = ref<VacationDashboardInfo | null>(null);
        const vacationStore = useVacationStore();
        const coverageStore = useCoverageStore();
        const beneStore = useBeneficiaryStore();
        const pensionStore = usePensionStore();
        const participantStore = useParticipantStore();
        const errorMessage = ref(null);

        // We no longer manage healthData locally. We'll use coverageStore.healthData.
        const healthData = computed(() => coverageStore.healthData);

        const PLAN_A = [498, 499];
        const PLAN_C = [561, 501, 502, 503, 504, 505, 506, 1050, 1049, 1062];
        const PLAN_COBRA = [633, 634, 635, 636, 637, 638, 639, 640, 1053, 1054, 641, 642, 643, 644, 645, 646, 647, 648, 1051, 1052];
        const PLAN_C_MRP = [508, 631, 632];
        const PLAN_RMRP = [1023, 1024];
        const PLAN_RETIREE = [509, 510, 511, 972, 973, 811];

        const healthLoaded = computed(() => !!healthData.value);
        const healthComponent = ref<Component>(HealthNoCoverage);
        const alerts = computed(() => participantStore.alerts?.fundData);
        const modals = computed(() => useModalStore().modals);

        const fetchData = async () => {
            // Fetch non-health data from the main dashboard endpoint
            await axios.get('api/participant/dashboard')
                .then(response => {
                    annuityData.value = response.data.Annuity;
                    pensionData.value = response.data.Pension;
                    vacationData.value = response.data.Vacation;

                    beneStore.setEligibleFunds(response.data.EligibleFunds);
                    vacationStore.setVacationData(response.data.Vacation);

                    if (!alerts?.value?.length) {
                        participantStore.fetchAlerts();
                    }
                })
                .catch(error => {
                    if (error?.response?.data) {
                        errorMessage.value = error.response.data;
                    }

                    console.error('There was an error!', error);
                })

            if (!participantStore?.general) {
                participantStore.fetchGeneralData();
            }
            await pensionStore.getPanelDetails();
            await vacationStore.getPanelDetails();

            // We do not set health data here anymore.
            loading.value = false;
        };

        // Trigger the health fetch from the coverage store on mount
        onMounted(async () => {
            // Fetch Health Data from store
            await coverageStore.fetchHealthData();
            // Once health data is loaded, fetch other data
            await fetchData();
        });

        const showNextCoverageLink = computed(() => {
            const nextCoverage = healthData.value?.NextCoverage;
            return nextCoverage && Object.keys(nextCoverage).length > 0;
        });

        const nextCoverageLink = computed(() => {
            const nextCoverage = healthData.value?.NextCoverage;
            const from = nextCoverage?.From || '';
            const to = nextCoverage?.To || '';
            const plan = nextCoverage?.Plan || '';
            const planClass = nextCoverage?.PlanClass;
            let link = '';
            let title = '';
            let classes = '';

            if (planClass) {
                if (PLAN_A.includes(planClass)) {
                    link = 'hw-plan-a-summary';
                    classes = 'plan-a-family';
                }
                else if (PLAN_C.includes(planClass)) {
                    link = 'hw-plan-c-summary';
                    classes = 'plan-c-mrp';
                }
                else if (PLAN_COBRA.includes(planClass)) {
                    link = 'hw-plan-cobra-summary';
                    classes = 'plan-c-mrp';
                }
                else if (PLAN_C_MRP.includes(planClass)) {
                    link = 'hw-plan-c-summary';
                    classes = 'plan-c-mrp';
                }
                else if (PLAN_RMRP.includes(planClass)) {
                    link = 'hw-plan-rmrp-summary';
                    classes = 'plan-rmrp';
                }
                else if (PLAN_RETIREE.includes(planClass)) {
                    link = 'hw-plan-retiree-summary';
                }

                title = plan;
            } else {
                if(plan === 'PlanA') {
                    link = 'hw-plan-a-summary';
                    title = 'Plan A';
                    classes = 'plan-a-family';
                }
                else if (plan === 'PlanC') {
                    link = 'hw-plan-c-summary';
                    title = 'Plan C';
                    classes = 'plan-c-mrp';
                }
                else if (plan === 'PlanRmrp') {
                    link = 'hw-plan-rmrp-summary';
                    title = 'Plan RMRP';
                    classes = 'plan-c-mrp ';
                }
                else if (plan === 'PlanACobra') {
                    link = 'hw-plan-cobra-summary';
                    title = 'Plan A Cobra';
                    classes = 'plan-c-mrp ';
                }
                else if (plan === 'PlanCCobra') {
                    link = 'hw-plan-cobra-summary';
                    title = 'Plan C Cobra';
                    classes = 'plan-c-mrp ';
                }
            }

            return { plan: link, title: title, classes: classes, from: from, to: to };
        });

        const upcomingCoverage = () => {
            const link = nextCoverageLink.value.plan;
            const title = nextCoverageLink.value.title;
            const classes = nextCoverageLink.value.classes;
            const fromDate = nextCoverageLink.value.from;
            const toDate = nextCoverageLink.value.to;

            openModal(
                link,
                title,
                classes,
                null,
                null,
                null,
                undefined,
                { FromDate: fromDate, ToDate: toDate }
            );
        };

        const hasPlanCUpcomingInvoice = computed(() => {
            return (
                healthData.value?.PlanCInvoices?.Upcoming &&
                Object.keys(healthData.value.PlanCInvoices.Upcoming).length > 0
            ) || false;
        });

        const hasPlanCCurrentInvoice = computed(() => {
            return (
                healthData.value?.PlanCInvoices?.Current &&
                Object.keys(healthData.value.PlanCInvoices.Current).length > 0
            ) || false;
        });

        const hasPlanCobraUpcomingInvoice = computed(() => {
            return (
                healthData.value?.CobraInvoices?.Upcoming &&
                Object.keys(healthData.value.CobraInvoices.Upcoming || {}).length > 0
            ) || false;
        });

        const hasPlanCobraCurrentInvoice = computed(() => {
            return (
                healthData.value?.CobraInvoices?.Current &&
                Object.keys(healthData.value.CobraInvoices?.Current).length > 0
            ) || false;
        });

        watch(healthData, async (newData: HealthDashboardInfo | null) => {
            if (!newData) return;

            switch (newData.Template) {
                case 'plan_c':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanC.vue'));
                    break;
                case 'plan_c_cobra':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanCCobra.vue'));
                    break;
                case 'plan_a':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanA.vue'));
                    break;
                case 'plan_a_cobra':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanACobra.vue'));
                    break;
                case 'mrp':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanMRP.vue'));
                    break;
                case 'rmrp':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanRMRP.vue'));
                    break;
                case 'retiree':
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthPlanRetiree.vue'));
                    break;
                case 'no_coverage':
                default:
                    healthComponent.value = defineAsyncComponent(() => import('../../components/participant/dashboard/funds/HealthNoCoverage.vue'));
                    break;
            }

        }, { immediate: true });

        return {
            healthData, healthLoaded, annuityData, pensionData, vacationData, healthComponent, modals, loading, alerts, errorMessage,
            hasPlanCUpcomingInvoice,
            hasPlanCCurrentInvoice,
            hasPlanCobraCurrentInvoice,
            hasPlanCobraUpcomingInvoice,
            showNextCoverageLink,
            upcomingCoverage,
            openModal
        };
    },

    components: {
        AnnuityPanel,
        PensionPanel,
        VacationPanel
    }
};
</script>
