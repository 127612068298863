import { defineStore } from 'pinia';
import { ref } from 'vue';
import { CappStatementOption } from '@/interfaces/health';

export const useCappInvoicesStore = defineStore('cappInvoices', () => {
    const selectedInvoice = ref<CappStatementOption | null>(null);

    // Action to set the selected invoice
    const setSelectedInvoice = (invoice: CappStatementOption) => {
        selectedInvoice.value = invoice;
    };

    // Action to clear the selected invoice
    const clearSelectedInvoice = () => {
        selectedInvoice.value = null;
    };

    // Function to check if an invoice is selected
    const isInvoiceSelected = (optionKey: string) => {
        return selectedInvoice.value?.OptionKey === optionKey;
    };

    return {
        selectedInvoice,
        setSelectedInvoice,
        clearSelectedInvoice,
        isInvoiceSelected,
    };
});