import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useOptionsFormatter } from "@/utils/optionFormatter";
import { SelectOption, ParticipantData, Alert } from "@/interfaces/interfaces";
import axios from "axios";

interface AlertOption {
    key: number | string;
    value: Alert[];
}

export const useParticipantStore = defineStore("participant", () => {
    // State
    const alerts = ref({
        data: [] as AlertOption[],
        fundData: [] as SelectOption[],
        unreadCount: 0,
        unreadAlerts: false,
    });

    const general = ref<ParticipantData | null>(null);

    // Actions
    const fetchAlerts = async (limit?: number) => {
        try {
            const response = await axios.get(`api/participant/alerts/${limit}`);
            alerts.value.data = useOptionsFormatter(response.data.Alerts);
            alerts.value.fundData = response.data.UnreadAlertsByFund;
            alerts.value.unreadCount = response.data.UnreadCount;
        } catch (error) {
            console.error("There was an error!", error);
        }
    };

    const fetchGeneralData = async () => {
        try {
            const response = await axios.get("api/participant/general-data");
            general.value = response.data;
        } catch (error) {
            console.error("There was an error!", error);
        }
    };

    const getUnread = () => {
        const unread = alerts.value.data?.map(alert => ({
            ...alert,
            value: alert.value.filter((item: Alert) => Number(item.AlertRead) === 0),
        }))
        .filter(alert => alert.value.length > 0);

        return unread;
    }

    const reset = () => {
        alerts.value = {
            data: [],
            fundData: [],
            unreadCount: 0,
            unreadAlerts: false,
        };
        general.value = null;
    };

    // Getters
    const dashboardItems = computed(() => {
        if (!general.value) return [];

        return [
            {
                iconClass: "iatse-icon-profile-circle",
                title: "Participant ID",
                detail: general.value?.ParticipantID,
            },
            {
                iconClass: "iatse-icon-personalcard",
                title: "Name",
                detail: general.value?.Name,
            },
            {
                iconClass: "iatse-icon-cake",
                title: "Date of Birth",
                detail: general.value?.DateofBirth,
            },
            {
                iconClass: "iatse-icon-security-user",
                title: "Age",
                detail: `${general.value?.Age} Yrs`,
            },
            {
                iconClass: "iatse-icon-location",
                title: "Address",
                detail:
                    general.value?.AddressFormatted !== ""
                        ? general.value?.AddressFormatted
                        : "--",
            },
        ];
    });

    return {
        alerts,
        general,
        getUnread,
        fetchAlerts,
        fetchGeneralData,
        reset,
        dashboardItems,
    };
});
