<template>
    <AlertsComponent :success="success" />
    <div class="plan-content">
        <form id="edit-alert" class="form">
            <TextInput type="text" label="Alert ID" :max=50 v-model:modelValue="alert.AlertTypeID" :readonly="true" />
            <SelectComponent label="Fund Type" v-model:selectedOption="alert.FundType" :options="fundTypes"
                :single-arrow="true" />

            <div class="custom-select multiselect_bullet_tags links">
                <label class="body-text"> Document Type Id </label>
                <Multiselect v-model="alert.Documents" :options="documents" placeholder="Type to search"
                    :multiple="true" :hide-selected="true" track-by="CodeID" label="Description" :searchable="true"
                    :close-on-select="true" :taggable="true" @search-change="handleDocumentSearch">
                </Multiselect>
            </div>
            <TextInput type="text" label="Type" :max=50 v-model:modelValue="alert.Name" />
            <Textarea label="Message" v-model:modelValue="alert.Message" customClass="fw-light fs-small" />

            <div class="action-buttons">
                <button @click.prevent="closeModal" class="btn btn-secondary"> Cancel </button>
                <button class="btn btn-primary" :disabled="loadingSubmit" @click.prevent="handleSave">
                    {{ loadingSubmit ? 'Saving...' : 'Save' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { MiscellaneousCode, SelectOption } from '@/interfaces/interfaces';
import { AlertType } from '@/interfaces/admin';
import { onMounted, ref, Ref, defineComponent } from 'vue';
import { useSuperadminStore } from '@/stores/superadmin';
import axios from 'axios';

import TextInput from '@components/form/TextInput.vue';
import Textarea from "@components/form/TextareaComponent.vue";
import SelectComponent from "@components/form/SelectComponent.vue";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';


export default defineComponent({
    components: {
        TextInput,
        Textarea,
        SelectComponent,
        Multiselect
    },
    setup(_, { emit }) {
        const superadminStore = useSuperadminStore();
        const alert: Ref<AlertType | null> = ref(superadminStore.alertType);
        const fundTypes = ref<SelectOption[]>(superadminStore.fundTypes);
        const documents = ref([]);
        const loadingSubmit = ref(false);
        const loadingSearch = ref(false);
        const success = ref(null);
        const closeModal = () => {
            emit('close');
        }

        // Handle Save Alert Type
        const handleSave = async () => {
            if (alert.value?.AlertTypeID) {
                loadingSubmit.value = true;
                const data = { ...alert.value, Documents: JSON.parse(JSON.stringify(alert.value?.Documents)).map((document: MiscellaneousCode) => document.CodeID).join(','), FundType: alert.value.FundType?.value !== 'Fund Type' ? alert.value.FundType?.value : null };

                await axios
                    .post(`api/administrator/alerts/${alert.value?.AlertTypeID}`, { ...data, _method: "PATCH" })
                    .then((response) => {
                        success.value = response.data?.success;
                    })
                    .finally(async () => {
                        setTimeout(() => success.value = null, 3000);
                        await superadminStore.fetchAlertTypes();
                        loadingSubmit.value = false;
                    })
            }
        }

        // Handle document search
        const handleDocumentSearch = async (query: string) => {
            loadingSearch.value = true;

            if (query.length > 2) {
                try {
                    const response = await axios.get(`api/administrator/documents?query=${query}`);
                    documents.value = response.data;
                } catch (error) {
                    console.error('Error searching documents:', error);
                }
            }

            loadingSearch.value = false;
        };

        return {
            loadingSubmit,
            loadingSearch,
            success,
            alert,
            fundTypes,
            documents,
            closeModal,
            handleSave,
            handleDocumentSearch,
        }
    }
})
</script>