// src/stores/coverage.ts
import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import { HealthDashboardInfo } from '@/interfaces/health';

export const useCoverageStore = defineStore('coverage', () => {
	const coverage = ref<HealthDashboardInfo['Coverage'] | null>(null);
    const healthData = ref<HealthDashboardInfo | null>(null);
    const isAvailableForClaim = ref(false);
	const loading = ref(false);
	const error = ref<Error | null>(null);

	const fetchHealthData = async () => {
		loading.value = true;
		error.value = null;

		try {
			const response = await axios.get('/api/participant/health-welfare');
            // Extract Health data from response and store it
            healthData.value = response.data;
            coverage.value = response.data.Coverage;
            isAvailableForClaim.value = response.data.IsAvailableForClaim;
		} catch (err) {
			error.value = err as Error;
			console.error('Error fetching health data:', error.value);
		} finally {
			loading.value = false;
		}
	};

	const clearCoverageData = () => {
		coverage.value = null;
        healthData.value = null;
        isAvailableForClaim.value = false;
	};

	return {
		coverage,
        healthData,
        isAvailableForClaim,
		loading,
		error,
		fetchHealthData,
		clearCoverageData,
	};
});
