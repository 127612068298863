import { defineStore } from "pinia";
import { ClaimRequest, ClaimFilters, Patient } from "@/interfaces/health";
import { RowData, TableData } from "@/interfaces/interfaces";
import { Paginator } from '@/interfaces/admin';

import axios from "axios";

const initialFilters: ClaimFilters = {
    Claim: null,
    WebReference: null,
    PatientName: null,
    ServiceDate: {
        StartDate: "",
        EndDate: "",
    },
    SubmissionDate: {
        StartDate: "",
        EndDate: "",
    },
    ClaimStatus: [],
    Sorting: {
        ServiceDate: {
            StartDate: "ASC",
            EndDate: "ASC",
        },
        SubmissionDate: {
            StartDate: "DESC",
            EndDate: "DESC",
        },
    },
    Page: 1,
};

export const useClaimStore = defineStore({
    id: "claim",
    state: () => ({
        claim: {
            Patient: null,
            Type: "add",
            ClaimID: null,
            Documents: [],
            Notes: null,
        } as ClaimRequest,
        patient: null as string | number | null,
        claimLinks: {
            list: "",
            form: "",
        },
        claims: {
            headers: [
                { title: 'Date Filled' },
                { title: 'Claim Number' },
                { title: 'Web Ref.' },
                { title: 'Claim Status' },
                { title: 'Claim Doc(s)' },
            ],
            rows: []
        } as TableData,
        history: {
            headers: [
                { title: "Date Filled" },
                { title: "Claim Number" },
                { title: "Web Reference." },
                { title: "Claim Status" },
                { title: "Claim Doc(s)" },
            ],
            rows: [],
        } as TableData,
        filters: { ...initialFilters } as typeof initialFilters,
        paginator: { per_page: 4, total: 2, last_page: 2, current_page: 1} as Paginator
    }),

    actions: {
        setClaim(claim: ClaimRequest) {
            this.claim = claim;
        },

        setPatient(patient: string | number| null) {
            this.patient = patient;
        },

        setLinks(list: string, form: string) {
            this.claimLinks.list = list;
            this.claimLinks.form = form;
        },

        setHistory(data: RowData[] | [], filters: ClaimFilters) {
            this.history.rows = data;
            this.filters = filters;
        },

        clearClaim() {
            this.claim = {
                Patient: null,
                Type: "add",
                ClaimID: null,
                Documents: null,
                Notes: null,
            };

            this.filters.Page = 1;
            this.patient = null;
        },

        updateClaim(newClaim: Partial<ClaimRequest>) {
            this.claim = { ...this.claim, ...newClaim };
        },

        resetFilters() {
            this.filters = { ...initialFilters };
        },

        async performSearch() {
            await axios
                .post("api/participant/claims-history-participant", this.filters)
                .then((response) => {
                    this.setHistory(response.data.History, this.filters);
                    this.paginator = response.data.Paginator;
                });
        },

        async fetchClaims() {
            await axios
                .get("api/participant/claims")
                .then(response => {
                    this.claims.rows = response.data?.Claims;
                    this.setLinks(response.data?.CoveredList, response.data?.ClaimForm);
                });
        },

        fetchPaginationData(pageNumber: number) {
            this.filters.Page = pageNumber;
            this.performSearch();
        },
    },

    getters: {
        // Get history
        getHistory(state) {
            return state.history;
        },

        // Get claim links
        getClaimLinks(state) {
            return state.claimLinks;
        },
    },
});
