import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4044b0e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_3 = ["id", "name", "placeholder", "readonly", "required", "value"]
const _hoisted_4 = {
  key: 1,
  class: "text-secondary-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['text-input', _ctx.customClass, { 'error': _ctx.error && _ctx.error.length }])
  }, [
    (_ctx.label !== '')
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.label,
          class: "body-text"
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " * "))
            : _createCommentVNode("", true)
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "text",
      id: _ctx.label,
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      readonly: _ctx.readonly,
      required: _ctx.required,
      class: "links",
      value: _ctx.inputValue,
      maxlength: "17",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
    }, null, 40, _hoisted_3),
    (_ctx.error && _ctx.error.length)
      ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(Array.isArray(_ctx.error) ? _ctx.error.join('. ') : _ctx.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}