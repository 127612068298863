import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pdl-10 pdt-30 pdr-10" }
const _hoisted_2 = {
  key: 1,
  class: "d-flex flex-column gap-25"
}
const _hoisted_3 = { class: "d-flex gap-25 flex-md-row flex-column" }
const _hoisted_4 = { class: "w-35 d-flex flex-column gap-25 w-sm-100" }
const _hoisted_5 = { class: "w-70 w-sm-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_ProfilePanel = _resolveComponent("ProfilePanel")!
  const _component_PaymentPanel = _resolveComponent("PaymentPanel")!
  const _component_RecentAnnouncements = _resolveComponent("RecentAnnouncements")!
  const _component_ReportsPanel = _resolveComponent("ReportsPanel")!
  const _component_ResourcesPanel = _resolveComponent("ResourcesPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ProfilePanel, {
                data: $setup.profileData,
                type: "Employer"
              }, null, 8, ["data"]),
              _createVNode(_component_PaymentPanel, { links: $setup.paymentLinks }, null, 8, ["links"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_RecentAnnouncements, { announcements: $setup.announcements }, null, 8, ["announcements"])
            ])
          ]),
          _createVNode(_component_ReportsPanel),
          _createVNode(_component_ResourcesPanel, { resources: $setup.resources }, null, 8, ["resources"])
        ]))
  ]))
}