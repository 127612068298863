import { Ref, ref } from "vue";
import axios from "axios";

interface FormSubmitOptions {
    formData: any;
    route: string;
    success: Ref<string|null>;
    info?: Ref<string|null>;
    errors: Ref<string[]>;
    loadingSubmit: Ref<boolean>;
    method?: "POST" | "PATCH" | "PUT" | "DELETE";
    generalErrors?: Ref<string[]>;
    reset?: boolean;
}

const useFormSubmit = ({
    formData,
    route,
    success,
    info = ref(""),
    errors,
    loadingSubmit,
    method = "PATCH",
    generalErrors = ref([]),
    reset = true
}: FormSubmitOptions) => {
    const handleSubmit = async () => {
        loadingSubmit.value = true;

        const data = { ...formData };

        Object.keys(formData).forEach((key) => {
            const value = data[key];

            if (value && typeof value === "object" && "key" in value) {
                data[key] = value.key;
            } else {
                data[key] = value;
            }
        });

        await axios
            .post(route, { ...data, _method: method })
            .then((response) => {
                if (response.data?.info) {
                    info.value = response.data?.info;
                } else {
                    success.value = response.data?.success;
                }

                loadingSubmit.value = false;
            })
            .catch((error) => {
                if (error.response.data.errors && reset) {
                    errors.value = Object.values(
                        error.response.data.errors
                    ).flat() as string[];

                    generalErrors.value = error.response?.data?.error?.General;
                } else {
                    errors.value = error.response.data.errors;
                    generalErrors.value = error.response?.data?.error?.General;
                }

                if (error.response.data.error) {
                    errors.value = [error.response.data.error]
                }

                loadingSubmit.value = false;
            });

        setTimeout(clearMessages, 3500);

        return success.value !== "";
    };

    const clearMessages = () => {
        success.value = "";
        info.value = "";
        generalErrors.value = [];

        if(reset) {
            errors.value = [];
        }
    };

    return {
        handleSubmit,
    };
};

export default useFormSubmit;
