<template>
    <FundPanel cardId="card-annuity" v-if="data.ENABLED">
        <template #icon>
            <i class="iatse-icon-fund-annuity"></i>
        </template>
        <template #title>
            Annuity
        </template>
        <template #fund-cover>
            <div class="col-md-9">
                <div class="fund-cover fund-cover__annuity d-flex align-items-start flex-column">
                    <div class="fund-cover-header">
                        <div class="coverage-column">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-dollar-square"></i>
                                <p class="links"><strong>Principal Balance</strong></p>
                            </div>
                            <p class=""><strong>{{ '$ ' + data.LastContribution.Amount }}</strong> <small>(as of
                                    {{ data.LastContribution.FormattedDate }})</small></p>
                        </div>
                        <div class="divider"> </div>
                        <div class="coverage-column">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-info-circle"></i>
                                <p class="links">Note</p>
                            </div>
                            <h6 class="mt-10">Click to access your Principal Account details and payments</h6>
                        </div>
                    </div>
                    <div class="fund-cover-buttons">
                        <a href="" class="btn btn-primary plan-modal"
                            @click.prevent="openModal('work-history', 'Work History', null, 'annuity')">Work
                            History</a>
                        <a href="https://www.principal.com/" target="_blank" class="btn btn-secondary">
                            <i class="iatse-icon-principal"></i>
                            <span>Principal Login</span>
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template #related-links>
            <a href="" @click.prevent="openModal('annuity-application', 'Annuity Application - Eligibility Determination Request', 'plan-form')"
                class="btn btn-tertiary btn-large plan-modal">
                Annuity Application</a>
            <a :href="data.SalaryDeferralCoupon" target="_blank" class="btn btn-tertiary btn-large">Salary
                Deferral Coupon</a>
            <a href="" class=" btn btn-tertiary btn-large plan-modal"
                @click.prevent="openModal(
                'beneficiaries',
                'My Beneficiaries',
                'plan-tables-striped beneficiary',
                null,
                null,
                null,
                [{ name: 'Add Beneficiary', type: 'primary', action: 'add-beneficiary', classes: 'plan-form plan-upload' }]
            )">
                Beneficiaries </a>
            <a href="" class="btn btn-tertiary btn-large">Funds Resources</a>
        </template>
    </FundPanel>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { AnnuityDashboardData } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import FundPanel from '@/components/cards/FundPanel.vue';
import axios from 'axios';

export default defineComponent({
    props: {
        data: {
            type: Object as () => AnnuityDashboardData,
            required: true
        }
    },
    components: {
        FundPanel
    },

    setup(props) {
        const modalStore = useModalStore();
        const accountBalance = ref(null);
        const visibleContent = ref(true);

        onMounted(async () => {
            try {
                const response = await axios.get('api/participant/annuity/balance');
                accountBalance.value = response.data.accountBalance;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        });

        const toggleVisible = () => {
            visibleContent.value = !visibleContent.value;
        }

        return {
            accountBalance,
            visibleContent,
            toggleVisible,
            openModal: modalStore.openModal
        };
    }
});
</script>