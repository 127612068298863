import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "plan-content" }
const _hoisted_2 = { class: "btn-delete d-flex ms-auto" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "divider mb-30"
}
const _hoisted_5 = { class: "action-buttons pdt-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, { errors: _ctx.errors }, null, 8, ["errors"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "form form-columns_3"
        }, [
          _createVNode(_component_TextInput, {
            type: "text",
            label: "First Name",
            placeholder: "Enter First Name",
            max: 25,
            modelValue: row.FirstName,
            "onUpdate:modelValue": ($event: any) => ((row.FirstName) = $event),
            required: true,
            error: _ctx.inputErrors.FirstName,
            isRequired: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "error"]),
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Middle Name",
            placeholder: "Enter Middle Name",
            max: 12,
            modelValue: row.MiddleName,
            "onUpdate:modelValue": ($event: any) => ((row.MiddleName) = $event),
            required: true,
            error: _ctx.inputErrors.MiddleName
          }, null, 8, ["modelValue", "onUpdate:modelValue", "error"]),
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Last Name",
            placeholder: "Enter Last Name",
            max: 25,
            modelValue: row.LastName,
            "onUpdate:modelValue": ($event: any) => ((row.LastName) = $event),
            required: true,
            error: _ctx.inputErrors.LastName,
            isRequired: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "error"]),
          _createVNode(_component_TextInput, {
            type: "text",
            label: "Designation",
            placeholder: "Enter Designation",
            max: 100,
            modelValue: row.Designation,
            "onUpdate:modelValue": ($event: any) => ((row.Designation) = $event),
            required: true
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          _createVNode(_component_TextInput, {
            type: "text",
            name: "phone",
            label: "Phone",
            placeholder: "Phone",
            max: 30,
            modelValue: row.Phone,
            "onUpdate:modelValue": ($event: any) => ((row.Phone) = $event),
            required: true,
            onKeypress: _ctx.validateNumber,
            isRequired: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeypress"]),
          _createVNode(_component_TextInput, {
            type: "email",
            label: "Email Address",
            placeholder: "Enter Email Address",
            max: 50,
            modelValue: row.Email,
            "onUpdate:modelValue": ($event: any) => ((row.Email) = $event),
            required: true,
            isRequired: true
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("i", {
              class: "iatse-icon-trash icon_delete plan-modal fs-4",
              onClick: ($event: any) => (_ctx.deleteRepresentative(index))
            }, null, 8, _hoisted_3)
          ]),
          (index < _ctx.rows.length - 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      _createElementVNode("button", {
        class: "btn btn-secondary w-fit",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addRepresentative && _ctx.addRepresentative(...args)))
      }, " + Add Another Representative"),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["prevent"])),
          class: "btn btn-secondary"
        }, " Back"),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
          class: "btn btn-primary"
        }, _toDisplayString(_ctx.loadingSubmit ? 'Submitting...' : 'Create Local Account'), 1)
      ])
    ])
  ], 64))
}