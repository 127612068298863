import { ref } from 'vue';

export function useFormattedDate() {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    // Format the date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${month}/${day}/${year}`;
  };

  return {
    formatDate
  };
}
