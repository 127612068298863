<template>
    <div class="bordered-card transparent flex-row gap-15 align-items-center justify-content-between">
        <h2 :class="{'fs-base-lg fw-normal': small}">What is covered/ Reimbursable?</h2>
        <a :href="links.list" target="_blank" class="btn btn-secondary plan-modal"> <i
            class="iatse-icon-list-view-rectangle"> </i> See List </a>
        <a :href="links.form" target="_blank" class="btn btn-primary">
            Download Claim Form
        <img src="@/assets/images/icons/pdf.svg" alt="pdf icon" /> </a>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        links: {
            type: Object,
            required: true
        },
        small: {
            type: Boolean,
            default: false
        }
    },
})

</script>