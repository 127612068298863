<template>
    <div class="pdl-10 pdr-10 mt-30">
        <div class="filters d-flex gap-4 flex-wrap flex-md-nowrap justify-content-between w-100 mb-30">
            <div class="d-flex flex-column gap-10 col-md-6 col-sm-12">
                <h2>Currently Listed on {{ userName }} Roster</h2>
                <h6>
                    Please review your listing for accuracy.
                    <a href="https://octobercms.dev.digitalgarage.ro/en/contact" target="_blank">Contact Us.</a>
                </h6>
            </div>
            <TextInput type="text" name="mixed" placeholder="Search by NBF Participant ID, First or Last Name" :max=100
                v-model:modelValue="filter" customClass="search w-35 w-sm-100" />
        </div>

        <LoaderComponent v-if="loading" />
        <div v-else>
            <!-- <button class="btn btn-primary" @click="printList">Print List</button> -->
            <table class="small striped toggle no-underline mt-30" id="table-contract-info">
                <thead class="large large_padding">
                    <tr>
                        <th>Last Name</th>
                        <th>First & Middle name</th>
                        <th>NBF Participant ID</th>
                        <th>Suffix</th>
                        <th>D.O.B</th>
                        <th>Gender</th>
                        <!-- <th>Marital Status</th> -->
                    </tr>
                </thead>
                <tbody v-if="data.length > 0 && !loading">
                    <tr v-for="person in data" :key="person.PersonID" class="large">
                        <td>
                            <a href="javascript;" class="plan-modal"
                                @click.prevent="openModal('local-member-profile', 'Member Profile', 'plan-profile plan-tables profile', null, { PersonID: person.PersonID })">
                                {{ person.LastName }}
                            </a>
                        </td>
                        <td>{{ person.FirstName }} <span v-if="person.MiddleName">, {{ person.MiddleName }}</span></td>
                        <td>{{ person.PersonID }}</td>
                        <td>{{ person.NameSuffix }}</td>
                        <td v-if="person.DateofBirth">{{ person.DateofBirth  }}</td>
                        <td v-else class="text-danger">Missing Info</td>
                        <td>{{ person.Gender }}</td>
                        <!-- <td>{{ person.MaritalStatus }}</td> -->
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="large">
                        <td colspan="8">
                            No results found.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div id="print"></div>
            <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, reactive, Ref, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import { usePrint } from '@/composable/usePrint';
import { Person } from '@/interfaces/local';
import { Paginator } from "@/interfaces/admin";

import PaginationComponent from '@components/utils/PaginationComponent.vue';
import TextInput from '@components/form/TextInput.vue';
import axios from 'axios';

export default {
    components: {
        PaginationComponent,
        TextInput
    },

    setup() {
        const loading = ref(true);
        const data: Ref<Person[] | []> = ref([]);
        const route = "api/local/roster";
        const userName = useAuthStore()?.userName;
        const modalStore = useModalStore();
        const paginator: Paginator = reactive({
            per_page: 0,
            total: 0,
            last_page: 0,
            current_page: 0,
        });
        const filter = ref('');
        const page = ref(1);
        const table = ref(null);
        const { printElementById } = usePrint();

        const printList = printElementById('table-roster', 'Local Roster');

        watch(filter, async () => {
            page.value = 1;
            await fetchData(true);
        }, { deep: true });

        const fetchData = async (isLoading: boolean) => {
            if (isLoading) {
                loading.value = true;
            }

            await axios.get(route, { params: { page: page.value, Filters: { Search: filter.value } } })
                .then(response => {
                    data.value = Object.values(response.data.Persons);
                    Object.assign(paginator, response.data.Paginator);
                    page.value = paginator.current_page;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        // Fetch Data For Selected Page
        const fetchPaginationData = (pageNumber: number) => {
            page.value = pageNumber;
            fetchData(false);
        };

        onMounted(async () => await fetchData(true));

        return {
            data,
            userName,
            loading,
            paginator,
            printList,
            filter,
            fetchPaginationData,
            openModal: modalStore.openModal,
        };
    }
}
</script>
