<template>
    <div class="form account-settings">
        <div class="d-flex w-full gap-2" v-if="localSelectedQuestion.ExistingQuestion && !localSelectedQuestion.IsEditable">
            <SelectComponent :label="'Security Question' + (index > 1 ? ' ' + index : '')"
                v-model:selectedOption="selectedOption" :options=options :readonly="true" v-if="!localSelectedQuestion.IsEditable" />

            <transition name="slide-fade">
                <div class="d-flex align-items-end w-full gap-2" v-if="!localSelectedQuestion.IsEditable">
                    <a href="" @click.prevent="toggleEditable" class="btn btn-primary action-btn"> Change </a>
                    <a href="" @click.prevent="removeQuestion" class="btn btn-secondary action-btn"> Remove </a>
                </div>
            </transition>
        </div>

        <!-- Edit Security Question -->
        <transition name="slide-fade">
            <div class="d-flex w-full justify-content-center align-items-end gap-2" v-if="localSelectedQuestion.IsEditable && localSelectedQuestion.ExistingQuestion">
                <SelectComponent :label="'Edit Security Question' + (index > 1 ? ' ' + index : '')"
                    v-model:selectedOption="selectedOption" :options=options @change="handleQuestion" />

                <TextInput v-model:modelValue="localSelectedQuestion.Answer" type="text"
                    :label="'Edit Security Answer' + (index > 1 ? ' ' + index : '')" placeholder="Edit Security Answer" />
            </div>
        </transition>

        <!-- Add New Security Question -->
        <div class="d-flex w-full justify-content-center align-items-end gap-2" v-if="localSelectedQuestion.IsEditable && !localSelectedQuestion.ExistingQuestion">
            <SelectComponent :label="'Add New Security Question' + (index > 1 ? ' ' + index : '')" v-model:selectedOption="selectedOption" :options=options @change="handleQuestion" />
            <TextInput v-model:modelValue="localSelectedQuestion.Answer" type="text"
                :label="'Add New Security Answer' + (index > 1 ? ' ' + index : '')"
                placeholder="Enter New Security Answer" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, Ref, watch, computed } from 'vue';
import { SecurityQuestion, SelectOption } from '@/interfaces/interfaces';
import { SelectedQuestion, SelectedQuestionRecord } from '@/interfaces/employer';
import SelectComponent from '@components/form/SelectComponent.vue';
import TextInput from '@components/form/TextInput.vue';

export default defineComponent({
    props: {
        label: String,
        questionId: Number, // The index represents the number of the security question and is applicable only to local and employer settings.
        options: {
            type: Array as PropType<{ key: number, value: string }[]>,
            required: true
        },
        userQuestion: {
            type: Object as PropType<SecurityQuestion>,
            required: true,
        },
        selectedQuestion: {
            type: Object as PropType<SelectedQuestion>,
            required: false,
        },
        index: {
            type: Number,
            required: true
        },
        showAddNewQuestion: {
            type: Boolean,
            default: false
        },
        resetInputs: {
            type: Boolean,
            default: false
        },
    },
    components: {
        SelectComponent,
        TextInput
    },
    setup(props, { emit }) {
        const defaultQuestion = { Question: 0, Answer: '', ExistingQuestion: props.questionId || 0, IsEditable: true }
        const questionToAdd = computed(() =>  props.selectedQuestion ? props.selectedQuestion : defaultQuestion);
        const localSelectedQuestion: Ref<SelectedQuestion> = ref(questionToAdd.value);
        const selectedOption = ref({key: props.userQuestion?.SecurityQuestionID ?? 0, value: props.userQuestion?.Question ?? 'Select Question'});

        // Reset Inputs
        watch(() => props.resetInputs, () => {
            if(localSelectedQuestion.value?.IsEditable) {
                selectedOption.value = {
                    key: 0,
                    value: 'Select Question',
                };

                localSelectedQuestion.value.Answer = '';
            }
        });

        watch(() => props.selectedQuestion, (newQuestion) => {
            localSelectedQuestion.value = newQuestion as SelectedQuestion;
        }, { deep: true });

        // Update The Selected Question
        const handleQuestion = (option: SelectOption) => {
            (localSelectedQuestion.value as SelectedQuestion).Question = Number(option.key);

            emit('handle-questions', localSelectedQuestion.value, props.index - 1);
        };

        const toggleEditable = () => {
            emit('toggle-edit', props.index - 1);
        }

        const removeQuestion = () => {
            emit('delete', localSelectedQuestion.value?.ExistingQuestion);
        }

        return {
            localSelectedQuestion,
            selectedOption,
            handleQuestion,
            toggleEditable,
            removeQuestion,
        }
    }
})
</script>