<template>
  <div class="container mt-4">
    <div class="row mb-4">
      <div class="col-md-12 text-center">
        <h1>My Address</h1>
      </div>
    </div>
    <div class="row mb-4">
      <span>Enter the information you wish to update in the applicable field.
        Note that all fields marked with an asterisk are required fields </span>
      <span>All updates to your address will immediately and permanently change
        your account with the Fund Office </span>
    </div>
    <div v-if="loading"> Loading... </div>
    <div v-else class="row mb-4">
      <div class="col-md-12 mb-4">
        <form @submit.prevent="handleSubmit">
          <div v-if="success" class="alert alert-success">
            {{ success }}
          </div>
          <div v-if="info" class="alert alert-info">
            {{ info }}
          </div>
          <div v-if="errors.length > 0" class="alert alert-danger">
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
          <div class="form-group row mb-4">
            <div class="col-md-3">
              <label for="address_type" class="col-form-label text-md-right">Address Type <span
                  class="text-danger">*</span>
              </label>
              <select class="form-control" id="address_type" v-model="formData.Type">
                <option value="">Select Address Type</option>
                <option v-for="addressType in addressTypes" :value="addressType.CodeID" :key="addressType.CodeID">
                  {{ addressType.Description }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mb-4">
            <div class="col-md-6">
              <label for="address1" class="col-form-label text-md-right">Address 1 <span
                  class="text-danger">*</span></label>
              <input type="text" id="address1" name="Street1" v-model="formData.Street1" class="form-control" required>
            </div>
            <div class="col-md-6">
              <label for="address2" class="col-form-label text-md-right">Address 2</label>
              <input type="text" id="address2" name="Street2" v-model="formData.Street2" class="form-control">
            </div>
          </div>

          <div class="form-group row mb-4">
            <div class="col-md-6">
              <label for="address3" class="col-form-label text-md-right">Address 3 </label>
              <input type="text" id="address3" name="Street3" v-model="formData.Street3" class="form-control">
            </div>
            <div class="col-md-6">
              <label for="address4" class="col-form-label text-md-right">Address 4</label>
              <input type="text" id="address4" name="Street4" v-model="formData.Street4" class="form-control">
            </div>
          </div>

          <div class="form-group row mb-4">
            <div class="col-md-4">
              <label for="city" class="col-form-label text-md-right">City <span class="text-danger">*</span></label>

              <input type="text" id="city" name="City" v-model="formData.City" class="form-control" required>
            </div>
            <div class="col-md-4">
              <label for="state" class="col-form-label text-md-right d-flex gap-2"> <span>State</span>
                <span id="state_required" class="text-danger">*</span></label>
              <select class="form-control" id="state" name="State" v-model="formData.State">
                <option value="">Select State</option>
                <option v-for="state in filteredStates" :value="state.ShortName" :key="state.ShortName">
                  {{ state.LongName }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="zip_code" class="col-form-label text-md-right">Zip Code <span
                  class="text-danger">*</span></label>
              <input type="number" id="zip_code" name="ZipCode" v-model="formData.ZipCode" class="form-control" required>
            </div>
          </div>
          <div class="form-group row mb-4">
            <div class="col-md-6">
              <label for="country" class="col-form-label text-md-right">Country <span class="text-danger">*</span></label>
              <select class="form-control" id="country" v-model="formData.Country">
                <option value="">Select Country</option>
                <option v-for="country in countries" :value="country.ShortName" :key="country.ShortName">
                  {{ country.LongName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row mb-4">
            <router-link :to="{ name: 'profile' }" class="col-md-8">
              <button type="button" class="btn btn-primary col-md-2" onclick="window.history.back()">Back</button>
            </router-link>
            <button type="submit" class="btn btn-primary col-md-4">
              {{ loadingSubmit ? 'Submitting...' : 'Update' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, reactive, computed, Ref, watch } from 'vue';
import useFormSubmit from '../../../composable/useFormSubmit';
import { MiscellaneousCode, AddressInfo, CountryStates, Country } from '@/interfaces/interfaces';
import axios from 'axios';

export default {
  setup() {
    const initialData: Ref<AddressInfo | null> = ref(null);
    const addressTypes: Ref<MiscellaneousCode[] | null> = ref(null);
    const statesByCountry: Ref<CountryStates[] | null> = ref(null);
    const countries: Ref<Country[] | null> = ref(null);
    const errors: Ref<string[]> = ref([]);
    const success = ref('');
    const info = ref('');
    const loading = ref(true);
    const loadingSubmit = ref(false);
    const formData = reactive({ ...initialData.value });
    const route = 'api/user/profile/my-address';

    // Filter states based on selected country
    const filteredStates = computed(() => {
      return [];
      // const selectedCountry = formData.Country.key;
      // const countryStates = statesByCountry.value ? (statesByCountry.value as CountryStates[]).filter(state => state.CountryKey == selectedCountry) : [];

      // return countryStates?.map(state => state.States).flat();
    });

    watch(filteredStates, () => {
      if(formData.State) {
        formData.State = null;
      }
    })

    // Fetch Data
    const fetchData = async () => {
      try {
        loading.value = true;
        const response = await axios.get(route)
        const data = response?.data;
        initialData.value = data?.AddressMethod;
        addressTypes.value = data?.AddressTypes;
        statesByCountry.value = data?.StatesByCountry;
        countries.value = data?.Countries;
        Object.assign(formData, initialData.value);
        loading.value = false;
      } catch (error) {
        console.error('Error fetching address info:', error);
      }
    }

    // Handle Form Submit
    const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });

    onMounted(async () => {
      await fetchData();
    });

    return {
      formData,
      addressTypes,
      statesByCountry,
      filteredStates,
      countries,
      errors,
      success,
      info,
      loading,
      loadingSubmit,
      handleSubmit,
    };
  },
}
</script>
