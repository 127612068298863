<template>
    <div v-if="selectedUserType == 5 || selectedUserType == 3" class="radio-group primary h-100">
        <div class="radio-buttons gap-20 h-100 flex-column flex-md-row">
            <div class="radio-button enlarged bordered-card bordered-card_secondary flex-1 h-100 align-items-start">
                <input v-model="type" type="radio" id="admin_auth" value="auth">
                <label for="admin_auth"><b>Administrator</b> - <small class="fw-light">Authentication/PIN Request</small><br/>In order to register your local for the first time, choose this option to begin the process.</label>
            </div>
            <div class="radio-button enlarged bordered-card bordered-card_secondary flex-1 h-100 align-items-start">
                <input type="radio" id="admin_register" v-model="type" value="register">
                <label for="admin_register">
                    <b>Administrator</b> - <small class="fw-light">Registration (Registration Requires PIN)</small><br/>
                    Once you have received your PIN, register here as the Administrator.
                </label>
            </div>
            <div class="radio-button enlarged bordered-card bordered-card_secondary flex-1 h-100 align-items-start">
                <input type="radio" id="rep_register" v-model="type" value="rep_register">
                <label for="rep_register">
                    <b>Representative</b> - <small class="fw-light">Registration (Registration Requires PIN)</small><br/>
                    As a Registrant of your Local, as designated by the Local’s Administrator, choose this option to register using you assigned PIN.
                </label>
            </div>
        </div>
    </div>

    <div v-else-if="selectedUserType == 6 || selectedUserType == 7" class="radio-group primary h-100">
        <div class="radio-buttons gap-20 h-100 flex-column flex-md-row">
            <div class="radio-button enlarged bordered-card bordered-card_secondary flex-1 h-100 align-items-start">
                <input v-model="type" type="radio" id="admin_auth" value="auth">
                <label for="admin_auth"><b>Administrator</b> - <small class="fw-light">Authentication/PIN Request</small><br/>In order to register your Employer for the first time, choose this option to begin the process.</label>
            </div>
            <div class="radio-button enlarged bordered-card bordered-card_secondary flex-1 h-100 align-items-start">
                <input type="radio" id="admin_register" v-model="type" value="register">
                <label for="admin_register">
                    <b>Administrator</b> - <small class="fw-light">Registration (Registration Requires PIN)</small><br/>
                    Once you have received your PIN, register here as the Administrator.
                </label>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
    props: {
        registerType: {
            type: String,
            required: true,
        },
        selectedUserType: Number
    },
    setup(props, { emit }) {
        const type = ref(props.registerType);

        watch(type, (newValue) => {
            const type = props.selectedUserType == 5 || props.selectedUserType == 3 ? 'local' : 'employer'
            emit('change-type', newValue, type);
        });

        return {
            type,
        };
    }
});
</script>
