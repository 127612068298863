import { computed } from "vue";

export function useFormatZipCode(zipCode: string | null) {
    const formatZipCode = computed(() => {
        // If the zip code is null or an empty string, return null
        if (!zipCode) {
            return null;
        }
        // Format the zip code if it's longer than 5 characters
        if (zipCode.length > 5) {
            return `${zipCode.substring(0, 5)}-${zipCode.substring(5, 9)}`;
        }
        return zipCode;
    });

    return { formatZipCode };
}
