import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocalAuth = _resolveComponent("LocalAuth")!
  const _component_LocalRegistration = _resolveComponent("LocalRegistration")!

  return (_ctx.registerType === 'auth')
    ? (_openBlock(), _createBlock(_component_LocalAuth, {
        key: 0,
        states: _ctx.states,
        loadingSubmit: _ctx.loadingSubmit,
        authRequest: _ctx.authRequest,
        success: _ctx.success,
        errors: _ctx.errors
      }, null, 8, ["states", "loadingSubmit", "authRequest", "success", "errors"]))
    : (_openBlock(), _createBlock(_component_LocalRegistration, {
        key: 1,
        securityQuestions: _ctx.securityQuestions,
        loadingSubmit: _ctx.loadingSubmit,
        localRequest: _ctx.localRequest,
        errors: _ctx.errors
      }, null, 8, ["securityQuestions", "loadingSubmit", "localRequest", "errors"]))
}