import {
    PensionStatus,
    PensionerDetails,
    TaxDetail,
    ParticipantDemographics,
    PensionDetails,
    PaymentDestination,
    PaymentDestinationTableData,
} from "@/interfaces/pension";
import { defineStore } from "pinia";
import axios from "axios";

export const usePensionStore = defineStore({
    id: "pension",
    state: () => ({
        data: null as PensionStatus | null,
        pensionerDetails: null as PensionerDetails | null,
        hasChildren: true as boolean,
        history: {
            headers: [
                { title: "Year" },
                { title: "Credited" },
                { title: "Worked" },
                { title: "Type" },
                { title: "Plan" },
                { title: "Vesting" },
                { title: "Pension" },
                { title: "Credited" },
                { title: "Contributions" },
                { title: "Comments" },
                { title: "PR" },
            ],
            rows: [],
        },

        paymentHistory: {
            headers: [
                { title: "Date" },
                { title: "Check#" },
                { title: "Gross" },
                { title: "Deductions" },
                { title: "Net" },
                { title: "YTD" },
            ],
            rows: [],
            hasChildren: false,
        },
        paymentDestination: {
            headers: [
                { title: "Name" },
                { title: "Type" },
                { title: "Destination" },
            ],
            rows: [] as PaymentDestinationTableData[],
        },
        taxDetails: [] as TaxDetail[],
        pensionDetails: null as PensionDetails | null,
        demographics: null as ParticipantDemographics | null,
    }),
    actions: {
        async fetchPensionCredits() {
            await axios
                .get("api/participant/pension/credits")
                .then((response) => {
                    this.data = response.data.GeneralInfo;
                    this.hasChildren = response.data.HasChildren;
                    this.history.rows = response.data.History;
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        async fetchPaymentHistory() {
            await axios
                .get("api/participant/pension/payments")
                .then((response) => {
                    this.paymentHistory.rows = Object.values(
                        response.data.Payments
                    );
                    this.taxDetails = response.data.TaxDetails;
                    this.paymentHistory.hasChildren = response.data.HasChildren;
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        async fetchDemographics() {
            await axios
                .get("api/participant/pension/demographics")
                .then((response) => {
                    this.demographics = response.data.Demographics;
                    this.pensionDetails = response.data.PensionDetails;
                    const paymentDestinations = Object.values(response.data.PaymentDestination) as PaymentDestination[];
                    this.paymentDestination.rows = paymentDestinations.flatMap((item: PaymentDestination) => item.TableData);
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        async getPanelDetails() {
            await axios
                .get("api/participant/pension-panel")
                .then((response) => {
                    this.pensionerDetails = response.data;
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                });
        },

        reset() {
            this.$reset();
        },
    },
});
