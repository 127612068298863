<template>
    <AlertsComponent :success="success" :errors="errors" />
    <div class="plan-content">
        <LoaderComponent v-if="loading" />
        <form v-else @submit.prevent="handleSubmit" class="form">
            <TextInput type="text" label="Title" :max=50 v-model:modelValue="autoresponder.Description" />
            <TextInput type="text" label="Email Subject" :max=50 v-model:modelValue="autoresponder.EmailSubject" />
            <TextInput type="text" label="Action" :max=50 v-model:modelValue="autoresponder.Action" />
            <SelectComponent label="Result" v-model:selectedOption="autoresponder.Result"
                :options="[{ key: 0, value: 'Select Result' }]" :single-arrow="true" />
            <Textarea label="Email Body" v-model:modelValue="autoresponder.EmailBody" customClass="fw-light fs-small" />
            <div class="action-buttons">
                <button @click.prevent="closeModal" class="btn btn-secondary">Cancel</button>
                <button type="submit" class="btn btn-primary">{{ loadingSubmit ? 'Submitting...' : 'Update' }}</button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, reactive, defineComponent, computed } from 'vue';
import { Autoresponder } from '@interfaces/interfaces';
import { useSuperadminStore } from '@/stores/superadmin';
import axios from 'axios';

import TextInput from '@components/form/TextInput.vue';
import Textarea from "@components/form/TextareaComponent.vue";
import SelectComponent from "@components/form/SelectComponent.vue";

export default defineComponent({
    components: {
        TextInput,
        Textarea,
        SelectComponent,
    },
    setup(_, { emit }) {
        const autoresponder: Autoresponder = reactive({ Description: '', EmailSubject: '', EmailBody: '', Action: '' });
        const autoresponderID = computed(() => useSuperadminStore()?.autoresponder);
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');

        const closeModal = () => {
            emit('close');
        }

        // Handle Edit Autoresponder
        const handleSubmit = async () => {
            loadingSubmit.value = true;

            await axios
                .post(`api/administrator/autoresponders/${autoresponderID.value}`, { ...autoresponder, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                    loadingSubmit.value = false;
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                    loadingSubmit.value = false;
                });
        }

        const fetchData = async () => {
            loading.value = true;

            if (autoresponderID.value) {
                await axios.get(`api/administrator/autoresponders/${autoresponderID.value}`)
                    .then(response => {
                        Object.assign(autoresponder, response.data.Autoresponder);
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            }

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return { autoresponder, handleSubmit, loading, loadingSubmit, closeModal, errors }

    }
})
</script>