<template>
    <LocalAuth v-if="registerType === 'auth'" :states="states" :loadingSubmit="loadingSubmit"
         :authRequest="authRequest" :success="success" :errors="errors" />
    <LocalRegistration v-else :securityQuestions="securityQuestions" :loadingSubmit="loadingSubmit"
        :localRequest="localRequest" :errors="errors"/>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { SecurityQuestion, Country, ILocalAuth } from '@/interfaces/interfaces';
import { PendingLocal } from '@/interfaces/admin';
import LocalAuth from './LocalAuth.vue';
import LocalRegistration from './LocalRegistration.vue';

export default defineComponent({
    components: {
        LocalAuth,
        LocalRegistration,
    },
    props: {
        securityQuestions: {
            type: Array as PropType<SecurityQuestion[]>,
            required: true
        },
        states: Array as PropType<Country[]>,
        registerType: String,
        loadingSubmit: Boolean,
        localRequest: Object as PropType<PendingLocal | null>,
        authRequest: Object as PropType<ILocalAuth | null>,
        success: String as PropType<string | null>,
        errors: Array as PropType<string[]>,
    },
});
</script>
