<template>
    <AlertsComponent :success="success" :info="info" :errors="errors" />
    <div class="container mt-4">
        <div class="row">
            <LoaderComponent v-if="loading" />
            <div v-else class="col-md-12">
                <!-- <h3 class="text-center mb-4"> {{ data?.Title }} </h3> -->
                <form>
                    <div v-if="errors.length > 0" class="alert alert-danger">
                        <ul>
                            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                        </ul>
                    </div>
                    <div v-if="!isValidStep1">
                        <DependentStep1 :data="data" @check-data="checkData" />
                    </div>
                    <div v-else>
                        <DependentStep2 :documentTypes="documentTypes" @check-data="checkData"
                            @submit-data="handleSubmit" />
                    </div>
                </form>
            </div>
        </div>
        <SuccessModal v-if="isModalVisible" :message="success" title="Success" route="dependents" />
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, reactive } from 'vue';
import { DependentData, DocumentType } from '@/interfaces/interfaces';
import { useDependentStore } from '@/stores/dependent';
import DependentStep1 from '@components/participant/dependents/DependentStep1.vue';
import DependentStep2 from '@components/participant/dependents/DependentStep2.vue';
import SuccessModal from '@components/participant/dependents/SuccessModal.vue';
import axios from 'axios';

export default {
    components: {
        DependentStep1,
        DependentStep2,
        SuccessModal
    },
    setup() {
        const data: Ref<DependentData | null> = ref(null);
        const formData = reactive({});
        const documentTypes: Ref<DocumentType[] | null> = ref(null);
        const route = 'api/participant/dependents/add';
        const loading = ref(false);
        const loadingSubmit = ref(false);
        const isValidStep1 = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const isModalVisible = ref(false);

        const checkData = (isValid: boolean, data: FormData) => {
            isValidStep1.value = isValid;

            if (isValid) {
                Object.assign(formData, data);
            }
        }

        const handleSubmit = () => {
            loadingSubmit.value = true;

            axios.post(route, { ...formData, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                    loadingSubmit.value = false;
                    isModalVisible.value = true;
                    // resetDependentStore();
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                    setTimeout(() => {
                        errors.value = [];
                    }, 5000);

                    loadingSubmit.value = false;
                });
        };

        const fetchData = async () => {
            loading.value = true;

            await axios.get(route)
                .then(response => {
                    data.value = response.data;
                    documentTypes.value = response.data.DocumentTypeDetails
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            data,
            documentTypes,
            loading,
            isValidStep1,
            formData,
            success,
            errors,
            isModalVisible,
            checkData,
            handleSubmit,
        };
    }
}
</script>