import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth";

export const useMenuStore = defineStore("menu", {
    state: () => ({
        menus: [],
        localMenus: [],
        participantMenus: [],
    }),
    getters: {
        getParticipantMenus: (state) => {
            if (state.menus.length === 0) {
                return [
                    {
                        name: 'beneficiaries',
                        label: 'Beneficiaries',
                        icon: 'iatse-icon-Users-Group-Rounded',
                        action: 'beneficiaries',
                        classes: 'plan-tables-striped beneficiary',
                        props: [
                            {
                                name: 'Add Beneficiary',
                                type: 'primary',
                                action: 'add-beneficiary',
                                classes: 'plan-form plan-upload',
                            },
                        ],
                    },
                    {
                        name: 'work-history',
                        label: 'Work History',
                        icon: 'iatse-icon-case',
                        action: 'work-history',
                    },
                    {
                        name: 'funds-resources',
                        label: 'Funds Resources',
                        icon: 'iatse-icon-Document-Text',
                        // to: { name: 'funds-resources' },
                        href: "https://octobercms.dev.digitalgarage.ro/en/resources",
                    },
                    {
                        name: 'faqs',
                        label: 'FAQs',
                        icon: 'iatse-icon-question',
                        // to: { name: 'faqs' },
                        href: "https://octobercms.dev.digitalgarage.ro/en/faqs",
                    },
                    {
                        name: 'missing-contribution',
                        label: 'Missing Contributions',
                        icon: 'iatse-icon-Hand-Pills',
                        action: 'missing-contribution',
                        classes: 'plan-upload plan-card-info plan-profile-update',
                    },
                ]
            }
        },

        getMenus: (state) => {
            if (state.menus.length === 0) {
                return [
                    {
                        name: "users_groups",
                        label: "Users and Groups",
                        icon: "iatse-icon-people plan-modal",
                        subitems: [
                            {
                                to: { name: "superadmin.users.participants" },
                                label: "Participants",
                                icon: "iatse-icon-profile-circle plan-modal",
                            },
                            {
                                to: { name: "superadmin.users.locals" },
                                label: "Locals",
                                icon: "iatse-icon-personalcard plan-modal",
                            },
                            {
                                to: { name: "superadmin.users.employers" },
                                label: "Employers",
                                icon: "iatse-icon-users plan-modal",
                            },
                            {
                                to: { name: "superadmin.users.staff" },
                                label: "IATSENBF Staff",
                                icon: "iatse-icon-dollar-circle plan-modal",
                            },
                        ],
                    },
                    {
                        name: "submissions",
                        label: "Submissions",
                        icon: "iatse-icon-transaction-minus plan-modal",
                        subitems: [
                            {
                                to: { name: "superadmin.requests" },
                                label: "Info Change Request",
                                icon: "iatse-icon-information plan-modal",
                            },
                            {
                                to: { name: "superadmin.form.submissions" },
                                label: "Form Submissions",
                                icon: "iatse-icon-card-edit-2 plan-modal",
                            },
                            {
                                to: { name: "superadmin.claim.submissions" },
                                label: "Claim Submissions",
                                icon: "iatse-icon-card-edit plan-modal",
                            },
                        ],
                    },
                    {
                        name: "autoresponders",
                        to: { name: "superadmin.autoresponders" },
                        label: "Autoresponders",
                        icon: "iatse-icon-refresh-admin plan-modal",
                    },
                    {
                        name: "alerts",
                        to: { name: "superadmin.alerts" },
                        label: "Alerts & Notifications",
                        icon: "iatse-icon-notification plan-modal",
                    },
                    {
                        name: "docs",
                        to: { name: "superadmin.document.mapping" },
                        label: "Docs Mapping Screen",
                        icon: "iatse-icon-Document-Text plan-modal",
                    },
                    {
                        name: "logs",
                        to: { name: "superadmin.logs" },
                        label: "Logs",
                        icon: "iatse-icon-receipt-item plan-modal",
                    },
                ];
            }
            return state.menus;
        },
        getLocalMenus: (state) => {
            if (state.localMenus.length === 0) {
                const authStore = useAuthStore();

                return [
                    {
                        name: "contract_info",
                        label: "Summarized Contract Info",
                        icon: "iatse-icon-card-edit-2 plan-modal",
                        to: { name: "local.contract-information" },
                    },
                    {
                        name: "members",
                        label: "Members Information",
                        icon: "iatse-icon-user-square plan-modal",
                        subitems: [
                            {
                                to: { name: "local.roster" },
                                label: authStore?.user?.name + " Roster",
                                icon: "iatse-icon-people plan-modal",
                            },
                            {
                                to: { name: "local.create-report" },
                                label: "Create Your Own Report",
                                icon: "iatse-icon-receipt-item plan-modal",
                            },
                            {
                                to: { name: "local.contribution-history" },
                                label: "Member Work History",
                                icon: "iatse-icon-user-edit plan-modal",
                            },
                            {
                                to: { name: "local.coverage-search" },
                                label: "Member Current Coverage",
                                icon: "iatse-icon-map-square plan-modal",
                            },
                            {
                                to: { name: "local.plan-resources" },
                                label: "Plan Forms & Documents",
                                icon: "iatse-icon-document-1 plan-modal",
                            },
                        ],
                    },
                    {
                        name: "demographic",
                        to: { name: "user.demographic" },
                        label: "Missing Demographic Info",
                        icon: "iatse-icon-home-trend-up plan-modal",
                    },
                    ...(authStore?.isLocalAdmin ? [
                        {
                            name: "locals-accounts",
                            to: { name: "local.representative" },
                            label: "Manage Locals Accounts",
                            icon: "iatse-icon-profile-circle plan-modal",
                        },
                    ] : []),
                ];
            }
            return state.localMenus;
        },
    },
});
