<template>
    <div class="plan-general-details" :class="classes">
        <div class="plan-general-detail-container">
            <div class="plan-general-detail-line">
                <h5> {{ text }} </h5>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        text: {
            type: String,
            required: true,
        },
        classes: {
            type: String,
            default: '',
        },
        button: {
            type: Object,
        }
    },
})
</script>