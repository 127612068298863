import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-file-upload" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["multiple"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "body-text",
      for: _ctx.label
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "file-name",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileName) = $event)),
      multiple: _ctx.multiple,
      readonly: ""
    }, null, 8, _hoisted_3), [
      [_vModelText, _ctx.fileName]
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "browse-btn btn btn-secondary",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args))),
      disabled: _ctx.disabled
    }, "Browse", 8, _hoisted_4),
    _createElementVNode("input", {
      type: "file",
      class: "file-input",
      ref: "fileUploadInput",
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
      style: {"display":"none"}
    }, null, 544)
  ]))
}