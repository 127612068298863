<template>
    <table class="small striped toggle no-underline">
        <thead class="large large_padding">
            <tr>
                <th> EIN / Tax ID </th>
                <th> Company Name </th>
                <th> Employer / Payor </th>
                <th> First Name </th>
                <th> Last Name </th>
                <th> Title </th>
                <th> Email Address </th>
                <th> Phone </th>
                <th> Type </th>
                <th v-if="processed == 0"> Account actions</th>
            </tr>
        </thead>
        <tbody>
            <tr class="large" v-for="employer in employers" :key="employer.TaxID">
                <td class="col-md-2">{{ employer.TaxID }} </td>
                <td class="col-md-4">{{ employer.CompanyName }}</td>
                <td class="col-md-4">{{ employer.Type }}</td>
                <td class="col-md-4">{{ employer.FirstName }}</td>
                <td class="col-md-4">{{ employer.LastName }}</td>
                <td class="col-md-4">{{ employer.Title }}</td>
                <td class="col-md-4">{{ employer.Email }}</td>
                <td class="col-md-4">{{ employer.Phone }}</td>
                <td class="col-md-4">{{ employer.Role }}</td>
                <td class="col-md-3" v-if="processed == 0">
                    <div class="actions">
                        <span class="tooltip-wrapper" data-tooltip="Send PIN">
                            <i class="plan-modal iatse-icon-lock-check" @click="sendPin(employer.UIEmployerRequestID)"></i>
                        </span>
                        <p> / </p>
                        <span class="tooltip-wrapper" data-tooltip="Reject Request">
                            <i class="plan-modal iatse-icon-lock" @click="rejectRequest(employer.UIEmployerRequestID)"></i>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { PendingEmployer, Paginator } from '@/interfaces/admin';

export default defineComponent({
    props: {
        employers: {
            type: Object as PropType<PendingEmployer[]>,
            required: true
        },
        actions: Object,
        processed: Number
    },
    setup(_, { emit }) {
        const sendPin = (requestID: number) => {
            emit('send-pin', requestID);
        }

        const rejectRequest = (requestID: number) => {
            emit('reject-request', requestID);
        }

        return {
            sendPin,
            rejectRequest,
        };
    }
});
</script>