<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <div class="bordered-card w-100" v-if="documents.length > 0">
            <h4>Documents Currently On File/Pending Admin Preview</h4>
            <table class="small mb-4">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(document, index) in documents" :key="index">
                        <td>
                            <a href="javascript;" @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document.DocumentID })">
                                {{ document.Type.Description }}
                            </a>
                        </td>
                        <td>{{ document.Date }}</td>
                        <td :class="{ 'text-danger': document.Status === 'Pending' }">
                            {{ document.Status }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="text-center mb-4 mt-4"> No documents have been uploaded to your account.</div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, defineComponent } from 'vue';
import { Document } from '@interfaces/interfaces';
import { LocationQueryValue, useRoute } from 'vue-router';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';

export default defineComponent({
    props: {
        modalProps: Object,
    },
    setup(props, { emit }) {
        const documents: Ref<Document[] | []> = ref([]);
        const dependentName: Ref<string> = ref('');
        const loading = ref(true);
        const route = useRoute();
        const DependentID: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const PartnerID: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const Status: Ref<LocationQueryValue | LocationQueryValue[]> = ref(null);
        const headers = [
            'Dependent Name',
            'Relationship',
            'DOB',
        ];

        const fetchData = async () => {
            loading.value = true;
            await axios.get('api/participant/dependents/documents', {
                params: {
                    ...props.modalProps
                }
            })
                .then(response => {
                    documents.value = response.data.Documents;
                    dependentName.value = response.data.DependentName;
                    console.log(response.data, 'documents');
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        const closeModal = () => {
            emit('close');
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            documents,
            dependentName,
            headers,
            loading,
            openModal: useModalStore().openModal,
            closeModal
        };
    },
});
</script>