<template>
    <div class="plan-content">
        <div class="container-fluid">
            <div class="row row-cols-1 row-cols-md-4" v-if="dataLoaded">
                <div class="col" v-for="(options, plan) in groupedOptions" :key="plan">
                    <div class="capp-statement" >
                        <div class="capp-statement__toggle d-flex justify-content-center">
                            <div class="toggle-switch">
                                <button :class="{ active: !familyToggle[plan] }" @click="familyToggle[plan] = false">Single</button>
                                <button :class="{ active: familyToggle[plan] }" @click="familyToggle[plan] = true">Family</button>
                            </div>
                        </div>
                        <div class="capp-statement__details mt-30">
                            <h3>{{ options.current.Title }}</h3>

                            <div class="capp-statement__owed mt-10">
                                <p>Amount Owed</p>
                                <h4>{{ formatCurrency(options.current.AmountOwed) }}</h4>
                            </div>
                        </div>
                        <div class="capp-statement__action mt-30">
                            <button 
                                class="btn btn-primary w-100" 
                                :class="{ 'btn-selected': isInvoiceSelected(options.current.OptionKey) }"
                                @click="selectOption(plan, options.current)">
                                {{ options.current.IsCurrent ? 'Continue this coverage' : 'Select this coverage' }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col" v-if="coverageOptions['MRP']">
                    <div class="capp-statement">
                        <div class="capp-statement__details">
                            <h3>{{ coverageOptions['MRP'].Title }}</h3>

                            <div class="capp-statement__owed">
                                <p>Amount Owed</p>
                                <h4>{{ formatCurrency(coverageOptions['MRP'].AmountOwed) }}</h4>
                            </div>
                        </div>
                        <div class="capp-statement__action mt-30">
                            <button 
                                class="btn btn-primary w-100" 
                                :class="{ 'btn-selected': isInvoiceSelected(coverageOptions['MRP'].OptionKey) }"
                                @click="selectOption('MRP', coverageOptions['MRP'])">
                                Related Instructions
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { CappStatementData, PlanCOptions, CappStatementOption, CoverageOptions } from '@/interfaces/health';
import { useCappInvoicesStore } from '@/stores/cappInvoice';

export default {
    setup() {
        const dataLoaded = ref(false);
        const coverageOptions = ref<CoverageOptions>({});
        const familyToggle = ref<{ [key: string]: boolean }>({});
        const openModal = useModalStore().openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const cappInvoicesStore = useCappInvoicesStore();
        const { setSelectedInvoice, isInvoiceSelected } = cappInvoicesStore;

        // Fetch data from the API, sending a flag if periods are already fetched
        const fetchData = () => {
            const params: Record<string, any> = {};

            axios
                .get('/api/participant/health-welfare/capp-statement', { params })
                .then((response) => {
                    coverageOptions.value = response.data.PlanCStatement.CoverageOptions;
                    dataLoaded.value = true;
                    initializeFamilyToggle();
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        // Initialize family toggle based on available options
        const initializeFamilyToggle = () => {
            Object.keys(coverageOptions.value).forEach(plan => {
                familyToggle.value[plan] = false; // default to Single
            });
        };

        // Type guard to check if planOptions has Family and Single properties
        function hasFamilyAndSingle(option: any): option is { Single?: PlanCOptions; Family?: PlanCOptions } {
            return option && (option.Single !== undefined || option.Family !== undefined);
        }

        const groupedOptions = computed(() => {
            const grouped: { [key: string]: any } = {};
            Object.keys(coverageOptions.value).forEach(plan => {
                const planOptions = coverageOptions.value[plan as keyof CoverageOptions];
                if (plan !== 'MRP' && planOptions) {
                    const isFamily = familyToggle.value[plan];

                    // Use type guard before accessing Family and Single
                    grouped[plan] = {
                        current: hasFamilyAndSingle(planOptions)
                            ? (isFamily ? planOptions.Family : planOptions.Single)
                            : planOptions // Fall back to planOptions if it doesn’t have Family or Single
                    };
                }
            });
            return grouped;
        });

        const selectOption = (plan: string, option: CappStatementOption) => {
            const userConfirmed = confirm(`You have selected ${option.Title}. Are you sure?`);
            if (userConfirmed) {
                setSelectedInvoice(option);

                if (plan === 'MRP') {
                    openModal('hw-mrp-terms', 'Certification', 'plan-c-mrp active');
                } else {
                    openModal('hw-capp-checkout', 'Make Capp Co-Payment', 'plan-c-mrp active');
                }
            }
        };


        onMounted(() => {
            fetchData(); // Initial fetch on mount
        });

        return {
            dataLoaded,
            groupedOptions,
            familyToggle,
            formatCurrency,
            coverageOptions,
            selectOption,
            isInvoiceSelected
        };
    }
};
</script>