<template>
    <AlertsComponent :info="info" :success="success" />
    <div class="pdt-30">
        <div class="d-flex justify-content-between align-items-center pdb-20">
            <h2> Docs Mapping Screen </h2>
            <button class="btn btn-primary" @click="handleSave">
                {{ loadingSubmit ? 'Saving...' : 'Save Changes' }}
            </button>
        </div>
    </div>
    <LoaderComponent v-if="loading" />
    <div v-else class="bordered-card bordered-card_secondary flex-row">
        <div class="d-flex flex-column w-75">
            <div class="filters d-flex gap-4 flex-wrap flex-md-nowrap justify-content-between w-100 mb-20">
                <div class="d-flex gap-4 col-md-6 col-sm-12">
                    <SelectComponent v-model:selectedOption="filters.Area" :options="areas" :single-arrow="true" customClass="w-100 fs-xs " @change="handleAreaChange" />
                    <TextInput type="text" placeholder="Search" :max=50 v-model:modelValue="filters.Search"
                    customClass="search fs-xs w-100" @input="handleSearchView" />
                </div>
            </div>
            <table class="small striped toggle no-underline fixed-table">
                <thead class="large large_padding">
                    <tr class="text-center">
                        <th class="w-25"> Area </th>
                        <th class="w-25"> View </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="form in state.forms" :key="form.FormID"
                        :class="{
                            'cursor-pointer-hover': true,
                            'large': true,
                            'selected': selectedForm === form.FormID
                        }"
                        @click="handleSelectedForm(form.FormID)">
                        <td class="cursor-pointer-hover"> {{ form?.Area }}</td>
                        <td class="cursor-pointer-hover"> {{ form?.View }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex flex-column w-25">
            <TextInput type="text" placeholder="Search for Documents" :max=50 v-model:modelValue="filters.Documents"
                customClass="search w-100 mb-30" @input="handleSearchDocument"/>
            <div class="scrollable-content">
                <table class="small mb-4 row-paddings text-black">
                    <tbody>
                        <tr v-for="document in state.documents" :key="document.CodeID">
                            <td class="checkbox document-row">
                                <div class="checkbox-input">
                                    <input type="checkbox" :id="document.CodeID.toString()" @change="handleDocumentChange()"
                                        class="document-checkbox" :value="document.CodeID" v-model="checkedDocuments">
                                    <label :for="document.CodeID.toString()">
                                        {{ document.Description }} </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { MiscellaneousCode } from '@/interfaces/interfaces';
import { onMounted, reactive, ref, watch, Ref } from 'vue';
import { SelectOption } from '@/interfaces/interfaces';
import { useOptionsFormatter } from '@/utils/optionFormatter';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";
import axios from 'axios';

interface UIForm {
    FormID: number,
    Area: string,
    View: string,
    Documents: number[]
}

export default {
    components: {
        SelectComponent,
        TextInput
    },
    setup() {
        const initialDocuments: Ref<MiscellaneousCode[] | []> = ref([]);
        const initialForms: Ref<UIForm[] | []> = ref([]);
        const state = reactive({
            forms: [...initialForms.value],
            documents: [...initialDocuments.value]
        });
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const success = ref(null);
        const info = ref(null);
        const areas = useOptionsFormatter(['All', 'Employers', 'Locals', 'Participants']);
        const selectedForm: Ref<number | null> = ref(null);
        const currentForm: Ref<UIForm | null> = ref(null);
        const checkedDocuments: Ref<number[]> = ref([]);
        const route = 'api/administrator/document-mapping-screen';
        const filters = reactive({
            Area: areas[0],
            Search: '',
            Documents: '',
        })

        const fetchData = async () => {
            await axios.get(route)
                .then(response => {
                    const res = response.data;
                    initialForms.value = res.UIForms;
                    initialDocuments.value = res.Documents;
                    state.forms = [...initialForms.value];
                    state.documents = [...initialDocuments.value];
                    selectedForm.value = state.forms[0].FormID;
                    currentForm.value = initialForms.value.find(form => form.FormID === selectedForm.value) ?? null;
                    checkedDocuments.value = currentForm.value?.Documents ?? [];
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false
        };

        // Handle Save Changes
        const handleSave = async () => {
            loadingSubmit.value = true;

            await axios
                .post(route, { ...state.forms, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                    info.value = response.data?.info;
                })
                .finally(() => {
                    loadingSubmit.value = false;

                    setTimeout(() => {
                        success.value = null
                        info.value = null
                    }, 3500);
                })
        }

        // Set Selected Form
        const handleSelectedForm = async (formID: number) => {
            selectedForm.value = formID;
            currentForm.value = initialForms.value.find(form => form.FormID === selectedForm.value) ?? null;
            checkedDocuments.value = JSON.parse(JSON.stringify(currentForm.value?.Documents)) ?? [];
        }

        // Handle Checked Documents
        const handleDocumentChange = () => {
            const filteredDocuments = checkedDocuments.value.filter(doc => doc !== 0);

            state.forms.forEach(form => {
                if (form.FormID === selectedForm.value) {
                    form.Documents = [...filteredDocuments];
                }
            });
        };

        // Handle Change Area from Select
        const handleAreaChange = () => {
            if (filters.Area.key == 0) {
                state.forms = [...initialForms.value];
            }
            else {
                const filteredForms: UIForm[] = JSON.parse(JSON.stringify(initialForms.value)).filter((form: UIForm) => form?.Area == filters.Area.value);
                state.forms = [...filteredForms];
            }
        }

        // Handle Search View
        const handleSearchView = (event: Event) => {
            const searchQuery = (event.target as HTMLInputElement)?.value.toLowerCase();
            let filteredForms: UIForm[]

            if (searchQuery.length > 0) {
                filteredForms = JSON.parse(JSON.stringify(state.forms)).filter((form: UIForm) => {
                    return form.View.toLowerCase().includes(searchQuery);
                });
            }
            else {
                handleAreaChange();
                filteredForms = [...state.forms];
            }

            state.forms = [...filteredForms];
        };

        // Handle Search Document
        const handleSearchDocument = (event: Event) => {
            const searchQuery = (event.target as HTMLInputElement)?.value.toLowerCase();

            if (searchQuery.length > 0) {
                const filteredDocuments = initialDocuments.value.filter(document => {
                    return document.Description.toLowerCase().includes(searchQuery);
                });

                state.documents = [...filteredDocuments];
            }
            else {
                state.documents = [...initialDocuments.value];
            }
        };

        onMounted(async () => {
            await fetchData();
        });

        return { state, filters, areas, selectedForm, loading, loadingSubmit, success, info, currentForm, checkedDocuments, handleSave, handleSearchView, handleSearchDocument, handleAreaChange, handleSelectedForm, handleDocumentChange }
    }
}
</script>