<template>
    <AlertsComponent :success="success" :errors="errors" />
    <LoaderComponent v-if="loading" />
    <template v-else>
        <MessageCard
            message="By Completing the information below, a request will be sent to the Fund’s Administrative team for processing."
            type="danger" iconClass="iatse-icon-alarm" classes="info_card_full"
        />
        <div class="plan-content justify-content-between">
            <form class="form inputs-wrapper">
                <SelectComponent label="Carrier" v-model:selectedOption="formData.Carrier" :options="carrierOptions"
                    :singleArrow="true" />
                <TextInput type="text" label="ParticipantID" placeholder="Enter ParticipantID" :max=12
                    v-model:modelValue="formData.ParticipantID" :readonly="true" customClass="disabled" />
                <SelectComponent v-if="formData.HasActiveFamilyCoverage" label="ID For" v-model:selectedOption="formData.Dependent" :options="dependents"
                    :singleArrow="true" />
                <Textarea label="Message (Max 500 characters)" placeholder="Type here..."
                    v-model:modelValue="formData.Notes" :max="500" customClass="fw-light fs-small" />
            </form>
        </div>
        <div class="action-buttons pdt-50 d-flex justify-content-between w-100">
            <button type="button" class="btn btn-secondary" @click="resetModal">
                Discard
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="handleSubmit">
                Submit
            </button>
        </div>
    </template>
</template>

<script lang="ts">
import { ref, Ref, onMounted, reactive, defineComponent } from 'vue';
import { useParticipantStore } from '@/stores/participant';
import { useModalStore } from '@/stores/modal';
import { useOptionsFormatter } from '@/utils/optionFormatter';
import { SelectOption } from '@/interfaces/interfaces';
import { RequestIDFormData } from '@/interfaces/health';

import MessageCard from '@components/cards/MessageCard.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import Textarea from '@components/form/TextareaComponent.vue';
import axios from 'axios';


export default defineComponent({
    components: {
        MessageCard,
        TextInput,
        SelectComponent,
        Textarea
    },
    setup(_, { emit }) {
        const route = 'api/participant/health-welfare/request-id-card';
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const carrierOptions = ref<SelectOption[]>([]);
        const dependents = ref<SelectOption[]>([]);
        const initialFormData = ref<RequestIDFormData|null>(null)
        const formData: RequestIDFormData = reactive({
            HasActiveFamilyCoverage: false,
            Carrier: {key: 0 , value: '-Select Option'},
            Dependent: {key: 0 , value: '-Select Option'},
            ParticipantID: useParticipantStore().general?.ParticipantID,
            Notes: '',
        })

        const closeModal = () => {
            emit('close');
        }

        const resetModal = () => {
            Object.assign(formData, initialFormData.value);
        }

        const handleSubmit = () => {
            loadingSubmit.value = true;
            const data = { ...formData, Carrier: formData.Carrier?.value, Dependent: formData.Dependent?.value }

            axios.post(route, { ...data })
                .then((response) => {
                    const successProps = {
                        title: "Request Submitted",
                        content: {
                            type: "text",
                            value: response.data.success,
                            icon: "iatse-icon-user-verified",
                        },
                        onConfirmButton: "Ok, Go Back to Dashboard",
                        onConfirm: () => {
                            useModalStore().closeAllModals();
                        },
                    }

                    useModalStore().openModal('success-modal', null, 'modal-success', null, successProps);

                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                }).finally(() => {
                    loadingSubmit.value = false;
                    setTimeout(() => errors.value = [], 3000);
                })
        };

        const fetchData = async () => {
            axios.get(route)
                .then((response) => {
                    formData.Carrier = response.data?.Coverage;
                    formData.Dependent = response.data?.Dependents[0];
                    formData.HasActiveFamilyCoverage = response.data?.HasActiveFamilyCoverage;
                    carrierOptions.value = useOptionsFormatter(response.data?.CarrierOptions);
                    dependents.value = response.data?.Dependents;

                    initialFormData.value = {
                        HasActiveFamilyCoverage: response.data?.HasActiveFamilyCoverage,
                        Carrier:  response.data?.Coverage ?? {key: 0 , value: '-Select Option'},
                        Dependent:  response.data?.Dependents[0] ?? {key: 0 , value: '-Select Option'},
                        ParticipantID: useParticipantStore().general?.ParticipantID,
                        Notes: '',
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);

                }).finally(() => {
                    loadingSubmit.value = false;
                })

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            formData,
            carrierOptions,
            dependents,
            loading,
            success,
            errors,
            loadingSubmit,
            closeModal,
            handleSubmit,
            resetModal
        };
    }
});
</script>