<template>
    <div class="plan-content">
        <div class="plan-cards-wrapper">
            <div class="card-header">
                <h5>Retiree Profile</h5>
                <div class="card-control plus">
                    <i class="plus iatse-icon-plus"></i>
                    <span class="line"></span>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <div class="data-section">
                            <div class="fund-details">
                                <div class="fund-detail">
                                    <h5>ParticipantID</h5>
                                    <h4>{{ personDetails.ParticipantID }}</h4>
                                </div>
                                <div class="fund-detail">
                                    <h5>SSN</h5>
                                    <h4>{{ personDetails.SSN  }}</h4>
                                </div>
                                <div class="fund-detail">
                                    <h5>Name</h5>
                                    <h4>{{ personDetails.Name }}</h4>
                                </div>
                                <div class="fund-detail">
                                    <h5>Age</h5>
                                    <h4>{{ personDetails.Age }}</h4>
                                </div>
                            </div>
                        </div>

                        <div v-if="spouseDetails != null">
                            <h4 class="mt-20">Spouse</h4>
                            <div class="data-section mt-5">
                                <div class="fund-details">
                                    <div class="fund-detail">
                                        <h5>SSN</h5>
                                        <h4>{{ spouseDetails.SSN }}</h4>
                                    </div>
                                    <div class="fund-detail">
                                        <h5>Name</h5>
                                        <h4>{{ spouseDetails.Name }}</h4>
                                    </div>
                                    <div class="fund-detail">
                                        <h5>Age</h5>
                                        <h4>{{ spouseDetails.Age }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="data-section">
                            <h4>Annual Totals</h4>
                            <!-- Add your annual totals data here if needed -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row mt-30">
                    <div class="col-md-12">
                        <h3>Retiree Benefits</h3>
                        <div class="custom-table">
                            <div class="table-row">
                                <div class="table-row__header">
                                    <div class="primary-row">
                                        <div><button style="visibility: hidden;">+</button></div>
                                        <div>Date</div>
                                        <div>Check #</div>
                                        <div>Amount</div>
                                        <div>Payee</div>
                                        <div>Run Date</div>
                                        <div>Clear Date</div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="retireeChecks.length > 0">
                                <div class="table-row" v-for="(check, index) in retireeChecks" :key="index">
                                    <div class="table-row__header">
                                        <div class="primary-row">
                                            <div>
                                                <button @click="toggleDetails(index)">{{ openedIndexes.includes(index) ? '-' : '+' }}</button>
                                                {{ check.CheckDate }}
                                            </div>
                                            <div>{{ check.CheckNumber }}</div>
                                            <div>{{ formatCurrency(check.AmountPaid) }}</div>
                                            <div>{{ check.PaidTo }}</div>
                                            <div>{{ check.CheckRunDate }}</div>
                                            <div>{{ check.ClearDate }}</div>
                                        </div>
                                    </div>
                                    <div v-if="openedIndexes.includes(index)" class="table-row__content">
                                        <!-- Secondary headers -->
                                        <div class="secondary-row">
                                            <div>Person ID</div>
                                            <div>Benefit</div>
                                            <div>Period Reimbursed</div>
                                            <div>Amount Req</div>
                                            <div>Amount Paid</div>
                                            <div>Comments</div>
                                        </div>
                                        <!-- Loop over each PersonID within Details -->
                                        <template v-for="(personDetails, personID) in check.Details" :key="personID">
                                            <!-- Optionally display PersonID here if needed -->
                                            <div class="secondary-row">
                                                <div><strong>{{ personID }}</strong></div>
                                                <!-- Empty cells to align columns -->
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>

                                            <!-- Now loop over the array of details for this PersonID -->
                                            <div class="secondary-row" v-for="(reimbursement, subIndex) in personDetails" :key="subIndex">
                                                <div>{{ reimbursement.PaidTo }}</div>
                                                <div>{{ reimbursement.BenefitType }}</div>
                                                <div>{{ reimbursement.PeriodReimbursedFor }}</div>
                                                <div>{{ formatCurrency(reimbursement.AmountRequested) }}</div>
                                                <div>{{ formatCurrency(reimbursement.AmountPaid) }}</div>
                                                <div>{{ reimbursement.Notes }}</div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="table-row">
                                    <div class="table-row__header">
                                        <div class="primary-row">
                                            <div>No records found.</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { ParticipantData } from '@/interfaces/interfaces';

interface RetireePaymentDetail {
    BenefitType: string;
    PeriodReimbursedFor: string;
    AmountRequested: number;
    AmountPaid: number;
    PaidTo: string;
    Notes: string;
}

interface RetireeCheck {
    CheckDate: string;
    CheckNumber: number;
    AmountPaid: number;
    PaidTo: string;
    CheckRunDate: string;
    ClearDate: string;
    Details: { [personID: string]: RetireePaymentDetail[] } | null;
}

export default {
    setup() {
        const { formatCurrency } = useCurrencyFormatter();
        const retireeChecks = ref<RetireeCheck[]>([]);
        const personDetails = ref<ParticipantData>({
            ParticipantID: '',
            SSN: '',
            Gender: '',
            Name: '',
            DateofBirth: '',
            Age: 0,
            AddressFormatted: ''
        });
        const spouseDetails = ref<ParticipantData | null>(null);
        const openedIndexes = ref<number[]>([]);

        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/participant/health-welfare/retiree-payments-history');
                
                // Make sure to match the new data structure from the backend
                retireeChecks.value = response.data.RetireeChecks; 
                personDetails.value = response.data.PersonDetails;
                spouseDetails.value = response.data.SpouseDetails;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const toggleDetails = (index: number) => {
            if (openedIndexes.value.includes(index)) {
                openedIndexes.value = openedIndexes.value.filter(i => i !== index);
            } else {
                openedIndexes.value.push(index);
            }
        };

        onMounted(() => {
            fetchData();
        });

        return {
            personDetails,
            spouseDetails,
            retireeChecks,
            formatCurrency,
            openedIndexes,
            toggleDetails
        };
    }
};
</script>
