import axios from 'axios';

export const getFundResources = async () => {
  try {
    const response = await axios.get(`api/local/resources`);

    return response.data;
  } catch (error) {
    console.error('Error fetching fund resources:', error);
    throw error;
  }
};

export const getAnnouncements = async () => {
    try {
      const response = await axios.get(`api/local/announcements`);
      return response.data;
    } catch (error) {
      console.error('Error fetching announcements:', error);
      throw error;
    }
  };
