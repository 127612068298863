<template>
    <div class="bordered-card bordered-card_secondary members-card">
        <h4 class="fw-bold">Members Information</h4>
        <div class="d-flex flex-column align-items-start gap-20 w-100">
            <div class="d-flex align-items-center gap-10 w-100">
                <i class="iatse-icon-search w-5"> </i>
                <router-link class="fs-sm" :to="{ name: 'local.member-search' }">
                    Member Search
                </router-link>
            </div>
            <div class="d-flex align-items-center gap-10 w-100">
                <i class="iatse-icon-calendar w-5"> </i>
                <router-link class="fs-sm" :to="{ name: 'local.coverage-search' }">
                    Member Current Coverage Search
                </router-link>
            </div>
            <div class="d-flex align-items-center gap-10 w-100 plan-modal" @click.prevent="openModal('local-missing-contributions', 'Missing Contributions Inquiry', 'plan-upload plan-card-info plan-profile-update')">
                <i class="iatse-icon-Hand-Pills fs-lg w-5"> </i>
                <a href="javascript;" class="fs-sm">
                    Member Contributions Inquiry
                </a>
            </div>
            <div class="d-flex align-items-center gap-10 w-100">
                <i class="iatse-icon-note fs-lg w-5"> </i>
                <a href="javascript;" class="plan-modal fs-sm"
                    @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: cappReport })">
                    CAPP Analysis Report
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';

export default defineComponent({
    props: {
        cappReport: Number
    },
    setup() {
        const userName = useAuthStore().userName;

        return {
            userName,
            openModal: useModalStore().openModal
        }
    }
})
</script>