/**
 * Transforms an object with key-value pairs or an array into an array of objects with key and value properties.
 *
 * @param obj - The object or array to transform.
 * @returns An array of objects with key and value properties.
 */
export const useOptionsFormatter = <T>(obj: Record<string, T> | T[]): { key: string | number; value: T }[] => {
    if (Array.isArray(obj)) {
        // If it's an array, use the index as the key
        return obj.map((value, index) => ({
            key: index,
            value,
        }));
    } else {
        // If it's an object, convert key-value pairs directly
        return Object.entries(obj).map(([key, value]) => ({
            key,
            value,
        }));
    }
};
