<template>
     <div class="pdl-10 pdt-30 pdr-10">
        <LoaderComponent v-if="loading" />
        <div v-else class="d-flex flex-column gap-25">
            <div class="d-flex gap-25 flex-md-row flex-column">
                <div class="w-35 d-flex flex-column gap-25 w-sm-100">
                    <ProfilePanel :data="profileData" type="Employer" />
                    <PaymentPanel :links="paymentLinks" />
                </div>
                <div class="w-70 w-sm-100">
                    <RecentAnnouncements :announcements="announcements"/>
                </div>
            </div>
            <!-- <FundMaterialsPanel :links="fundMaterialLinks" /> -->
            <ReportsPanel />
            <ResourcesPanel :resources="resources" />
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref } from 'vue';
import { FundMaterialLinks, PaymentLinks } from '@/interfaces/employer';
import { FundMaterials, Announcements } from '@interfaces/local';
import { ProfileData } from '@interfaces/interfaces';
import PaymentPanel from '@/components/employer/dashboard/PaymentPanel.vue';
import FundMaterialsPanel from '@/components/employer/dashboard/FundMaterialsPanel.vue';
import ProfilePanel from '@components/user/dashboard/ProfilePanel.vue';
import ReportsPanel from '@components/local/dashboard/ReportsPanel.vue';
import ResourcesPanel from '@components/local/dashboard/ResourcesPanel.vue';
import RecentAnnouncements from '@components/user/dashboard/RecentAnnouncements.vue';
import axios from 'axios';

export default {
    components: {
        PaymentPanel,
        // FundMaterialsPanel,
        ProfilePanel,
        RecentAnnouncements,
        ReportsPanel,
        ResourcesPanel
    },
    setup() {
        const fundMaterialLinks: Ref<FundMaterialLinks | null> = ref(null);
        const paymentLinks: Ref<PaymentLinks | null> = ref(null);
        const profileData: Ref<ProfileData | null> = ref(null);
        const resources: Ref<FundMaterials | null> = ref(null);
        const cappReport: Ref<number | null> = ref(null);
        const announcements: Ref<Announcements | null> = ref(null);
        const loading = ref(true);
        const type = ref('Employer');

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/employer/fund-materials')
                .then(response => {
                    fundMaterialLinks.value = response.data.FundMaterialLinks;
                    paymentLinks.value = response.data.PaymentLinks;
                });

            await axios.get('api/employer/dashboard')
                .then(response => {
                    profileData.value = response.data;
                    type.value = response.data.IsPayor ? 'Payroll' : 'Employer';
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            loading,
            type,
            fundMaterialLinks,
            paymentLinks,
            profileData,
            resources,
            announcements,
        };
    }
}
</script>