<template>
    <!-- <AlertsComponent :errors="errors" /> -->
    <LoaderComponent v-if="loading" />
    <div v-else class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Latest Monthly Member Work History Reports</h2>
        <div class="bordered-card bordered-card_secondary bg-light-green mb-30 flex-row gap-30">
            <p class="pdr-20"> Latest Report </p>
            <template v-for="(report, key, index) in reports" :key="key">
                <div class="d-flex gap-5 fs-sm align-items-center">
                    <i class="iatse-icon-pdf d-flex"><span class="path1"> </span> <span class="path2"> </span></i>

                    <p class="plan-modal"
                        @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: key })">
                        {{report }}
                    </p>
                </div>
                <div v-if="index < Object.entries(reports).length - 1" class="divider divider_vertical my-0"></div>
            </template>

        </div>
        <h2 class="mb-20">Member Work History</h2>
        <!-- Filters -->
        <div class="d-flex flex-column gap-20">
            <HistoryFilters :loadingSearch="loading" :reset="reset" @search="searchData" :errors=errors />
        </div>
        <!-- Results -->
        <HistoryResults v-if="(!loading)" :loading="loading" :loadingTotals="loadingTotals" :pageChanged="pageChanged"
            @reset-filters="resetFilters" @fetch-pagination-data="fetchPaginationData" />
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { History, ContributionFilters, Entity } from '@/interfaces/local';
import { useContributionsStore } from '@/stores/contributions';
import HistoryFilters from '@components/local/contribution-history/HistoryFilters.vue';
import HistoryResults from '@components/local/contribution-history/HistoryResults.vue';
import axios from 'axios';

export default {
    components: {
        HistoryFilters,
        HistoryResults,
    },

    setup() {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const changePage = ref(false);
        const pageChanged = ref(false);
        const reset = ref(false);
        const page = ref(1);
        const errors: Ref<string[]> = ref([]);
        const reports: Ref<string[]> = ref([]);
        const contributionsStore = useContributionsStore();
        const hasHistory = computed(() => (contributionsStore.fetchContributions.length !== 0));

        const resetFilters = () => {
            contributionsStore.resetData();
            changePage.value = false;
            reset.value = true;
            page.value = 1;
        }

        const fetchData = async () => {
            await axios.get('api/local/work-history')
                .then(response => {
                    reports.value = response.data;
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        const searchData = async (payload: ContributionFilters, existingFilters: boolean | null) => {
            loadingSearch.value = true;
            contributionsStore.setContributions([]);
            let filters = JSON.parse(JSON.stringify(payload));
            let formData = {
                ...filters,
                Page: page.value,
            };

            if (!existingFilters) {
                const hasEmployers = filters?.Employers?.some((employer: Entity) => employer.ID !== 0);
                const hasProductions = filters?.Productions?.some((prod: Entity) => prod.ID !== 0);
                const hasFunds = filters?.Funds?.some((fund: Entity) => fund.ID !== 0);
                const hasPayors = filters?.Payors?.some((payor: Entity) => payor.ID !== 0);

                formData = {
                    ...filters,
                    Employers: !hasEmployers ? null : filters.Employers.map((item: Entity) => item.ID),
                    Productions: !hasProductions ? null : filters.Productions.map((item: Entity) => item.ID),
                    Payors: !hasPayors ? null : filters.Payors.map((item: Entity) => item.ID),
                    Funds: !hasFunds ? null : filters.Funds.map((item: Entity) => item.ID),
                    Page: page.value,
                };

                contributionsStore.setFilters(formData);
            }

            if (changePage.value) {
                pageChanged.value = false;
            }

            await axios
                .post('api/local/work-history', { ...formData })
                .then(response => {
                    contributionsStore.setContributions(
                        response.data.Records
                    );

                    contributionsStore.setPaginator(response.data.Paginator);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = error.response.data.errors;
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;
                    reset.value = false;

                    if (changePage.value) {
                        pageChanged.value = true;
                    }

                    setTimeout(() => errors.value = [], 3500)
                })
        };

        const fetchPaginationData = async (pageNumber: number) => {
            page.value = pageNumber;
            changePage.value = true;

            await searchData(contributionsStore.getFilters, true);
        }

        return {
            loading,
            loadingSearch,
            reports,
            hasHistory,
            pageChanged,
            changePage,
            errors,
            reset,
            contributionsStore,
            resetFilters,
            fetchPaginationData,
            searchData,
            openModal: useModalStore().openModal
        }
    }
}
</script>