<template>
    <div class="filters mt-30 mb-20 ml-10">
        <TextInput type="text" name="mixed" placeholder="Search by Document Name" :max=50 v-model:modelValue="searchTerm" customClass="search" />
    </div>
    <LoaderComponent v-if="loading" />
    <div v-else class="bordered-card bordered-card_secondary gap-20 p-2 pdt-15 pdb-35 ml-10">
        <h2 class="bg-light-green w-100 pdl-20 pdt-25 pdb-25 rounded"> Plan Forms & Documents</h2>
        <template v-for="(resourceGroup, mainKey, index) in filteredValues" :key="mainKey">
            <div class="primary-group primary-group_page d-flex justify-content-between align-items-center w-100 plan-modal pdl-20" @click="toggleGroup(mainKey)">
                <h5 class="fw-bold fs-base"> {{ mainKey }} Documents </h5>
                <!-- Toggle secondary group items on click -->
                <button @click="toggleGroup(mainKey)" class="toggle-btn">
                    <span v-if="!showGroup[mainKey]" class="fs-xl lh-1">+</span>
                    <span v-else class="fs-xl lh-1">-</span>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="showGroup[mainKey]"
                    class="secondary-group d-flex flex-md-row flex-column w-100 pdr-0 pdl-20">
                    <template v-for="(documents, secondaryKey, secondaryIndex) in resourceGroup" :key="secondaryKey">
                        <div class="secondary_group_item d-flex flex-column gap-20">
                            <h5 class="fw-semibold fs-base"> {{ secondaryKey }} </h5>
                                <!-- Display limited documents initially -->
                                <template  v-for="(doc, docIndex) in getVisibleDocuments(documents, mainKey, secondaryKey)"  :key="docIndex">
                                    <a :href="doc.file_url" target="_blank"
                                        :class="{'fade-in': isExpanded(mainKey, secondaryKey) && docIndex >= 3, 'fade-out': !isExpanded(mainKey, secondaryKey) && docIndex > 3}"
                                        class="document-item gap-10">
                                            <i class="iatse-icon-pdf d-flex">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                            </i>
                                            <span class="fs-base">{{ doc.title }}</span>
                                    </a>
                                 </template>

                            <!-- Expand/Collapse Button -->
                            <button v-if="documents.length > 3" @click="toggleDocs(mainKey, secondaryKey)"
                                    class="btn btn-link p-0 justify-content-start" :class="{'bounce-up': isExpanded(mainKey, secondaryKey), 'bounce-down': !isExpanded(mainKey, secondaryKey)}">
                                    <i class="iatse-icon-select-arrow fs-xxs text-primary"></i>
                                {{ isExpanded(mainKey, secondaryKey) ? 'View Less' : 'View More' }}
                            </button>

                        </div>
                        <div v-if="secondaryIndex < Object.entries(resourceGroup).length - 1" class="divider divider_vertical h-100 m-0"></div>
                    </template>
                </div>
            </transition>
            <div v-if="index < Object.entries(resources).length - 1" class="divider m-0"></div>
        </template>
    </div>
</template>

<script lang="ts">
import { FundMaterials, DocumentCategory, Document } from "@/interfaces/local";
import { onMounted, ref, watch, Ref } from 'vue';
import { getFundResources } from '@/services/publicService';
import TextInput from '@components/form/TextInput.vue';

export default {
    components: {
        TextInput,
    },
    setup() {
        const loading = ref(true);
        const resources: Ref<FundMaterials | null> = ref(null);
        const showGroup = ref<Record<string, boolean>>({});
        const visibleDocs = ref<Record<string, Record<string, boolean>>>({});
        const searchTerm = ref('');
        const filteredValues: Ref<FundMaterials | null> = ref(null);

        const fetchData = async () => {
            resources.value = await getFundResources();
            filteredValues.value = resources.value;
            loading.value = false;
        };

        onMounted(async () => await fetchData());

        watch(searchTerm, () => {
            if(searchTerm.value.length > 2 && resources.value) {
                filteredValues.value = Object.entries(resources.value)?.reduce((acc: FundMaterials, [key, category]) => {
                    const filteredCategory: DocumentCategory = {
                        'Fund Documents': [],
                        'Forms & Applications': [],
                        'Notices': []
                    };

                    Object.entries(category).forEach(([categoryName, documents]) => {
                        const filteredDocuments = (documents as Document[]).filter((doc: Document) => {
                            return doc.title.toLowerCase().includes(searchTerm.value.toLowerCase());
                        });

                        filteredCategory[categoryName as keyof DocumentCategory] = filteredDocuments.length > 0 ? filteredDocuments : [];
                    });

                    if (
                        filteredCategory['Fund Documents'].length ||
                        filteredCategory['Forms & Applications'].length ||
                        filteredCategory['Notices'].length
                    ) {
                        acc[key as keyof FundMaterials] = filteredCategory;
                    }

                    return acc;
                }, {
                    "Employer Documents": {
                        'Fund Documents': [],
                        'Forms & Applications': [],
                        'Notices': []
                    },
                    "Participant and Benefit Documents": {
                        'Fund Documents': [],
                        'Forms & Applications': [],
                        'Notices': []
                    },
                    "Fund and Local Documents": {
                        'Fund Documents': [],
                        'Forms & Applications': [],
                        'Notices': []
                    }
                });


                Object.keys(filteredValues.value).forEach(mainKey => {
                    if (!showGroup.value[mainKey]) {
                        toggleGroup(mainKey);
                    }
                });
            } else {
                filteredValues.value = resources.value;
            }
        });


        // Toggle the visibility of secondary items for each group
        const toggleGroup = (mainKey: string) => {
            showGroup.value[mainKey] = !showGroup.value[mainKey];
        };

        // Get visible documents (limit to 3 or all based on toggle state)
        const getVisibleDocuments = (documents: any[], mainKey: string, secondaryKey: string) => {
            const expanded = isExpanded(mainKey, secondaryKey);
            return expanded ? documents : documents.slice(0, 3);
        };

        // Check if a category is expanded
        const isExpanded = (mainKey: string, secondaryKey: string) => {
            return visibleDocs.value[mainKey]?.[secondaryKey] || false;
        };

        // Toggle documents visibility for a category
        const toggleDocs = (mainKey: string, secondaryKey: string) => {
            if (!visibleDocs.value[mainKey]) visibleDocs.value[mainKey] = {};
            visibleDocs.value[mainKey][secondaryKey] = !isExpanded(mainKey, secondaryKey);
        };

        return {
            loading,
            showGroup,
            searchTerm,
            resources,
            filteredValues,
            toggleGroup,
            getVisibleDocuments,
            toggleDocs,
            isExpanded
        };
    }
};
</script>

